import { Flex, Icon, Close } from '@storyofams/react-ui';

import { Button } from '~components/common/Button';
import { Info, Tick, XCircle } from '~components/common/Icons';
import { Text } from '~components/common/Text';

type ToastProps = {
  msg: string;
  type: 'info' | 'success' | 'error' | 'alert';
  onClose: () => void;
};

const content = {
  info: {
    icon: Info,
    color: 'primary800',
  },
  success: {
    icon: Tick,
    color: 'success600',
  },
  error: {
    icon: XCircle,
    color: 'error600',
  },
  alert: {
    icon: Info,
    color: 'error600',
  },
};

export const Toast = ({ msg, type }: ToastProps) => (
  <Flex
    p={4}
    width="100%"
    boxShadow="toast"
    borderRadius="md"
    position="relative"
    backgroundColor="grey100"
    alignItems="center"
  >
    <Icon fontSize={3} icon={content[type].icon} color={content[type].color} />
    <Text ml={2} fontSize={2} color="grey700" maxWidth="100%" lineHeight={1.5}>
      {msg}
    </Text>
    <Button variant="unstyled" position="absolute" top="16px" right="16px">
      <Icon icon={Close} color="grey700" fontSize="8px" />
    </Button>
  </Flex>
);
