import React, { forwardRef, ComponentProps, ReactNode, Ref } from 'react';
import { Icon, SystemProps, Box, Spinner, system } from '@storyofams/react-ui';
import type { PolymorphicForwardRefExoticComponent } from 'react-polymorphic-types';
import styled, { css } from 'styled-components';
import { ResponsiveValue, variant } from 'styled-system';

import { Link } from '~components';

const _defaultElement = 'button';

const sizes = {
  small: {
    fontSize: 1.5,
  },
  medium: {
    fontSize: 2,
    lineHeight: 'normal',
  },
  large: {
    fontSize: 2.5,
    px: 4,
    lineHeight: 'medium',
  },
};

const baseStyles = {
  px: 4,
  py: 2,
  fontWeight: 'medium',
  minHeight: '52px',
  textTransform: 'uppercase',
  letterSpacing: '0.04em',
};

const variants = {
  primary: {
    ...baseStyles,
    bg: 'grey900',
    color: '#fff',
    border: '1px solid',
    borderColor: 'grey900',

    '&:hover, &:focus, &:active': {
      bg: '#fff',
      borderColor: 'grey900',
      color: 'grey900',
    },

    '&:focus, &:active': {
      boxShadow: '0px 0px 0px 4px #D9E1DD',
    },

    '&:disabled:not([data-is-loading])': { opacity: 0.25 },
  },
  secondary: {
    ...baseStyles,
    bg: '#fff',
    color: 'grey900',
    border: '1px solid',
    borderColor: '#fff',

    '&:hover, &:focus, &:active': {
      color: '#fff',
      backgroundColor: 'grey900',
      borderColor: 'grey900',
    },

    '&:focus, &:active': {
      boxShadow: '0px 0px 0px 4px #D9E1DD',
    },

    '&:disabled:not([data-is-loading])': { opacity: 0.25 },
  },
  inverted: {
    ...baseStyles,
    bg: 'grey100',
    color: 'grey900',
    border: '1px solid',
    borderColor: 'grey100',

    '&:hover, &:focus, &:active': {
      bg: 'grey900',
      color: '#fff',
      borderColor: 'grey900',

      'svg path': {
        stroke: '#fff',
      },
    },

    '&:focus, &:active': {
      boxShadow: '0px 0px 0px 4px #D9E1DD',
    },

    '&:disabled:not([data-is-loading])': { opacity: 0.25 },
  },
  outline: {
    ...baseStyles,
    bg: 'transparent',
    color: 'grey900',
    border: '1px solid',
    borderColor: 'grey900',

    '&:hover, &:focus, &:active': {
      bg: 'grey900',
      color: '#fff',
    },

    '&:focus, &:active': {
      boxShadow: '0px 0px 0px 4px #D9E1DD',
    },

    '&:disabled:not([data-is-loading])': { opacity: 0.25 },
  },
  invertedOutline: {
    ...baseStyles,
    bg: 'transparent',
    color: 'cocktails',
    border: '1px solid',
    borderColor: 'cocktails',

    '&:hover, &:focus, &:active': {
      bg: 'grey900',
    },

    '&:active': {
      boxShadow: '0px 0px 0px 4px #151817',
    },

    '&:disabled:not([data-is-loading])': { opacity: 0.25 },
  },
  link: {
    color: 'grey900',
    display: 'inline-flex',
    lineHeight: 'high',

    '&:hover, &:active, &:focus': {
      color: 'grey500',
    },
  },
  unstyled: {
    '&:disabled': { cursor: 'not-allowed' },
  },
};

export type ButtonProps = {
  isLoading?: boolean;
  icon?: ComponentProps<typeof Icon>['icon'];
  href?: string;
  rel?: string;
  target?: string;
  to?: string;
  variant?: keyof typeof variants;
  buttonSize?: ResponsiveValue<keyof typeof sizes>;
  disabled?: boolean;
  children: ReactNode;
  twoIcons?: boolean;
};

const StyledButton = styled(Box).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    ['buttonSize'].indexOf(prop) === -1 && defaultValidatorFn(prop),
})<Pick<ButtonProps, 'variant'>>`
  position: relative;
  appearance: none;
  text-decoration: none;

  /** @todo not sure what styles in react-ui are overriding this */
  height: auto !important;

  ${(p) =>
    p.variant !== 'unstyled' &&
    css`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: inherit;
      text-align: center;
      border: 0;
      border-radius: 99px;
      user-select: none;
      transition: background-color 0.18s ease-in-out, box-shadow 0.18s,
        border-color 0.18s ease-in-out, color 0.18s ease-in-out,
        opacity 0.18s ease-in-out, color 0.18s ease-in-out;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }

      &:active,
      &:focus {
        box-shadow: none;
        outline: 1px;
      }

      &[data-is-loading] {
        cursor: wait;
        opacity: 1;
      }

      svg path {
        transition: stroke 0.18s ease-in-out;
      }
    `}

  ${variant({ variants })}
  ${variant({ prop: 'buttonSize', variants: sizes })}
  ${system}
`;

const loadingStyles = {
  position: 'relative' as any,
  disabled: true,
  'data-is-loading': true,
  'aria-disabled': true,
};

export const Button: PolymorphicForwardRefExoticComponent<
  ButtonProps & SystemProps,
  typeof _defaultElement
> = forwardRef(
  (
    { children, icon, twoIcons, to, isLoading, ...props }: ButtonProps,
    ref: Ref<any>,
  ) => {
    const content = (
      <>
        {!!icon && <Icon width="24px" icon={icon} mr={1} />}
        {children}
        {twoIcons && <Icon width="24px" icon={icon} ml={1} />}
      </>
    );

    return to ? (
      <Link href={to} passHref>
        <StyledButton as="a" buttonSize="medium" variant="link" {...props}>
          {content}
        </StyledButton>
      </Link>
    ) : (
      <StyledButton
        as={props?.href ? 'a' : _defaultElement}
        buttonSize="medium"
        variant={props?.href ? 'link' : 'primary'}
        {...props}
        {...(isLoading ? loadingStyles : {})}
        ref={ref}
        display="flex"
      >
        {isLoading ? (
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          >
            <Spinner color="inherit" size={18 as any} />
          </Box>
        ) : (
          content
        )}
      </StyledButton>
    );
  },
);
