import styled from 'styled-components';

const Styles = styled.div<{ isValid?: boolean }>`
  position: relative;
  height: 50px;
  width: 100%;

  .flatpickr-wrapper {
    height: 100%;
    width: 100%;
  }

  .calendar-icon {
    position: absolute;
    right: 16px;
    top: 12px;
    pointer-events: none;
    svg {
      pointer-events: none;
    }
  }

  .flatpickr-calendar {
    opacity: 0;
    display: none;
    text-align: center;
    visibility: hidden;
    animation: none;
    direction: ltr;
    padding: ${(p) => p.theme.space[3]}px;
    font-size: ${(p) => p.theme.fontSizes[2]}px;
    line-height: 1.5;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    touch-action: manipulation;
    background: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
  }
  .flatpickr-calendar.open,
  .flatpickr-calendar.inline {
    opacity: 1;
    visibility: visible;
  }
  .flatpickr-calendar.open {
    width: 100%;
    min-width: 300px;
    max-width: 600px;
    display: inline-block;
    z-index: 99999;
  }
  .flatpickr-calendar.animate.open {
    animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .flatpickr-calendar.inline {
    display: block;
    position: relative;
    top: 4px;
  }
  .flatpickr-calendar.static {
    position: absolute;
    top: calc(100% + 4px);
  }
  .flatpickr-calendar.static.open {
    z-index: 999;
    display: block;
  }
  .flatpickr-calendar.multiMonth
    .flatpickr-days
    .dayContainer:nth-child(n + 1)
    .flatpickr-day.inRange:nth-child(7n + 7) {
    box-shadow: none !important;
  }
  .flatpickr-calendar.multiMonth
    .flatpickr-days
    .dayContainer:nth-child(n + 2)
    .flatpickr-day.inRange:nth-child(7n + 1) {
    box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  }
  .flatpickr-calendar .hasWeeks .dayContainer,
  .flatpickr-calendar .hasTime .dayContainer {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .flatpickr-calendar .hasWeeks .dayContainer {
    border-left: 0;
  }
  .flatpickr-calendar.hasTime .flatpickr-time {
    height: 40px;
    border-top: 1px solid #eee;
  }
  .flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
    height: auto;
  }
  .flatpickr-calendar:before,
  .flatpickr-calendar:after {
    position: absolute;
    display: block;
    pointer-events: none;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    left: 22px;
  }
  .flatpickr-calendar.rightMost:before,
  .flatpickr-calendar.rightMost:after {
    left: auto;
    right: 22px;
  }
  .flatpickr-calendar:before {
    border-width: 5px;
    margin: 0 -5px;
  }
  .flatpickr-calendar:after {
    border-width: 4px;
    margin: 0 -4px;
  }
  .flatpickr-calendar.arrowBottom:before,
  .flatpickr-calendar.arrowBottom:after {
    top: 100%;
  }
  .flatpickr-calendar.arrowBottom:before {
    border-top-color: #eee;
  }
  .flatpickr-calendar.arrowBottom:after {
    border-top-color: #fff;
  }
  .flatpickr-calendar:focus {
    outline: 0;
  }
  .flatpickr-wrapper {
    width: 100%;
    position: relative;
    display: inline-block;
  }
  .flatpickr-months {
    padding: 10px 0;
    display: flex;
  }
  .flatpickr-months .flatpickr-month {
    background: transparent;
    color: #4e5d78;
    fill: #4e5d78;
    height: 28px;
    line-height: 1;
    text-align: center;
    position: relative;
    user-select: none;
    overflow: hidden;
    flex: 1;
  }
  .flatpickr-months .flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month {
    text-decoration: none;
    cursor: pointer;
    position: absolute;
    top: 0px;
    line-height: 16px;
    height: 28px;
    padding: 20px;
    z-index: 3;
    color: #4e5d78;
    fill: #4e5d78;
  }
  .flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
  .flatpickr-months .flatpickr-next-month.flatpickr-disabled {
    display: none;
  }
  .flatpickr-months .flatpickr-prev-month i,
  .flatpickr-months .flatpickr-next-month i {
    position: relative;
  }
  .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
    left: 0;
  }

  .flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
  .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
    right: 0;
  }

  .flatpickr-months .flatpickr-prev-month:hover,
  .flatpickr-months .flatpickr-next-month:hover {
    color: ${(p) => p.theme.colors.cocktails};
  }
  .flatpickr-months .flatpickr-prev-month:hover svg,
  .flatpickr-months .flatpickr-next-month:hover svg {
    fill: ${(p) => p.theme.colors.cocktails};
  }
  .flatpickr-months .flatpickr-prev-month svg,
  .flatpickr-months .flatpickr-next-month svg {
    width: 14px;
    height: 14px;
  }
  .flatpickr-months .flatpickr-prev-month svg path,
  .flatpickr-months .flatpickr-next-month svg path {
    transition: fill 0.1s;
    fill: inherit;
  }
  .numInputWrapper {
    position: relative;
    height: auto;
  }
  .numInputWrapper input,
  .numInputWrapper span {
    display: inline-block;
  }
  .numInputWrapper input {
    width: 100%;
  }
  .numInputWrapper input::-ms-clear {
    display: none;
  }
  .numInputWrapper input::-webkit-outer-spin-button,
  .numInputWrapper input::-webkit-inner-spin-button {
    margin: 0;
  }
  .numInputWrapper span {
    position: absolute;
    right: 0;
    width: 14px;
    padding: 0 4px 0 2px;
    height: 50%;
    line-height: 50%;
    opacity: 0;
    cursor: pointer;
    border: 1px solid rgba(64, 72, 72, 0.15);
    box-sizing: border-box;
  }
  .numInputWrapper span:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  .numInputWrapper span:active {
    background: rgba(0, 0, 0, 0.2);
  }
  .numInputWrapper span:after {
    display: block;
    content: '';
    position: absolute;
  }
  .numInputWrapper span.arrowUp {
    top: 0;
    border-bottom: 0;
  }

  .numInputWrapper span.arrowUp:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid rgba(64, 72, 72, 0.6);
    top: 26%;
  }
  .numInputWrapper span.arrowDown {
    top: 50%;
  }
  .numInputWrapper span.arrowDown:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid rgba(64, 72, 72, 0.6);
    top: 40%;
  }
  .numInputWrapper span svg {
    width: inherit;
    height: auto;
  }
  .numInputWrapper span svg path {
    fill: rgba(60, 63, 64, 0.5);
  }
  .numInputWrapper:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  .flatpickr-current-month {
    font-size: ${(p) => p.theme.fontSizes[1]};
    font-weight: 400;
    color: inherit;
    position: absolute;
    width: 75%;
    left: 12.5%;
    padding: 6.16px 0 0 0;
    line-height: 1;
    height: 28px;
    display: inline-block;
    text-align: center;
    transform: translate3d(0px, 0px, 0px);
  }
  .flatpickr-current-month span.cur-month {
    font-family: inherit;
    font-weight: 700;
    color: inherit;
    display: inline-block;
    margin-left: 0.5ch;
    padding: 0;
  }
  .flatpickr-current-month span.cur-month:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  .flatpickr-current-month .numInputWrapper {
    width: 6ch;
    width: 7ch\0;
    display: inline-block;
  }
  .flatpickr-current-month .numInputWrapper span.arrowUp:after {
    border-bottom-color: #4e5d78;
  }
  .flatpickr-current-month .numInputWrapper span.arrowDown:after {
    border-top-color: #4e5d78;
  }
  .flatpickr-current-month input.cur-year {
    background: transparent;
    box-sizing: border-box;
    color: inherit;
    cursor: text;
    padding: 0 0 0 0.5ch;
    margin: 0;
    display: inline-block;
    font-size: inherit;
    font-family: inherit;
    font-weight: 500;
    line-height: inherit;
    height: auto;
    border: 0;
    border-radius: 0;
    vertical-align: initial;
    appearance: textfield;
  }
  .flatpickr-current-month input.cur-year:focus {
    outline: 0;
  }
  .flatpickr-current-month input.cur-year[disabled],
  .flatpickr-current-month input.cur-year[disabled]:hover {
    font-size: 100%;
    color: rgba(60, 63, 64, 0.5);
    background: transparent;
    pointer-events: none;
  }
  .flatpickr-current-month .flatpickr-monthDropdown-months {
    appearance: menulist;
    background: transparent;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    color: inherit;
    cursor: pointer;
    font-size: ${(p) => p.theme.fontSizes[1]};
    font-family: inherit;
    font-weight: ${(p) => p.theme.fontWeights.regular};
    height: 22px;
    line-height: inherit;
    margin: -1px 0 0 0;
    outline: none;
    padding: 0 0 0 0.5ch;
    position: relative;
    vertical-align: initial;
    width: auto;
    margin-right: 12px;
  }
  .flatpickr-current-month .flatpickr-monthDropdown-months:focus,
  .flatpickr-current-month .flatpickr-monthDropdown-months:active {
    outline: none;
  }
  .flatpickr-current-month .flatpickr-monthDropdown-months:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  .flatpickr-current-month
    .flatpickr-monthDropdown-months
    .flatpickr-monthDropdown-month {
    background-color: transparent;
    outline: none;
    padding: 0;
  }
  .flatpickr-weekdays {
    background: transparent;
    text-align: center;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    height: 28px;
  }
  .flatpickr-weekdays .flatpickr-weekdaycontainer {
    display: flex;
    flex: 1;
  }
  span.flatpickr-weekday {
    cursor: default;
    font-size: ${(p) => p.theme.fontSizes[1]};
    background: transparent;
    color: ${(p) => p.theme.colors.grey200};
    line-height: 1;
    margin: 0;
    text-align: center;
    display: block;
    flex: 1;
    font-weight: ${(p) => p.theme.fontWeights.regular};
  }
  .dayContainer,
  .flatpickr-weeks {
    padding: 1px 0 0 0;
  }
  .flatpickr-days {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    width: 100%;
  }
  .flatpickr-days:focus {
    outline: 0;
  }
  .dayContainer {
    border-right: 0;
    padding: 0;
    outline: 0;
    text-align: left;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    display: inline-block;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }
  .dayContainer + .dayContainer {
    box-shadow: -1px 0 0 #eee;
  }
  .flatpickr-day {
    background: none;
    border: 1px solid transparent;
    border-radius: 150px;
    box-sizing: border-box;
    color: #0a1f44;
    cursor: pointer;
    font-weight: ${(p) => p.theme.fontWeights.regular};
    width: 14.2857143%;
    flex-basis: 14.2857143%;
    max-width: 39px;
    height: 39px;
    line-height: 39px;
    margin: 0;
    display: inline-block;
    position: relative;
    justify-content: center;
    text-align: center;
  }
  .flatpickr-day.inRange,
  .flatpickr-day.prevMonthDay.inRange,
  .flatpickr-day.nextMonthDay.inRange,
  .flatpickr-day.today.inRange,
  .flatpickr-day.prevMonthDay.today.inRange,
  .flatpickr-day.nextMonthDay.today.inRange,
  .flatpickr-day:hover,
  .flatpickr-day.prevMonthDay:hover,
  .flatpickr-day.nextMonthDay:hover,
  .flatpickr-day:focus,
  .flatpickr-day.prevMonthDay:focus,
  .flatpickr-day.nextMonthDay:focus {
    cursor: pointer;
    outline: 0;
    background: ${(p) => p.theme.colors.cocktails};
    color: ${(p) => p.theme.colors.white};
  }
  .flatpickr-day.today {
    color: ${(p) => p.theme.colors.cocktails};
  }
  .flatpickr-day.today:hover,
  .flatpickr-day.today:focus {
    border-color: ${(p) => p.theme.colors.cocktails};
    background: ${(p) => p.theme.colors.cocktails};
    color: #fff;
  }
  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    background: ${(p) => p.theme.colors.cocktails};
    border-color: ${(p) => p.theme.colors.cocktails};
    color: ${(p) => p.theme.colors.white};
    box-shadow: none;
  }
  .flatpickr-day.selected.startRange,
  .flatpickr-day.startRange.startRange,
  .flatpickr-day.endRange.startRange {
    border-radius: 50px 0 0 50px;
  }
  .flatpickr-day.selected.endRange,
  .flatpickr-day.startRange.endRange,
  .flatpickr-day.endRange.endRange {
    border-radius: 0 50px 50px 0;
  }
  .flatpickr-day.selected.startRange.endRange,
  .flatpickr-day.startRange.startRange.endRange,
  .flatpickr-day.endRange.startRange.endRange {
    border-radius: 50px;
  }
  .flatpickr-day.inRange {
    border-radius: 0;
  }
  .flatpickr-day.flatpickr-disabled,
  .flatpickr-day.flatpickr-disabled:hover,
  .flatpickr-day.prevMonthDay,
  .flatpickr-day.nextMonthDay,
  .flatpickr-day.notAllowed,
  .flatpickr-day.notAllowed.prevMonthDay,
  .flatpickr-day.notAllowed.nextMonthDay {
    color: #b0b7c3;
    background: transparent;
    border-color: #e9e9e9;
    cursor: default;
  }
  .flatpickr-day.flatpickr-disabled,
  .flatpickr-day.flatpickr-disabled:hover {
    cursor: not-allowed;
    color: rgba(64, 72, 72, 0.1);
  }
  .flatpickr-day.week.selected {
    border-radius: 0;
    box-shadow: -5px 0 0 ${(p) => p.theme.colors.cocktails},
      5px 0 0 ${(p) => p.theme.colors.cocktails};
  }
  .flatpickr-day.hidden {
    visibility: hidden;
  }
  .flatpickr-weekwrapper {
    float: left;
  }
  .flatpickr-weekwrapper .flatpickr-weeks {
    padding: 0 12px;
    box-shadow: 1px 0 0 #eee;
  }
  .flatpickr-weekwrapper .flatpickr-weekday {
    float: none;
    width: 100%;
    line-height: 28px;
  }
  .flatpickr-weekwrapper span.flatpickr-day,
  .flatpickr-weekwrapper span.flatpickr-day:hover {
    display: block;
    width: 100%;
    max-width: none;
    color: #b0b7c3;
    background: transparent;
    cursor: default;
    border: none;
  }
  .flatpickr-innerContainer {
    display: block;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;
  }
  .flatpickr-rContainer {
    display: inline-block;
    padding: 0;
    box-sizing: border-box;
  }
  .flatpickr-time {
    text-align: center;
    outline: 0;
    display: block;
    height: 0;
    line-height: 40px;
    max-height: 40px;
    box-sizing: border-box;
    overflow: hidden;
  }
  .flatpickr-time:after {
    content: '';
    display: table;
    clear: both;
  }
  .flatpickr-time .numInputWrapper {
    flex: 1;
    width: 40%;
    height: 40px;
    float: left;
  }
  .flatpickr-time .numInputWrapper span.arrowUp:after {
    border-bottom-color: #0a1f44;
  }
  .flatpickr-time .numInputWrapper span.arrowDown:after {
    border-top-color: #0a1f44;
  }
  .flatpickr-time.hasSeconds .numInputWrapper {
    width: 26%;
  }
  .flatpickr-time.time24hr .numInputWrapper {
    width: 49%;
  }
  .flatpickr-time input {
    background: transparent;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    text-align: center;
    margin: 0;
    padding: 0;
    height: inherit;
    line-height: inherit;
    color: #0a1f44;
    font-size: 14px;
    position: relative;
    box-sizing: border-box;
    appearance: textfield;
  }
  .flatpickr-time input.flatpickr-hour {
    font-weight: bold;
  }
  .flatpickr-time input.flatpickr-minute,
  .flatpickr-time input.flatpickr-second {
    font-weight: 500;
  }
  .flatpickr-time input:focus {
    outline: 0;
    border: 0;
  }
  .flatpickr-time .flatpickr-time-separator,
  .flatpickr-time .flatpickr-am-pm {
    height: inherit;
    float: left;
    line-height: inherit;
    color: #0a1f44;
    font-weight: bold;
    width: 2%;
    user-select: none;
    align-self: center;
  }
  .flatpickr-time .flatpickr-am-pm {
    outline: 0;
    width: 18%;
    cursor: pointer;
    text-align: center;
    font-weight: 500;
  }
  .flatpickr-time input:hover,
  .flatpickr-time .flatpickr-am-pm:hover,
  .flatpickr-time input:focus,
  .flatpickr-time .flatpickr-am-pm:focus {
    background: ${(p) => p.theme.colors.cocktails};
  }
  .flatpickr-input[readonly] {
    cursor: pointer;
  }
  @-webkit-keyframes fpFadeInDown {
    from {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes fpFadeInDown {
    from {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  span.flatpickr-day,
  span.flatpickr-day.prevMonthDay,
  span.flatpickr-day.nextMonthDay {
    border-radius: 0 !important;
    border: none;
    max-width: none;
    border-right-color: transparent;
  }
  span.flatpickr-day:nth-child(n + 8),
  span.flatpickr-day.prevMonthDay:nth-child(n + 8),
  span.flatpickr-day.nextMonthDay:nth-child(n + 8) {
    border-top-color: transparent;
  }
  span.flatpickr-day:nth-child(7n-6),
  span.flatpickr-day.prevMonthDay:nth-child(7n-6),
  span.flatpickr-day.nextMonthDay:nth-child(7n-6) {
    border-left: 0;
  }
  span.flatpickr-day:nth-child(n + 36),
  span.flatpickr-day.prevMonthDay:nth-child(n + 36),
  span.flatpickr-day.nextMonthDay:nth-child(n + 36) {
    border-bottom: 0;
  }
  span.flatpickr-day:nth-child(-n + 7),
  span.flatpickr-day.prevMonthDay:nth-child(-n + 7),
  span.flatpickr-day.nextMonthDay:nth-child(-n + 7) {
    margin-top: 0;
  }
  span.flatpickr-day.today:not(.selected),
  span.flatpickr-day.prevMonthDay.today:not(.selected),
  span.flatpickr-day.nextMonthDay.today:not(.selected) {
    border-color: #e9e9e9;
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom-color: ${(p) => p.theme.colors.cocktails};
  }
  span.flatpickr-day.startRange,
  span.flatpickr-day.prevMonthDay.startRange,
  span.flatpickr-day.nextMonthDay.startRange,
  span.flatpickr-day.endRange,
  span.flatpickr-day.prevMonthDay.endRange,
  span.flatpickr-day.nextMonthDay.endRange {
    border-color: ${(p) => p.theme.colors.cocktails};
  }
  span.flatpickr-day.today,
  span.flatpickr-day.prevMonthDay.today,
  span.flatpickr-day.nextMonthDay.today,
  span.flatpickr-day.selected,
  span.flatpickr-day.prevMonthDay.selected,
  span.flatpickr-day.nextMonthDay.selected {
    z-index: 2;
  }
  .flatpickr-weekwrapper,
  .flatpickr-weeks {
    box-shadow: none;
  }
  .flatpickr-weekwrapper span.flatpickr-day {
    border: 0;
    margin: -1px 0 0 -1px;
  }
  .hasWeeks .flatpickr-days {
    border-right: 0;
  }

  /* input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  } */

  @media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
    span.flatpickr-day {
      display: block;
      flex: 1 0 auto;
    }
  }

  input.flatpickr-input {
    font-size: ${(p) => p.theme.fontSizes[3]};
    font-weight: ${(p) => p.theme.fontWeights.regular};
    height: 100%;
    padding: 12px 16px;
    width: 100%;
    font-family: inherit;
    color: ${(p) => p.theme.colors.grey900};
    outline: none;
    border: 1px solid ${(p) => p.theme.colors[p.isValid ? 'grey300' : 'error']};
    border-radius: ${(p) => p.theme.radii.sm};
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.16);
    transition: box-shadow 0.2s, border-color 0.2s, border-width 0.2s;

    &:disabled {
      cursor: default;
      background: ${(p) => p.theme.colors.grey100};
      border-color: ${(p) => p.theme.colors.grey200};
    }
    &:focus {
      outline: none;
      border-color: ${(p) => p.theme.colors.grey500};
    }
  }
`;

export default Styles;
