import { ReactNode, useEffect, useState } from 'react';
import { Box, SystemProps, Flex } from '@storyofams/react-ui';
import { startsWith } from 'lodash';
import { useRouter } from 'next/router';

import { NavigationItem, FooterItem } from '~lib/storyblok/sdk';

import { Footer } from '../Footer';
import { Navigation } from '../Navigation';
import { ActionTicker } from '../Navigation/components/ActionTicker';
import { NavigationPrimary } from '../Navigation/components/NavigationPrimary';

const ACCOUNT_BG = '#f4f4f5';
const MOMENTS_BG = '#f9f7f2';

interface LayoutProps extends SystemProps {
  children: ReactNode;
  navigation: NavigationItem;
  footer: FooterItem;
  navigationBg?: SystemProps['bg'];
  storySlugs?: any;
}

export const Layout = ({
  children,
  navigation,
  footer,
  navigationBg,
  storySlugs,
  ...props
}: LayoutProps) => {
  const [isScrolled, setScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const router = useRouter();

  const onScroll = () => {
    if (window.scrollY > 54) {
      if (!isScrolled) {
        setScrolled(true);
      }
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  let bg = 'rgba(255, 255, 255, 0.8)' as any;
  if (!isScrolled) {
    if (startsWith(router?.pathname, '/account')) {
      bg = ACCOUNT_BG;
    } else if (router?.pathname === '/cocktail-moments') {
      bg = MOMENTS_BG;
    } else if (navigationBg) {
      bg = navigationBg;
    }
  }

  return (
    <Flex flexDirection="column" width="100%">
      <Box
        pb={[1.25, 1.25, 1.75]}
        bg={['rgba(255, 255, 255, 0.8)', 'rgba(255, 255, 255, 0.8)', bg]}
      >
        {!!navigation?.content?.pre_header &&
          process.env.NEXT_PUBLIC_STORE_COUNTRY !== 'b2b' && (
            <ActionTicker actionTicker={navigation?.content?.pre_header} />
          )}
      </Box>

      <Navigation>
        <NavigationPrimary
          badge={1}
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
          links={navigation?.content?.links}
          mobileLinks={navigation?.content?.mobile_links}
          cartHint={navigation?.content?.cart_hint}
          scrolledStyles={{
            bg: ['rgba(255, 255, 255, 0.8)', 'rgba(255, 255, 255, 0.8)', bg],
            backdropFilter: 'blur(8px)',
          }}
        />
      </Navigation>

      <Flex
        mt={[1.25, 1.25, 0]}
        borderTop={['1px', '1px', 'none']}
        borderColor={['grey200', 'grey200', undefined]}
        width="100%"
        as="main"
        flexDirection="column"
        flex="1"
        bg={startsWith(router?.pathname, '/account') && ACCOUNT_BG}
        {...props}
      >
        {children}
      </Flex>

      <Footer links={footer} storySlugs={storySlugs} />
    </Flex>
  );
};
