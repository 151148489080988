export const getLocalizedUrl = (router, locale: string, story?: any) => {
  let path = router.asPath?.replace(/(\?|#).*/, '');

  if (locale === router.defaultLocale) {
    if (story?.default_full_slug) {
      path = `/${story.default_full_slug}`;
    }
  } else if (story?.translated_slugs?.length) {
    const translatedSlug = story.translated_slugs.find(
      ({ lang }) => lang === locale,
    )?.path;

    if (translatedSlug) {
      path = `/${translatedSlug}`;
    }
  }

  const countrySubdomain =
    process.env.NEXT_PUBLIC_STORE_COUNTRY !== 'nl'
      ? `${process.env.NEXT_PUBLIC_STORE_COUNTRY}.`
      : '';
  let url = `https://${countrySubdomain}bols.com${
    locale !== router.defaultLocale ? `/${locale}` : ''
  }${router.basePath}${path}`;

  if (url.endsWith('/')) {
    url = url.slice(0, -1);
  }

  return url;
};
