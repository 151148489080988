import React, { FC, forwardRef } from 'react';
import { InputWrapperProps } from '@storyofams/react-ui';
import { pick, omit } from '@styled-system/props';
import { useId } from 'react-id-generator';
import { Props } from 'react-select';
import { InputWrapper } from '../InputWrapper';

import StyledSelect from './StyledSelect';

export type SelectProps = {
  id?: string;
} & InputWrapperProps &
  Props;

export const Select: FC<SelectProps> = forwardRef(
  (
    {
      status = false,
      statusMessage = false,
      label = false,
      error,
      id: givenId,
      ...props
    },
    ref,
  ) => {
    const autoId = useId();
    const id = givenId || `select-${autoId}`;

    return (
      <InputWrapper
        id={id}
        label={label}
        status={status}
        statusMessage={statusMessage}
        error={error}
        {...pick(props)}
      >
        <StyledSelect
          isClearable={false}
          isSearchable={false}
          forwardedRef={ref}
          {...omit(props)}
          value={
            typeof props.value === 'string' || typeof props.value === 'number'
              ? // @ts-ignore
                props?.options?.find(
                  ({ value }) => value === (props.value as any),
                )
              : props.value
          }
          inputId={id}
        />
      </InputWrapper>
    );
  },
);
