import { useEffect } from 'react';
import { Flex, ChevronRight, ChevronLeft, Icon } from '@storyofams/react-ui';
import styled from 'styled-components';
import { Button } from '~components';
import { usePagination } from '~hooks';

const StyledButton = styled(Button).attrs({ variant: 'unstyled' })<{
  transparent?: boolean;
}>`
  width: 24px;
  height: 24px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(p) => p.theme.colors.grey900};
  border-radius: ${(p) => p.theme.radii.full};
  font-size: ${(p) => p.theme.fontSizes[1.5]}px;
  font-weight: ${(p) => p.theme.fontWeights.bold};
  transition: all 0.2s ease;

  &:disabled {
    opacity: 0.2;
  }

  ${(p) => p.transparent && 'border: 1px solid transparent !important;'}
`;

interface Props {
  onChange(number): void;
  itemsPerPage?: number;
  total: number;
}

export const Pagination = ({ onChange, itemsPerPage = 10, total }: Props) => {
  const {
    setPage,
    goToPreviousPage,
    goToNextPage,
    page,
    totalPages,
    paginationItems,
  } = usePagination(total, itemsPerPage);

  useEffect(() => {
    onChange(page);
  }, [page]);

  return (
    <Flex>
      <StyledButton onClick={goToPreviousPage} mr={1} disabled={page <= 1}>
        <Icon icon={ChevronLeft} width="5px" />
      </StyledButton>

      {paginationItems?.map((item) =>
        typeof item === 'string' ? (
          <Flex
            minWidth="24px"
            mr={1}
            alignItems="center"
            justifyContent="center"
          >
            {item}
          </Flex>
        ) : (
          <StyledButton
            onClick={() => setPage(item)}
            mr={1}
            bg={page === item && 'grey900'}
            color={page === item && 'white'}
            transparent={page !== item}
          >
            {item}
          </StyledButton>
        ),
      )}

      <StyledButton onClick={goToNextPage} disabled={page >= totalPages}>
        <Icon icon={ChevronRight} width="5px" />
      </StyledButton>
    </Flex>
  );
};
