import React, { ReactNode } from 'react';
import { Box, Icon } from '@storyofams/react-ui';
import Swiper, { ReactIdSwiperProps } from 'react-id-swiper';
import styled from 'styled-components';
import SwiperCore, {
  Virtual,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  Parallax,
  Zoom,
  Lazy,
  Controller,
  A11y,
  History,
  HashNavigation,
  Autoplay,
  EffectFade,
  EffectCube,
  EffectFlip,
  EffectCoverflow,
  Thumbs,
} from 'swiper';

import { ArrowLeft } from '~components/common/Icons';
import 'swiper/swiper-bundle.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import 'swiper/components/navigation/navigation.min.css';
import { Slide } from './Slide';

SwiperCore.use([
  Virtual,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  Parallax,
  Zoom,
  Lazy,
  Controller,
  A11y,
  History,
  HashNavigation,
  Autoplay,
  EffectFade,
  EffectCube,
  EffectFlip,
  EffectCoverflow,
  Thumbs,
]);

export type SliderProps = {
  children: ReactNode[];
} & ReactIdSwiperProps;

export const Slider = ({ children, ...props }: SliderProps) => {
  return (
    <Container>
      <Swiper
        slidesPerView="auto"
        spaceBetween={24}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        direction="horizontal"
        mousewheel={false}
        renderPrevButton={() => <NavigationButton />}
        renderNextButton={() => <NavigationButton direction="next" />}
        watchOverflow
        {...props}
      >
        {children?.map((child, index) => (
          <Box
            key={String(index)}
            // mr={[2, 3]}
            // maxWidth={{ all: '200px', md: '320px' }}
            display="block"
            className="swiper-slide"
          >
            {child}
          </Box>
        ))}
      </Swiper>
    </Container>
  );
};

Slider.Slide = Slide;

const NavigationButton = ({ direction = 'prev' }) => (
  <Box
    borderRadius="full"
    width="48px"
    height="48px"
    bg="white"
    className={`swiper-button-${direction}`}
  >
    <Icon
      icon={ArrowLeft}
      fontSize={2.25}
      color="grey900"
      css={{
        transform: `rotate(${direction === 'next' ? '180deg' : '0deg'})`,
      }}
    />
  </Box>
);

const Container = styled.div`
  width: 100%;

  & .swiper-container {
    overflow: inherit;

    .swiper-button-next,
    .swiper-button-prev {
      &:after {
        content: unset;
      }
    }
  }
`;
