import { Box, css } from '@storyofams/react-ui';
import ReactPlayer from 'react-player/lazy';

export const Video = ({ url, ...props }: any) => {
  return (
    <Box
      mx="auto"
      display="flex"
      justifyContent="center"
      bg="grey200"
      borderRadius="md"
      css={css({
        position: 'relative',
        width: '100%',
        pt: '56.25%',
        '> div': {
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100% !important',
          height: '100% !important',
        },
        iframe: {
          borderRadius: 'md',
          width: '100%',
          height: '100%',
        },
      })}
    >
      {!!url && (
        <ReactPlayer
          url={url}
          config={{
            youtube: {
              playerVars: {
                modestbranding: 1,
                controls: true,
              },
            },
          }}
          {...props}
        />
      )}
    </Box>
  );
};
