import React, { ReactNode, useState } from 'react';
import { css, Box, Flex, SystemProps } from '@storyofams/react-ui';
import { useMeasure } from 'react-use';

import { Heading } from '../Heading';

export interface AccordionProps extends SystemProps {
  title: string;
  children: ReactNode | string;
}

export const Accordion = ({ title, children, ...props }: AccordionProps) => {
  const [open, setOpen] = useState(false);
  const [ref, { height }] = useMeasure();

  return (
    <Box borderBottom="1px solid" borderBottomColor="grey200" mt="-1px">
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
        /** @ts-ignore this is an indication of whats wrong with the return type of the polymorph */
        onClick={() => setOpen(!open)}
        pt={[3, 4]}
        pb={[2, 3]}
        cursor="pointer"
        {...props}
      >
        <Heading variant="h4" as="h5" fontSize={[2.5, '28px']}>
          {title}
        </Heading>
        <Flex
          css={css({
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: ['20px', '28px'],
            mt: '-1px',
            '&::before, &::after': {
              content: JSON.stringify(''),
              bg: 'grey900',
              height: '2px',
              width: ['12px', '18px'],
              display: 'block',
              transition: '0.1s transform ease-in-out',
              borderRadius: '2px',
              transform: open ? 'rotate(45deg)' : 'rotate(0deg)',
            },
            '&::after': {
              transform: open
                ? 'translateY(-2px) rotate(135deg)'
                : 'translateY(-2px) rotate(90deg)',
            },
          })}
        />
      </Flex>
      <Box
        maxHeight={open ? [`${height + 2 * 8}px`, `${height + 3 * 8}px`] : 0}
        transition="max-height ease 0.3s"
        overflow="hidden"
      >
        <Box
          pb={[3, 4]}
          transition="opacity ease 0.25s"
          opacity={open ? 1 : 0}
          ref={ref}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
