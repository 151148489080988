import { useIntl } from 'react-intl';

export const useDate = () => {
  const intl = useIntl();

  const formatDate = (
    date,
    options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    } as any,
  ) => intl.formatDate(date, options);

  return {
    formatDate,
  };
};
