import {
  StatusMessage as StyledStatusMessage,
  StatusMessageProps,
} from '@storyofams/react-ui';

export const StatusMessage = ({
  children,
  status,
  ...props
}: StatusMessageProps) => (
  <StyledStatusMessage
    status={status}
    fontWeight="regular"
    color="grey500"
    textAlign="left"
    fontSize={1.5}
    {...props}
  >
    {children}
  </StyledStatusMessage>
);
