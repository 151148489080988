import { GraphQLClient } from 'graphql-request';
import { getSdk } from './sdk';

const getClient = (locale?: string) =>
  new GraphQLClient(
    `${process.env.NEXT_PUBLIC_SHOPIFY_SHOP_ORIGIN}/api/2021-04/graphql.json`,
    {
      headers: {
        'X-Shopify-Storefront-Access-Token':
          process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
        ...(locale ? { 'Accept-Language': locale } : {}),
      },
    },
  );

export const shopifySdk = getSdk(getClient());

export const getShopifySdk = (locale: string) => getSdk(getClient(locale));
