import { Icon, SystemProps, Flex, Box } from '@storyofams/react-ui';

import { Checkmark } from '~components/common/Icons';
import { Text } from '~components/common/Text';
import { NavigationComponent } from '~lib/storyblok/sdk';

interface Props extends SystemProps {
  actionTicker: NavigationComponent['pre_header'];
}

const ActionTickerItem = ({
  text,
}: NavigationComponent['pre_header']['actionTicker']) => {
  return (
    <>
      <Icon icon={Checkmark} fontSize={2} color="white" mr={0.75} />
      <Text variant="s" color="white">
        {text}
      </Text>
    </>
  );
};

export const ActionTicker = ({ actionTicker, ...props }: Props) => {
  return (
    <Flex
      position="relative"
      alignItems="center"
      justifyContent="center"
      py={0.75}
      px={[6, 192 as any]}
      width="100%"
      backgroundColor="grey900"
      fontSize={1.5}
      overflow="hidden"
      height="40px"
      {...props}
    >
      {actionTicker?.map(({ text, _uid }, idx) => (
        <Box
          key={_uid}
          alignItems="center"
          display={
            idx === 0 || actionTicker?.length === 1
              ? 'flex'
              : { all: 'none', md: 'flex' }
          }
          mr={idx < actionTicker?.length - 1 ? { md: 8 } : undefined}
          {...props}
        >
          <ActionTickerItem text={text} />
        </Box>
      ))}
    </Flex>
  );
};
