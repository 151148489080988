import React, { useState } from 'react';
import { Box } from '@storyofams/react-ui';
import { AnimatePresence, m } from 'framer-motion';
import dynamic from 'next/dynamic';
import styled from 'styled-components';

import type { Loader as LoaderType } from './Loader';

const LottieWrapper = styled(Box)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
`;

const Loader = dynamic(() => import('./Loader').then((mod) => mod.Loader), {
  loading: () => (
    <m.div
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.5, duration: 0.3 }}
    >
      <LottieWrapper bg="white" />
    </m.div>
  ),
}) as typeof LoaderType;

export const PageLoader = () => {
  const [visible, setVisibility] = useState(true);

  if (visible) {
    return (
      <LottieWrapper>
        <AnimatePresence>
          <Loader setVisibility={setVisibility} />
        </AnimatePresence>
      </LottieWrapper>
    );
  }

  return null;
};
