import { css } from '@styled-system/css';

import { Button } from '~components/common/Button';

export const NavigationButton = ({ children, ...props }) => (
  <Button
    variant="unstyled"
    minWidth="40px"
    height="40px !important"
    display="flex"
    alignItems="center"
    justifyContent="center"
    color="cocktails"
    transition="0.18s background-color ease-in-out"
    borderRadius="full"
    bg="tranparent"
    css={css({
      '&:hover, &:focus, &.active': { bg: 'grey200' },
    })}
    {...props}
  >
    {children}
  </Button>
);
