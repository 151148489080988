import React, { forwardRef } from 'react';
import { CustomCheckbox, CustomCheckboxProps } from '@reach/checkbox';
import { InputWrapperProps } from '@storyofams/react-ui';
import { pick, omit } from '@styled-system/props';
import { useId } from 'react-id-generator';
import styled from 'styled-components';

import { InputWrapper } from '~components/common/InputWrapper';
import { Text } from '~components/common/Text';

const StyledCheckbox = styled(CustomCheckbox)`
  appearance: none;
  background-color: transparent;
  padding: 0;
  margin-right: 12px;
  border: none;

  position: relative;

  display: inline-flex;

  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;

  flex-shrink: 0;

  &[data-reach-custom-checkbox] {
    input {
      appearance: none;
      outline: none;

      display: flex;
      align-items: center;
      position: relative;

      width: 100%;
      height: 100%;

      background: ${({ theme }) => theme.colors.grey100};
      border-radius: ${({ theme }) => theme.radii.xs};
      border: 1px solid ${({ theme }) => theme.colors.cocktails};

      transition: background 0.18s ease-in-out, box-shadow 0.18s ease-in-out;

      cursor: pointer;

      &:before {
        content: '';

        position: absolute;
        left: 7px;
        bottom: 8px;

        width: 8px;
        height: 11px;

        display: none;

        border-right: 2px solid;
        border-bottom: 2px solid;
        border-color: ${(p) => p.theme.colors.white};

        transform: rotate(40deg);

        transition: border-color 0.15s;
      }

      &:not([disabled]) {
        cursor: pointer;

        &:hover,
        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.cocktails};
        }
      }
    }
  }

  &[data-reach-custom-checkbox][data-state='checked'] {
    input {
      background: ${({ theme }) => theme.colors.grey900};

      &:before {
        display: block;
      }
    }
  }

  ${(props) => props.css}
`;

type CheckboxProps = {
  id?: string;
  disabled?: boolean;
} & CustomCheckboxProps &
  InputWrapperProps;

export const Checkbox = forwardRef<CustomCheckboxProps, CheckboxProps>(
  (
    {
      label,
      status = false,
      statusMessage = false,
      error,
      id: givenId,
      disabled,
      ...rest
    },
    ref,
  ) => {
    const autoId = useId();
    const id = givenId || `checkbox-${autoId}`;

    return (
      <InputWrapper
        status={status}
        statusMessage={statusMessage}
        error={error}
        {...pick(rest)}
      >
        {label ? (
          <Text
            as="label"
            variant="label"
            fontSize={2}
            htmlFor={id}
            opacity={disabled ? 0.6 : 1}
            cursor={disabled ? 'not-allowed' : 'pointer'}
            color="grey900"
            textAlign="left"
          >
            <StyledCheckbox
              id={id}
              ref={ref}
              mr={1.5}
              disabled={disabled}
              {...omit(rest)}
            />
            {label}
          </Text>
        ) : (
          <StyledCheckbox
            id={id}
            ref={ref}
            mr={1.5}
            disabled={disabled}
            {...omit(rest)}
          />
        )}
      </InputWrapper>
    );
  },
);
