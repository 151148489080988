import { ReactNode } from 'react';
import { Flex, Icon, SystemProps } from '@storyofams/react-ui';
import { BreadcrumbJsonLd } from 'next-seo';
import styled from 'styled-components';

import { Button } from '../Button';
import { ChevronRight } from '../Icons';
import { Text } from '../Text';

interface Item {
  content?: ReactNode;
  link: string;
  name: string;
}

type CustomProps = {
  items: Item[];
} & SystemProps;

const Wrapper = styled(Flex)`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: ${(p) => p.theme.fontSizes[2]}px;
  line-height: 130%;
  color: ${(p) => p.theme.colors.cocktails};
  border-radius: 99px;
  padding: ${(p) => p.theme.space[0.5]}px ${(p) => p.theme.space[1]}px;
`;

export const Breadcrumb = ({ items, ...props }: CustomProps) => (
  <Wrapper {...props}>
    {items.map(({ content, link, name }, idx) => {
      const item =
        idx === items?.length - 1 ? (
          <Text
            key={link}
            mr={0.5}
            fontSize={1.5}
            lineHeight="16px"
            color="cocktails"
            aria-label={content ? name : undefined}
          >
            {content || name}
          </Text>
        ) : (
          <Button
            variant="link"
            to={link}
            key={link}
            mr={0.5}
            fontSize={1.5}
            lineHeight="16px"
            color="cocktails"
            aria-label={content ? name : undefined}
          >
            {content || name}
          </Button>
        );

      if (idx < items?.length - 1) {
        return [
          item,
          <Icon key={`arrow-${idx}`} icon={ChevronRight} mr={0.5} />,
        ];
      }

      return [item];
    })}
    <BreadcrumbJsonLd
      itemListElements={items?.map(({ link, name }, idx) => ({
        item: idx !== items?.length - 1 ? link : undefined,
        name,
        position: idx + 1,
      }))}
    />
  </Wrapper>
);
