import React, { forwardRef } from 'react';
import { Icon, InputWrapperProps } from '@storyofams/react-ui';
import { addYears, addDays, startOfDay } from 'date-fns';
import Flatpickr from 'react-flatpickr';

import { InputWrapper } from '~components/common/InputWrapper';
import Styles from './styles';

const now = startOfDay(addDays(new Date(), 1));

const defaultOptions = {
  date: {
    dateFormat: 'd - m - Y',
    minDate: now,
    maxDate: addYears(now, 2),
  },
  time: {
    enableTime: true,
    noCalendar: true,
    dateFormat: 'H:i',
    time_24hr: true,
  },
  dateTime: {
    dateFormat: 'd - m - Y H:i',
    minDate: now,
    maxDate: addYears(now, 2),
    enableTime: true,
    time_24hr: true,
  },
};

interface DatePickerProps extends InputWrapperProps {
  type?: keyof typeof defaultOptions;
  onChange?(dateString: string | string[]): void;
  name: string;
  options?: any;
  value?: any;
  disabled?: boolean;
  placeholder?: string;
}
export const DatePicker = forwardRef(
  (
    {
      name,
      onChange,
      label,
      type = 'date',
      error,
      options,
      value,
      disabled,
      placeholder,
      ...props
    }: DatePickerProps,
    ref,
  ) => {
    return (
      <>
        <InputWrapper label={label} error={error} flex="1" {...props}>
          <Styles isValid={!error}>
            <Flatpickr
              ref={ref}
              name={name}
              placeholder={placeholder}
              options={{
                static: true,
                ...defaultOptions[type],
                ...options,
              }}
              onChange={(val) =>
                onChange(
                  val?.length > 1
                    ? val.map((date) => date.toISOString())
                    : val?.[0]?.toISOString(),
                )
              }
              value={value}
              disabled={disabled}
            />
            <Icon
              icon="calendar-today"
              fontSize={6}
              className="calendar-icon"
            />
          </Styles>
        </InputWrapper>
      </>
    );
  },
);
