import React, { CSSProperties, ReactNode } from 'react';
import { InputWrapper, InputWrapperProps, Stack } from '@storyofams/react-ui';
import { useId } from 'react-id-generator';
import { ResponsiveValue } from 'styled-system';

type CSS = CSSProperties;

type RadioGroupProps = {
  id?: string;
  space?: ResponsiveValue<CSS['margin']>;
  flexDirection?: ResponsiveValue<CSS['flexDirection']>;
  children: ReactNode;
} & InputWrapperProps;

export const RadioGroup = ({
  space = 1,
  flexDirection = 'column',
  id: initialId,
  children,
  ...props
}: RadioGroupProps) => {
  const autoId = useId();
  const id = initialId || `radio-group-${autoId}`;

  return (
    <InputWrapper {...props} id={id}>
      <Stack space={space} flexDirection={flexDirection} role="radiogroup">
        {children}
      </Stack>
    </InputWrapper>
  );
};
