import { getTranslationForLocale } from './getTranslationForLocale';

export const resolveCallToAction = async (
  story,
  sdk,
  locale,
  defaultLocale,
) => {
  const { lang, needsTranslation } = getTranslationForLocale(
    locale,
    defaultLocale,
  );

  if (story?.content?.callToAction?.id) {
    story.content.callToAction = (
      await sdk.callToAction({
        slug: story.content.callToAction.id,
        lang: needsTranslation ? lang : undefined,
      })
    ).CalltoactionItem;
  }

  if (story?.content?.sections?.length) {
    story.content.sections = await Promise.all(
      story.content.sections.map(async (section) => {
        if (
          section?.component === 'cta_dynamic' &&
          section.call_to_action?.id
        ) {
          return {
            ...section,
            call_to_action: (
              await sdk.callToAction({
                slug: section.call_to_action?.id,
                lang: needsTranslation ? lang : undefined,
              })
            ).CalltoactionItem,
          };
        }

        return section;
      }),
    );
  }
};
