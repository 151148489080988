import { forwardRef, useEffect, useMemo, useState } from 'react';
import { Select, InputWrapperProps } from '@storyofams/react-ui';
import type { countries as countriesList } from 'countries-list';
import { Props } from 'react-select';
import styled from 'styled-components';

type SelectProps = {
  id?: string;
} & InputWrapperProps &
  Props;

const Input = forwardRef(
  (
    { countries, ...props }: SelectProps & { countries: typeof countriesList },
    ref,
  ) => {
    const options = useMemo(
      () =>
        Object.keys(countries)
          .map((code) => ({
            label: countries[code].name,
            value: code,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      [],
    );
    return (
      <Select
        ref={ref}
        options={options}
        width="100%"
        fontSize={2}
        {...props}
        value={
          typeof props.value === 'string'
            ? options?.find(({ value }) => value === (props.value as any))
            : props.value
        }
        defaultValue={options?.find(
          ({ value }) => value === (props.defaultValue as any),
        )}
        isSearchable
      />
    );
  },
);

const DynamicInput = forwardRef(({ ...props }: SelectProps, innerRef) => {
  const [countries, setCountries] = useState(null);

  const loadCountries = async () => {
    const loadedCountries = (await import('countries-list')).countries;

    setCountries(loadedCountries);
  };

  useEffect(() => {
    loadCountries();
  }, []);

  if (!countries) {
    return null;
  }

  return <Input {...props} ref={innerRef} countries={countries} />;
});

const Wrapper = styled.div`
  font-size: ${(p) => p.theme.fontSizes[2]}px;

  label {
    font-size: 14px;
    line-height: 140%;
    color: ${(p) => p.theme.colors.grey500};
    font-weight: ${(p) => p.theme.fontWeights.regular};
  }

  .react-select {
    &__placeholder {
      color: ${(p) => p.theme.colors.grey500};
      line-height: 150%;
      position: relative;
      transform: none;
    }

    &__control {
      appearance: none;
      cursor: pointer;
      transition: border-color 0.18s ease;
      border-color: ${(p) => p.theme.colors.grey300};
      border-radius: 8px;
      background-color: #fff;
      height: 50px;

      box-shadow: inset 0px 1px 2px rgb(0 0 0 / 16%);

      transition: border-color 0.18s ease-in-out,
        background-color 0.18s ease-in-out;

      &:hover,
      &:active,
      &:focus {
        border-color: ${(p) => p.theme.colors.grey900};
      }

      .react-select__dropdown-indicator {
        color: ${(p) => p.theme.colors.grey500};
      }

      &--menu-is-open {
        .react-select__dropdown-indicator {
          svg {
            transform: rotate(180deg);
          }
        }
      }

      &--is-disabled {
        cursor: not-allowed;
        opacity: 0.6;
        background: none;

        &:hover {
          border-color: ${(p) => p.theme.colors.grey300};
        }
      }

      &--is-focused {
        border-color: ${(p) => p.theme.colors.grey900};
        box-shadow: none;

        .react-select__placeholder {
          color: ${(p) => p.theme.colors.grey300};
        }
      }
    }

    &__dropdown-indicator {
      color: ${(p) => p.theme.colors.grey900};
    }

    &__single-value {
      color: ${(p) => p.theme.colors.grey900};
      max-width: 100%;
      position: relative;
      transform: none;
    }

    &__value-container {
      padding: 6px 8px 4px 14px !important;
    }

    &__indicator-separator {
      display: none;
    }

    &__menu {
      width: 100%;
      min-width: 80px;
      border-radius: 4px;
    }

    &__menu-list {
      padding: ${(p) => p.theme.space[1]}px;
    }

    &__option {
      padding: ${(p) => p.theme.space[1]}px ${(p) => p.theme.space[2]}px;
      margin-bottom: ${(p) => p.theme.space[0.5]}px;
      transition: border-color 0.18s ease-in-out,
        background-color 0.18s ease-in-out, color 0.18s ease-in-out;
      color: ${(p) => p.theme.colors.grey900};
      border-radius: 4px;
      font-size: ${(p) => p.theme.fontSizes[2]}px;
      line-height: 1.5;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        background-color: ${(p) => p.theme.colors.grey200};
        color: ${(p) => p.theme.colors.grey900};
      }

      & {
        &--is-focused {
          background-color: ${(p) => p.theme.colors.grey400};
          color: ${(p) => p.theme.colors.white};
        }

        &--is-selected,
        &--is-active {
          color: ${(p) => p.theme.colors.white};
          background-color: ${(p) => p.theme.colors.grey500};

          &:hover {
            color: ${(p) => p.theme.colors.white};
            background-color: ${(p) => p.theme.colors.grey500};
          }
        }

        &--is-disabled {
          background: ${(p) => p.theme.colors.grey300};
          cursor: not-allowed;

          &:hover {
            background: ${(p) => p.theme.colors.grey300};
          }
        }
      }
    }
  }
`;

export const CountrySelect = forwardRef((props: SelectProps, ref) => {
  return (
    <Wrapper>
      <DynamicInput innerRef={ref} {...props} />
    </Wrapper>
  );
});
