import { Flex, Icon } from '@storyofams/react-ui';

import { Info, Tick, XCircle } from '~components/common/Icons';
import { Text } from '~components/common/Text';

type ToastProps = {
  msg: string;
  type: 'info' | 'success' | 'error' | 'alert';
};

const content = {
  info: {
    icon: Info,
    color: 'primary800',
  },
  success: {
    icon: Tick,
    color: 'success300',
  },
  error: {
    icon: XCircle,
    color: 'error300',
  },
};

export const Persistent = ({ msg, type }: ToastProps) => (
  <Flex
    p={1.5}
    width="100%"
    borderRadius="md"
    position="relative"
    backgroundColor="grey100"
    alignItems="center"
    minHeight="64px"
  >
    <Icon fontSize={3} icon={content[type].icon} color={content[type].color} />
    <Text ml={2} fontSize={2} color="grey700" maxWidth="100%" lineHeight={1.5}>
      {msg}
    </Text>
  </Flex>
);
