import { getTranslationForLocale } from './getTranslationForLocale';
import { loadMessages } from './loadMessages';
import { staticPropsWithSdk } from './storyblok/client';

export const enhancedStaticProps: typeof staticPropsWithSdk = (
  getStaticProps,
) =>
  staticPropsWithSdk(async (context) => {
    const { defaultLocale, locale, sdk } = context;

    const { lang, needsTranslation } = getTranslationForLocale(
      locale,
      defaultLocale,
    );

    const [
      { FooterItem: footer },
      { NavigationItem: navigation },
      staticProps,
      messages,
    ] = await Promise.all([
      sdk.footer({
        slug: `${needsTranslation ? `${lang}/settings/` : 'settings/'}footer`,
      }),
      sdk.navigation({
        slug: `${
          needsTranslation ? `${lang}/settings/` : 'settings/'
        }navigation`,
      }),
      getStaticProps(context),
      loadMessages(context),
    ]);

    return {
      ...staticProps,
      props: {
        ...(staticProps as any).props,
        footer,
        navigation,
        messages,
      },
    };
  });
