import { ReactNode } from 'react';
import { Flex, Box } from '@storyofams/react-ui';
import { Image } from '@storyofams/storyblok-toolkit';
import { css } from 'styled-components';

import { Heading, Text, BackButton } from '~components';

interface BannerProps {
  title: string;
  description?: string;
  subtitle?: string;
  image?: any;
  backgroundColor?: {
    color: string;
  };
  textColor?: {
    color: string;
  };
  children?: ReactNode;
  backButton?: {
    to: string;
    label: string;
  };
}

export const Banner = ({
  image,
  backgroundColor,
  textColor,
  title,
  description,
  subtitle,
  children,
  backButton,
}: BannerProps) => {
  const hasImage = !!image?.filename;
  const { label, ...buttonProps } = backButton || {};

  return (
    <Flex
      justifyContent={hasImage ? 'flex-start' : 'center'}
      alignItems="center"
      bg={
        hasImage || backgroundColor?.color
          ? backgroundColor?.color || 'grey200'
          : 'white'
      }
      width="100%"
      textAlign={hasImage ? ['center', 'left'] : 'center'}
      borderRadius="16px"
      color={backgroundColor?.color ? textColor?.color : 'grey900'}
      position="relative"
    >
      <Flex
        width={hasImage ? ['100%', '50%'] : '100%'}
        flexDirection="column"
        alignItems={hasImage ? ['center', 'flex-start'] : 'center'}
        p={
          (hasImage || backgroundColor?.color) &&
          (hasImage ? '40px 48px 48px' : '56px 16px')
        }
      >
        {backButton && (
          <BackButton mb={[4, 3]} mt={0} order={0} {...buttonProps}>
            {backButton?.label}
          </BackButton>
        )}

        <Heading
          variant="h1"
          mb={1}
          lineHeight="140%"
          color="inherit"
          letterSpacing="-0.01em"
        >
          {title}
        </Heading>

        <Text lineHeight="high" color="inherit" maxWidth="400px">
          {description || subtitle}
        </Text>

        <Box mt={hasImage ? [5, 10] : 5}>{children}</Box>
      </Flex>

      {hasImage && (
        <Flex
          width={[0, '50%']}
          height={[0, '100%']}
          position="absolute"
          right={0}
          css={css({
            img: {
              borderTopRightRadius: '16px',
              borderBottomRightRadius: '16px',
            },
          })}
        >
          <Image
            width="100%"
            height="100%"
            src={image?.filename}
            alt={image?.alt || 'header'}
          />
        </Flex>
      )}
    </Flex>
  );
};
