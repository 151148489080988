import React, { FC } from 'react';
import { Flex, SystemProps } from '@storyofams/react-ui';
import styled from 'styled-components';
import { Text } from '~components/common/Text';

const StyledDivider = styled(Flex)`
  width: 100%;
  align-items: center;

  &:before,
  &:after {
    content: '';
    height: 1px;
    flex: 1;
    background-color: ${(p) => p.theme.colors[p.color]};
  }
`;

export const Divider: FC<SystemProps> = ({
  children,
  color = 'grey300',
  ...props
}) => {
  return (
    <StyledDivider as="span" color={color} {...props}>
      {children && (
        <Text mx={2} color="inherit">
          {children}
        </Text>
      )}
    </StyledDivider>
  );
};
