import React, {
  forwardRef,
  DetailedHTMLProps,
  InputHTMLAttributes,
} from 'react';
import { Flex } from '@storyofams/react-ui';
import { useId } from 'react-id-generator';
import styled from 'styled-components';

import { Text } from '~components/common/Text';

const StyledRadio = styled.input`
  appearance: none;
  outline: none;
  border: none;

  width: 16px;
  height: 16px;

  margin-right: ${({ theme }) => theme.space[1]}px;

  border-radius: 50%;
  box-shadow: 0 0 0 2px ${(p) => p.theme.colors.cocktails};
  background-color: ${({ theme }) => theme.colors.white};

  transition: border-shadow 0.18s ease-in-out,
    background-color 0.18s ease-in-out, 0.18s box-shadow ease-in-out;

  &:before {
    transition: all 0.18s ease-in-out;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: white;
    border-radius: 8px;
    z-index: -1;
  }

  + label {
    display: flex;
    align-items: center;
    position: absolute;
    padding: 16px;
    padding-left: 44px;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    color: ${(p) => p.theme.colors.cocktails};
  }

  &:checked {
    border: 4px solid ${({ theme }) => theme.colors.cocktails};
    box-shadow: 0 0 0 2px ${(p) => p.theme.colors.white};

    &:before {
      background-color: black;
    }

    + label,
    + label + span {
      color: white;
    }
  }

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    outline: none;
    box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.grey900};

    &:checked {
      box-shadow: 0 0 0 2px ${(p) => p.theme.colors.white};
    }
  }

  &:disabled {
    cursor: not-allowed;

    + label {
      cursor: not-allowed;
    }

    &:before {
      cursor: not-allowed;
    }
  }
`;

interface Props
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
  note?: string;
  disabled?: boolean;
}

export const Radio = forwardRef<HTMLInputElement, Props>(
  ({ label, note, ...props }, ref) => {
    const autoId = useId();
    const id = `radio-button=${autoId}`;

    return (
      <Flex
        position="relative"
        alignItems="center"
        p={2}
        height="52px"
        opacity={props.disabled ? 0.6 : 1}
        zIndex={1}
      >
        {/* @ts-ignore */}
        <StyledRadio id={id} type="radio" ref={ref} {...props} />

        <Text
          as="label"
          variant="label"
          htmlFor={id}
          key={id}
          fontWeight="medium"
        >
          {label ?? props.value}
        </Text>

        {note && (
          <Text
            as="span"
            ml="auto"
            fontWeight="normal"
            fontStyle="italic"
            color="cocktails"
          >
            {note}
          </Text>
        )}
      </Flex>
    );
  },
);
