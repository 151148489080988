declare interface GtagWindow extends Window {
  gtag: any;
}
declare const window: GtagWindow;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  if (window?.gtag) {
    window.gtag('config', process.env.NEXT_PUBLIC_GTM, {
      page_path: url,
    });
  }
};

export const track = (data: any) => {
  if (window?.gtag) {
    window.gtag(data);
  }
};
