type TranslationLocale = { lang: string; needsTranslation: boolean };

const LANG_US = 'us';

export const getTranslationForLocale = (
  locale: string,
  defaultLocale: string,
): TranslationLocale =>
  process.env.NEXT_PUBLIC_STORE_COUNTRY === LANG_US
    ? { lang: LANG_US, needsTranslation: true }
    : { lang: locale, needsTranslation: defaultLocale !== locale };
