import { Box, Stack } from '@storyofams/react-ui';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Container } from '~components';
import { useShopify } from '~context';
import { Button } from '~components/common/Button';
import { Heading } from '~components/common/Heading';
import { Text } from '~components/common/Text';

const messages = defineMessages({
  b2bTitle: ' Welcome {displayName}!',
  b2bDescription:
    'Browse around the store, re-order your favorites from previous orders or view products selected especially for you!',
  previousOrders: 'View previous orders',
  groupOverview: '{group} overview',
});

export const B2BHeader = () => {
  const { customer } = useShopify();

  return (
    <Box width="100%" overflow="hidden">
      <Container
        as="section"
        textAlign="center"
        pt={[8, 10]}
        pb={[4, 2]}
        px={[2, 6]}
      >
        <Heading variant="h1" as="h2" mb={2} flex={1}>
          <FormattedMessage
            {...messages.b2bTitle}
            values={{ displayName: customer?.displayName }}
          />
        </Heading>
        <Text mb={4}>
          <FormattedMessage {...messages.b2bDescription} />
        </Text>

        <Stack justifyContent="center" space={3}>
          <Button to="/account/orders" variant="primary" width="max-content">
            <FormattedMessage {...messages.previousOrders} />
          </Button>

          {customer?.tags
            .filter((tag) => tag.includes('group_'))
            .map((tag) => (
              <Button
                to={`/group/${tag.replace('group_', '')}`}
                variant="outline"
                width="max-content"
              >
                <FormattedMessage
                  {...messages.groupOverview}
                  values={{ group: tag.replace('group_', '') }}
                />
              </Button>
            ))}
        </Stack>
      </Container>
    </Box>
  );
};

export default B2BHeader;
