import React from 'react';
import { Box } from '@storyofams/react-ui';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';

const Mask = styled(motion.div)`
  width: 250vw;
  height: 250vw;
  border: 130vw solid black;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1101;
  filter: blur(24px);
`;

export const CampaignLoader = ({ hasConsent }) => {
  if (hasConsent) {
    return (
      <Box
        width="100%"
        height="100%"
        overflow="hidden"
        position="absolute"
        top="0"
        left="0"
      >
        <AnimatePresence>
          <Mask
            key="mask"
            initial={{ borderWidth: '130vw' }}
            animate={{
              borderWidth: '0vw',
              transitionEnd: { display: 'none' },
            }}
            transition={{ duration: 3.5 }}
          />
        </AnimatePresence>
      </Box>
    );
  }

  return null;
};
