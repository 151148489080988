import React from 'react';
import { Box } from '@storyofams/react-ui';
import { Image } from '@storyofams/storyblok-toolkit';
import styled from 'styled-components';

import { Button } from '~components/common/Button';
import { Heading } from '~components/common/Heading';
import { Text } from '~components/common/Text';

export type LatestCollectionSlideProps = {
  title: string;
  subtitle: string;
  image: {
    filename: string;
    alt: string;
  };
  height: any;
};

export const LatestCollectionSlide = ({
  title,
  image,
  subtitle,
  ...props
}: LatestCollectionSlideProps) => (
  <Container {...props} variant="unstyled" width="100%">
    <Box width="100%">
      {!!image && (
        <Image src={image?.filename} alt={image?.alt} fluid={[290, 275]} />
      )}
      <div>
        <Heading variant="h5" mt={2} fontWeight="medium" color="grey900">
          {title}
        </Heading>
        <Subtitle variant="s" color="grey300">
          {subtitle}
        </Subtitle>
      </div>
    </Box>
  </Container>
);

const Container = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 8px;

  & .storyblok-image-wrapper {
    height: 275px;
    width: 100%;

    img {
      border-radius: 8px;
    }
  }
`;

const Subtitle = styled(Text)`
  font-weight: 300;
  font-size: 14px;
  line-height: 100%;
  margin-top: 4px;
`;
