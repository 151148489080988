import axios from 'axios';
import { getError } from './getError';

export const formsSubmitOne = async (pageId, fields) => {
  try {
    const res = await axios.post(
      `${process.env.NEXT_PUBLIC_NACHO_API_URL}/forms/${pageId}`,
      fields,
    );

    const { form, paymentUrl, status } = res.data;

    if (paymentUrl) {
      window.location.href = paymentUrl;
    }

    return { form, paymentUrl, status };
  } catch (error) {
    return { error: getError(error) || error.message };
  }
};

export const formsCheckCoupon = async (code) => {
  try {
    const res = await axios.get(
      `${process.env.NEXT_PUBLIC_NACHO_API_URL}/webshop/coupons/${code}`,
    );

    const { coupon } = res.data;

    return coupon;
  } catch (error) {
    return { error: getError(error) };
  }
};

export const formsQollabCheckout = async (pageId, fields) => {
  try {
    const res = await axios.post(
      `${process.env.NEXT_PUBLIC_NACHO_API_URL}/qollab/checkout`,
      {
        pageId,
        ...fields,
      },
    );

    const { redirectUrl } = res.data;

    if (redirectUrl) {
      window.location.href = redirectUrl;
    }

    return redirectUrl;
  } catch (err) {
    return { error: getError(err) };
  }
};
