import { useMemo } from 'react';

import { Icon, Flex, Box, SystemProps, css } from '@storyofams/react-ui';
import { sumBy } from 'lodash';
import { useRouter } from 'next/router';

import { defineMessages, FormattedMessage } from 'react-intl';
import { useShopify } from '~context';
import { Cart } from '~components/cart';
import { Button } from '~components/common/Button';
import { Drawer } from '~components/common/Drawer';
import { Heading } from '~components/common/Heading';
import { Cross, Search, Logo } from '~components/common/Icons';
import { Text } from '~components/common/Text';
import { shopifyToIntlPrice } from '~lib/shopify/utils';

import { CartButton } from './CartButton';
import { Hamburger } from './Hamburger';
import { NavigationButton } from './NavigationButton';
import { NavigationLink } from './NavigationLink';
import { ProfileButton } from './ProfileButton';

interface NavigationPrimaryProps extends SystemProps {
  badge?: number;
  hasSearchButton?: boolean;
  mobileMenuOpen: boolean;
  setMobileMenuOpen(boolean): void;
  links: {
    label: string;
    link: {
      url: string;
    };
  }[];
  mobileLinks?: {
    label: string;
    link: {
      url: string;
    };
  }[];
  cartHint?: string;
  scrolledStyles?: any;
}

const messages = defineMessages({
  menu: 'Menu',
  logout: 'Logout',
});

export const NavigationPrimary = ({
  badge,
  hasSearchButton,
  mobileMenuOpen,
  setMobileMenuOpen,
  links,
  mobileLinks,
  cartHint,
  scrolledStyles,
  ...props
}: NavigationPrimaryProps) => {
  const { pathname } = useRouter();
  const {
    checkout,
    customer,
    logout,
    showCartOverlay,
    toggleCartOverlay,
    country,
  } = useShopify();

  const quantity = useMemo(
    () =>
      sumBy(checkout?.lineItems?.edges, function (item) {
        return item?.node?.quantity;
      }),
    [checkout],
  );

  const getLink = (link) =>
    link?.linktype === 'story' ? `/${link.story?.url}` : link.url;

  const getUrl = (rawLink) => {
    let link = getLink(rawLink);
    if (link.endsWith('/')) {
      link = link.slice(0, link.length - 1);
    }

    if (link.startsWith('/us/')) {
      link = link.replace('/us', '');
    }

    if (
      link === '/signature-cocktail-recipes' &&
      country &&
      !['NL', 'US'].includes(country)
    ) {
      return 'https://bols.com/cocktails/recipes';
    }

    if (
      process.env.NEXT_PUBLIC_STORE_COUNTRY === 'b2b' &&
      !link.includes('shop') &&
      !link.includes('account')
    ) {
      return `${
        !!process.env.NEXT_PUBLIC_NL_URL
          ? process.env.NEXT_PUBLIC_NL_URL
          : 'https://bols.com'
      }${link}`;
    }

    return link;
  };

  return (
    <Flex
      mx="auto"
      maxWidth="1360px"
      alignItems="center"
      pr={[1, 2, 5]}
      pl={[2, 2, 5]}
      {...props}
    >
      <Box
        position="absolute"
        left="0"
        right="0"
        top="0"
        bottom="0"
        css={css(scrolledStyles)}
      />
      <Hamburger onClick={() => setMobileMenuOpen(true)} />

      <Box flex="1">
        <Button
          to={process.env.NEXT_PUBLIC_STORE_COUNTRY === 'b2b' ? '/shop' : '/'}
          variant="unstyled"
        >
          <Icon
            icon={Logo}
            pt={'6px'}
            fontSize={{ all: 6, md: 8 }}
            justifyContent="flex-start!important"
          />
        </Button>
      </Box>

      <Box
        position={['fixed', 'fixed', 'static']}
        display={['flex !important', 'flex !important', 'none !important']}
        top={[0, 0, 'unset']}
        left={[
          mobileMenuOpen ? 0 : '-100%',
          mobileMenuOpen ? 0 : '-100%',
          'unset',
        ]}
        bottom={[0, 0, 'unset']}
        zIndex="overlay"
        width="100%"
        backgroundColor="rgba(45,45,45,.65)"
        transition="opacity .24s ease-out"
        opacity={[mobileMenuOpen ? 1 : 0, mobileMenuOpen ? 1 : 0, 0]}
        onClick={() => setMobileMenuOpen(false)}
      />

      <Flex
        as="nav"
        position={['fixed', 'fixed', 'static']}
        top={[0, 0, 'unset']}
        left={[
          mobileMenuOpen ? 0 : '-100%',
          mobileMenuOpen ? 0 : '-100%',
          'unset',
        ]}
        bottom={[0, 0, 'unset']}
        transition="left .24s ease-out"
        maxWidth={['320px', '320px', 'none']}
        height="100%"
        width={['100%', '100%', 'auto']}
        alignContent="center"
        flexDirection={['column', 'column', 'row']}
        zIndex={['modal', 'modal', 'base']}
        bg={['grey900', 'grey900', 'transparent']}
      >
        <Box
          width="100%"
          height="64px"
          display={['flex', 'flex', 'none']}
          alignItems="center"
          justifyContent="center"
          px={2}
          as="button"
          onClick={() => setMobileMenuOpen(false)}
          position="relative"
        >
          <Icon
            color="white"
            fontSize={3}
            icon={Cross}
            position="absolute"
            left={2}
            top="50%"
            transform="translateY(-50%)"
          />
          <Text
            color="grey400"
            letterSpacing="0.08em"
            css={css({ textTransform: 'uppercase' })}
          >
            <FormattedMessage {...messages.menu} />
          </Text>
        </Box>

        <Flex
          as="ul"
          flexDirection={['column', 'column', 'row']}
          justifyContent={['flex-start', 'flex-start', 'center']}
          alignItems={['flex-start', 'flex-start', 'center']}
          flex={1}
          px={2}
          pb={[1.5, 1.5, 0]}
          position="relative"
          zIndex={1}
          className="list"
        >
          {links?.map((item, index) => (
            <Flex
              as="li"
              key={item.label}
              ml={index === 0 ? 0 : [0, 0, 8]}
              py={{ all: 2, md: 0 }}
            >
              <NavigationLink
                link={getUrl(item?.link)}
                active={pathname === getUrl(item?.link)}
              >
                {item.label}
              </NavigationLink>
            </Flex>
          ))}

          {mobileLinks?.map((item, index) => (
            <Flex
              as="li"
              key={item.label}
              ml={index === 0 ? 0 : [0, 0, 8]}
              py={{ all: 1.5, md: 0 }}
              mt={index === 0 && 'auto'}
              display={['flex', 'flex', 'none !important']}
            >
              <NavigationLink
                link={getUrl(item?.link)}
                active={pathname === getUrl(item?.link)}
                fontFamily="body"
                fontSize={2.5}
                letterSpacing="0"
                fontWeight="regular"
                color="grey300"
              >
                {item.label}
              </NavigationLink>
            </Flex>
          ))}
        </Flex>
      </Flex>

      <Flex
        flex={1}
        position="relative"
        alignItems="center"
        justifyContent="flex-end"
        ml={['auto', 'auto', 0]}
      >
        <Flex mr={[0.5, 0.5, 1]}>
          <NavigationButton
            to="/search"
            className={pathname === '/search' ? 'active' : undefined}
          >
            <Icon icon={Search} fontSize={3} />
          </NavigationButton>
        </Flex>

        <ProfileButton isLoggedIn={!!customer}>
          <NavigationButton onClick={logout}>
            <FormattedMessage {...messages.logout} />
          </NavigationButton>
        </ProfileButton>

        <Flex>
          <CartButton
            cartOpen={showCartOverlay}
            quantity={quantity}
            setCartOpen={() => toggleCartOverlay(true)}
          />
        </Flex>
      </Flex>

      <Drawer
        isOpen={showCartOverlay}
        close={toggleCartOverlay}
        title={
          <Flex justifyContent="space-between" flex={1}>
            <Heading variant="h4" as="h4">
              <FormattedMessage
                defaultMessage="Your Bag {quantity}"
                values={{ quantity: quantity ? ` (${quantity})` : '' }}
              />
            </Heading>

            {!!quantity && (
              <Text color="grey500" fontWeight="normal">
                <FormattedMessage
                  defaultMessage="Total - {total}"
                  values={{ total: shopifyToIntlPrice(checkout?.totalPriceV2) }}
                />
              </Text>
            )}
          </Flex>
        }
      >
        <Cart hint={cartHint} />
      </Drawer>
    </Flex>
  );
};
