import { Icon, SystemProps } from '@storyofams/react-ui';
import { Button, ButtonProps } from '../Button';
import { ArrowLeft } from '../Icons';

export const BackButton = ({
  children,
  ...props
}: ButtonProps & SystemProps) => {
  return (
    <Button
      variant="unstyled"
      display="flex"
      alignItems="center"
      justifyContent={{ all: 'center', md: 'flex-start' }}
      m="0 auto"
      lineHeight="normal"
      {...props}
      css={{
        svg: {
          transition: '0.2s transform ease-out',
        },
        '&:hover, &:focus': {
          svg: {
            transform: 'translateX(-4px)',
          },
        },
      }}
    >
      <Icon icon={ArrowLeft} mr={1.25} />
      {children}
    </Button>
  );
};
