import { Box, SystemProps, system } from '@storyofams/react-ui';
import styled from 'styled-components';
import { variant } from 'styled-system';

export const textVariants = {
  l: {
    fontSize: [2, 3],
    fontWeight: 'medium',
    lineHeight: 'high',
  },
  m: {
    fontSize: 2,
    lineHeight: 'high',
  },
  s: {
    fontSize: 1.75,
  },
  xs: {
    fontSize: 1.5,
  },
  tagline: {
    fontSize: 1.75,
    fontWeight: 'medium',
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
  },
  label: {
    display: 'flex',

    fontSize: 1.75,
    lineHeight: '140%',
    color: 'grey500',

    cursor: 'pointer',

    '&[disabled=""]': {
      cursor: 'not-allowed',
      opacity: 0.6,
    },
  },
};

type CustomProps = {
  /** defaults to `p` */
  as?: 'p' | 'span' | 'blockquote' | 'strong' | 'em' | 'pre' | 'label' | 'a';
  variant?: keyof typeof textVariants;
} & SystemProps;

export const Text = styled(Box).attrs((props: CustomProps) => ({
  as: props?.as ?? 'p',
  variant: props?.variant || 'm',
}))<CustomProps>`
  font-family: ${(p) => p.theme.fonts.body};
  color: ${(p) => p.theme.colors.grey900};
  line-height: 140%;

  ${variant({ variants: textVariants })}
  ${system}
`;
