import { Icon, SystemProps } from '@storyofams/react-ui';

import { Cart as CartIcon } from '~components/common/Icons';

import { Badge } from './Badge';
import { NavigationButton } from './NavigationButton';

interface Props extends SystemProps {
  quantity?: number;
  cartOpen: boolean;
  setCartOpen(boolean): void;
}

export const CartButton = ({ quantity, cartOpen, setCartOpen }: Props) => {
  const buttonContent = (
    <>
      <Icon icon={CartIcon} fontSize={3} />

      {!!quantity && (
        <Badge position="absolute" top="2px" right="3px">
          {quantity}
        </Badge>
      )}
    </>
  );

  return (
    <NavigationButton
      variant="unstyled"
      onClick={setCartOpen}
      display="inline-flex"
      color={cartOpen ? 'grey900' : 'cocktails'}
    >
      {buttonContent}
    </NavigationButton>
  );
};
