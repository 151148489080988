import React from 'react';
import ReactSelect, { Props } from 'react-select';
import styled, { css, useTheme } from 'styled-components';

// import { TetherComponent } from './TetherComponent';

export const StyledSelect = styled(ReactSelect).attrs({
  className: 'react-select',
  classNamePrefix: 'react-select',
})(
  ({ styledTheme: theme, disableShadow }) => css`
    font-size: ${theme.fontSizes[2]}px;

    .react-select {
      &__placeholder {
        color: ${theme.colors.grey500};
        line-height: 150%;
        position: relative;
        transform: none;
      }

      &__control {
        appearance: none;
        cursor: pointer;
        transition: border-color 0.18s ease;
        border-color: ${theme.colors.grey300};
        border-radius: 8px;
        background-color: #fff;
        height: 50px;

        box-shadow: ${disableShadow
          ? 'none'
          : 'inset 0px 1px 2px rgb(0 0 0 / 16%)'};

        transition: border-color 0.18s ease-in-out,
          background-color 0.18s ease-in-out;

        &:hover,
        &:active,
        &:focus {
          border-color: ${theme.colors.grey900};
        }

        .react-select__dropdown-indicator {
          color: ${theme.colors.grey500};
        }

        &--menu-is-open {
          .react-select__dropdown-indicator {
            svg {
              transform: rotate(180deg);
            }
          }
        }

        &--is-disabled {
          cursor: not-allowed;
          opacity: 0.6;
          background: none;

          &:hover {
            border-color: ${theme.colors.grey300};
          }
        }

        &--is-focused {
          border-color: ${theme.colors.primary800};
          box-shadow: none;

          .react-select__placeholder {
            color: ${theme.colors.grey300};
          }
        }
      }

      &__dropdown-indicator {
        color: ${theme.colors.primary800};
      }

      &__single-value {
        color: ${theme.colors.grey900};
        max-width: 100%;
        position: relative;
        transform: none;
      }

      &__value-container {
        padding: 6px 8px 4px 14px !important;
      }

      &__indicator-separator {
        display: none;
      }

      &__menu {
        width: 100%;
        min-width: 80px;
        border-radius: 4px;
      }

      &__menu-list {
        padding: ${theme.space[1]}px;
      }

      &__option {
        padding: ${theme.space[1]}px ${theme.space[2]}px;
        margin-bottom: ${theme.space[0.5]}px;
        transition: border-color 0.18s ease-in-out,
          background-color 0.18s ease-in-out, color 0.18s ease-in-out;
        color: ${theme.colors.grey900};
        border-radius: 4px;
        font-size: ${theme.fontSizes[2]}px;
        line-height: 1.5;
        white-space: nowrap;
        cursor: pointer;

        &:hover {
          background-color: ${theme.colors.grey200};
          color: ${theme.colors.grey900};
        }

        & {
          &--is-focused {
            background-color: ${theme.colors.grey400};
            color: ${theme.colors.white};
          }

          &--is-selected,
          &--is-active {
            color: ${theme.colors.white};
            background-color: ${theme.colors.grey500};

            &:hover {
              color: ${theme.colors.white};
              background-color: ${theme.colors.grey500};
            }
          }

          &--is-disabled {
            background: ${theme.colors.grey300};
            cursor: not-allowed;

            &:hover {
              background: ${theme.colors.grey300};
            }
          }
        }
      }
    }
  `,
);

interface SelectProps extends Props {
  theme?: any;
  disableShadow?: boolean;
}

const Select = (props: SelectProps) => {
  const theme = useTheme(); // react-select and styled-components both need a theme so it needs to be renamed

  return (
    <StyledSelect
      styledTheme={theme}
      theme={(t) => ({
        ...t,
        colors: {
          ...t.colors,
          primary25: theme.colors.cocktails,
          primary50: theme.colors.cocktails,
          primary: theme.colors.cocktails,
        },
      })}
      {...props}
    />
  );
};

export default Select;
