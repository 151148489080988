import React from 'react';
import { Box } from '@storyofams/react-ui';
import { Image } from '@storyofams/storyblok-toolkit';
import styled from 'styled-components';

import { Button } from '~components/common/Button';
import { Heading } from '~components/common/Heading';

export type CampaignSlideProps = {
  title: string;
  image: {
    filename: string;
    alt: string;
  };
};

export const CampaignSlide = ({
  title,
  image,
  ...props
}: CampaignSlideProps) => (
  <Container {...props} variant="unstyled" width="100%">
    <Box width="100%" position="relative">
      <Image src={image?.filename} alt={image?.alt} width="100%" />
      <Text
        variant="h5"
        color="white"
        textShadow="0px 2px 10px rgba(0, 0, 0, 0.32)"
        fontSize={2}
        fontFamily="Sohne Breit"
        letterSpacing="0.04em"
      >
        {title}
      </Text>
    </Box>
  </Container>
);

const Container = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  & .storyblok-image-wrapper {
    height: 95vh;
    width: 100%;

    img {
      animation: zoom 7s ease-in-out infinite alternate;
    }

    @media (max-width: ${(p) => p.theme.breakpoints.md}) {
      height: 90vh;
    }

    @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
      height: 85vh;
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 0;
  }

  @keyframes slider {
    0% {
      left: 0;
    }
    33% {
      left: -100vw;
    }
    66% {
      left: -200vw;
    }
    100% {
      left: 0;
    }
  }

  @keyframes zoom {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.05);
    }
  }
`;

const Text = styled(Heading)`
  position: absolute;
  bottom: 160px;
  left: 0;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
  z-index: 1;

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    bottom: 144px;
  }

  @media (max-width: 767px) {
    bottom: 128px;
  }
`;
