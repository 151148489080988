import { ReactNode } from 'react';
import { Box, Flex, SystemProps } from '@storyofams/react-ui';

import { Image, Heading, Text } from '~components';
import { CheckoutFragmentFragment } from '~lib/shopify/sdk';
import { shopifyToIntlPrice } from '~lib/shopify/utils';

export const LineItem = ({
  title,
  variant,
  children,
  ...props
}: Partial<CheckoutFragmentFragment['lineItems']['edges'][number]['node']> & {
  children: ReactNode;
} & SystemProps) => {
  return (
    <Flex mb={3} alignItems="center" {...props}>
      <Image
        src={variant?.image?.url}
        alt={variant?.image?.altText || variant?.product?.title || title}
        width={{ all: 96, md: 104 }}
        height={{ all: 96, md: 104 }}
        mr={3}
        overflow="hidden"
        objectFit="contain"
      />

      <Box flex={1} fontSize={2.25}>
        <Flex flex={1} justifyContent="space-between" mb={2.5}>
          <Box>
            <Heading
              variant="h5"
              as="h5"
              mb={0.75}
              fontSize={2.5}
              lineHeight="100%"
            >
              {variant?.product?.title || title}
            </Heading>
            {!!variant?.selectedOptions?.length &&
              (variant?.selectedOptions?.[0]?.name !== 'Title' ||
                variant?.selectedOptions?.length > 1) && (
                <Text color="grey500" fontSize={1.75} fontWeight={300}>
                  {variant?.selectedOptions
                    ?.map((opt) => opt.value)
                    .join(' - ')}
                </Text>
              )}
          </Box>

          <Text lineHeight="20px">{shopifyToIntlPrice(variant?.priceV2)}</Text>
        </Flex>

        {children}
      </Box>
    </Flex>
  );
};
