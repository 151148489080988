import { PropsWithChildren } from 'react';
import NextLink, { LinkProps } from 'next/link';

export const Link = ({ href, ...props }: PropsWithChildren<LinkProps>) => {
  const url = href.toString().startsWith('/us/')
    ? href.toString().replace('/us', '')
    : href;

  return <NextLink href={url} {...props} />;
};
