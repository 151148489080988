import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BlockScalar: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  JsonScalar: any;
};

export type AgeconsentComponent = {
  __typename?: 'AgeconsentComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  button_text?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  consent_text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AgeconsentFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
  button_text?: Maybe<FilterQueryOperations>;
};

export type AgeconsentItem = {
  __typename?: 'AgeconsentItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<AgeconsentComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type AgeconsentItems = {
  __typename?: 'AgeconsentItems';
  items?: Maybe<Array<Maybe<AgeconsentItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Alternate = {
  __typename?: 'Alternate';
  fullSlug: Scalars['String'];
  id: Scalars['Int'];
  isFolder?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  slug: Scalars['String'];
};

export type Asset = {
  __typename?: 'Asset';
  alt?: Maybe<Scalars['String']>;
  copyright?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  focus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AuthpageComponent = {
  __typename?: 'AuthpageComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  seo?: Maybe<Scalars['JsonScalar']>;
  title?: Maybe<Scalars['String']>;
};

export type AuthpageFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
  description?: Maybe<FilterQueryOperations>;
};

export type AuthpageItem = {
  __typename?: 'AuthpageItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<AuthpageComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type AuthpageItems = {
  __typename?: 'AuthpageItems';
  items?: Maybe<Array<Maybe<AuthpageItem>>>;
  total?: Maybe<Scalars['Int']>;
};


export type BlogpageComponent = {
  __typename?: 'BlogpageComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  callToAction?: Maybe<Story>;
  component?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instagramSection?: Maybe<Story>;
  seo?: Maybe<Scalars['JsonScalar']>;
  title?: Maybe<Scalars['String']>;
};


export type BlogpageComponentCallToActionArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
};


export type BlogpageComponentInstagramSectionArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
};

export type BlogpageFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
  description?: Maybe<FilterQueryOperations>;
  instagramSection?: Maybe<FilterQueryOperations>;
  callToAction?: Maybe<FilterQueryOperations>;
};

export type BlogpageItem = {
  __typename?: 'BlogpageItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<BlogpageComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type BlogpageItems = {
  __typename?: 'BlogpageItems';
  items?: Maybe<Array<Maybe<BlogpageItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BlogpostcategoryComponent = {
  __typename?: 'BlogpostcategoryComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type BlogpostcategoryFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
};

export type BlogpostcategoryItem = {
  __typename?: 'BlogpostcategoryItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<BlogpostcategoryComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type BlogpostcategoryItems = {
  __typename?: 'BlogpostcategoryItems';
  items?: Maybe<Array<Maybe<BlogpostcategoryItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CalltoactionComponent = {
  __typename?: 'CalltoactionComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  blocks?: Maybe<Scalars['BlockScalar']>;
  component?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type CalltoactionFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
};

export type CalltoactionItem = {
  __typename?: 'CalltoactionItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<CalltoactionComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type CalltoactionItems = {
  __typename?: 'CalltoactionItems';
  items?: Maybe<Array<Maybe<CalltoactionItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CheckoutComponent = {
  __typename?: 'CheckoutComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  back_button_label?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  questions?: Maybe<Scalars['BlockScalar']>;
};

export type CheckoutFilterQuery = {
  back_button_label?: Maybe<FilterQueryOperations>;
  content?: Maybe<FilterQueryOperations>;
};

export type CheckoutItem = {
  __typename?: 'CheckoutItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<CheckoutComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type CheckoutItems = {
  __typename?: 'CheckoutItems';
  items?: Maybe<Array<Maybe<CheckoutItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CocktailComponent = {
  __typename?: 'CocktailComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['JsonScalar']>;
  callToAction?: Maybe<Story>;
  component?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured_products_description?: Maybe<Scalars['String']>;
  featured_products_title?: Maybe<Scalars['String']>;
  flavour?: Maybe<Scalars['String']>;
  glass?: Maybe<Scalars['String']>;
  glass_icon?: Maybe<Scalars['String']>;
  image?: Maybe<Asset>;
  instagramSection?: Maybe<Story>;
  products?: Maybe<Scalars['JsonScalar']>;
  recipe?: Maybe<Scalars['BlockScalar']>;
  related?: Maybe<Array<Maybe<Story>>>;
  related_cocktails_title?: Maybe<Scalars['String']>;
  rowUrl?: Maybe<Link>;
  seo?: Maybe<Scalars['JsonScalar']>;
  title?: Maybe<Scalars['String']>;
  visual?: Maybe<Scalars['BlockScalar']>;
};


export type CocktailComponentCallToActionArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
};


export type CocktailComponentInstagramSectionArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
};


export type CocktailComponentRelatedArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
};

export type CocktailFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
  description?: Maybe<FilterQueryOperations>;
  glass?: Maybe<FilterQueryOperations>;
  glass_icon?: Maybe<FilterQueryOperations>;
  flavour?: Maybe<FilterQueryOperations>;
  featured_products_title?: Maybe<FilterQueryOperations>;
  featured_products_description?: Maybe<FilterQueryOperations>;
  related_cocktails_title?: Maybe<FilterQueryOperations>;
  related?: Maybe<FilterQueryOperations>;
  callToAction?: Maybe<FilterQueryOperations>;
  instagramSection?: Maybe<FilterQueryOperations>;
};

export type CocktailItem = {
  __typename?: 'CocktailItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<CocktailComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type CocktailItems = {
  __typename?: 'CocktailItems';
  items?: Maybe<Array<Maybe<CocktailItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CocktailoverviewComponent = {
  __typename?: 'CocktailoverviewComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  seo?: Maybe<Scalars['JsonScalar']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type CocktailoverviewFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
  subtitle?: Maybe<FilterQueryOperations>;
};

export type CocktailoverviewItem = {
  __typename?: 'CocktailoverviewItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<CocktailoverviewComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type CocktailoverviewItems = {
  __typename?: 'CocktailoverviewItems';
  items?: Maybe<Array<Maybe<CocktailoverviewItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CollectionComponent = {
  __typename?: 'CollectionComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  collection?: Maybe<Scalars['JsonScalar']>;
  component?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['BlockScalar']>;
  hidesFilters?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Asset>;
  isHidden?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['String']>;
  seo?: Maybe<Scalars['JsonScalar']>;
  title?: Maybe<Scalars['String']>;
};

export type CollectionFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
  isHidden?: Maybe<FilterQueryOperations>;
  hidesFilters?: Maybe<FilterQueryOperations>;
  order?: Maybe<FilterQueryOperations>;
};

export type CollectionItem = {
  __typename?: 'CollectionItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<CollectionComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type CollectionItems = {
  __typename?: 'CollectionItems';
  items?: Maybe<Array<Maybe<CollectionItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ContactformComponent = {
  __typename?: 'ContactformComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['JsonScalar']>;
  termsAndConditions?: Maybe<Story>;
  title?: Maybe<Scalars['String']>;
};


export type ContactformComponentTermsAndConditionsArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
};

export type ContactformFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
  termsAndConditions?: Maybe<FilterQueryOperations>;
};

export type ContactformItem = {
  __typename?: 'ContactformItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<ContactformComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type ContactformItems = {
  __typename?: 'ContactformItems';
  items?: Maybe<Array<Maybe<ContactformItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ContentItem = {
  __typename?: 'ContentItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<Scalars['JsonScalar']>;
  content_string?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type ContentItems = {
  __typename?: 'ContentItems';
  items?: Maybe<Array<Maybe<ContentItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CookieconsentComponent = {
  __typename?: 'CookieconsentComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  confirmButton?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['JsonScalar']>;
  declineButton?: Maybe<Scalars['String']>;
};

export type CookieconsentFilterQuery = {
  confirmButton?: Maybe<FilterQueryOperations>;
  declineButton?: Maybe<FilterQueryOperations>;
};

export type CookieconsentItem = {
  __typename?: 'CookieconsentItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<CookieconsentComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type CookieconsentItems = {
  __typename?: 'CookieconsentItems';
  items?: Maybe<Array<Maybe<CookieconsentItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CourseformComponent = {
  __typename?: 'CourseformComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  newsletterLabel?: Maybe<Scalars['String']>;
  requirements?: Maybe<Scalars['String']>;
  termsAndConditions?: Maybe<Story>;
  title?: Maybe<Scalars['String']>;
};


export type CourseformComponentTermsAndConditionsArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
};

export type CourseformFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
  description?: Maybe<FilterQueryOperations>;
  courseId?: Maybe<FilterQueryOperations>;
  newsletterLabel?: Maybe<FilterQueryOperations>;
  termsAndConditions?: Maybe<FilterQueryOperations>;
  requirements?: Maybe<FilterQueryOperations>;
};

export type CourseformItem = {
  __typename?: 'CourseformItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<CourseformComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type CourseformItems = {
  __typename?: 'CourseformItems';
  items?: Maybe<Array<Maybe<CourseformItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Datasource = {
  __typename?: 'Datasource';
  id: Scalars['Int'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type DatasourceEntries = {
  __typename?: 'DatasourceEntries';
  items: Array<DatasourceEntry>;
  total: Scalars['Int'];
};

export type DatasourceEntry = {
  __typename?: 'DatasourceEntry';
  dimensionValue?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type Datasources = {
  __typename?: 'Datasources';
  items: Array<Datasource>;
};

export type DynamicblogpageComponent = {
  __typename?: 'DynamicblogpageComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  callToAction?: Maybe<Story>;
  category?: Maybe<Story>;
  component?: Maybe<Scalars['String']>;
  instagramSection?: Maybe<Story>;
  noIndex?: Maybe<Scalars['Boolean']>;
  previewImage?: Maybe<Asset>;
  sections?: Maybe<Scalars['BlockScalar']>;
  seo?: Maybe<Scalars['JsonScalar']>;
  shortDescription?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};


export type DynamicblogpageComponentCallToActionArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
};


export type DynamicblogpageComponentCategoryArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
};


export type DynamicblogpageComponentInstagramSectionArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
};

export type DynamicblogpageFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
  shortDescription?: Maybe<FilterQueryOperations>;
  category?: Maybe<FilterQueryOperations>;
  callToAction?: Maybe<FilterQueryOperations>;
  instagramSection?: Maybe<FilterQueryOperations>;
  noIndex?: Maybe<FilterQueryOperations>;
};

export type DynamicblogpageItem = {
  __typename?: 'DynamicblogpageItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<DynamicblogpageComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type DynamicblogpageItems = {
  __typename?: 'DynamicblogpageItems';
  items?: Maybe<Array<Maybe<DynamicblogpageItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type DynamicpageComponent = {
  __typename?: 'DynamicpageComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  callToAction?: Maybe<Story>;
  component?: Maybe<Scalars['String']>;
  instagramSection?: Maybe<Story>;
  noIndex?: Maybe<Scalars['Boolean']>;
  sections?: Maybe<Scalars['BlockScalar']>;
  seo?: Maybe<Scalars['JsonScalar']>;
};


export type DynamicpageComponentCallToActionArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
};


export type DynamicpageComponentInstagramSectionArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
};

export type DynamicpageFilterQuery = {
  noIndex?: Maybe<FilterQueryOperations>;
  callToAction?: Maybe<FilterQueryOperations>;
  instagramSection?: Maybe<FilterQueryOperations>;
};

export type DynamicpageItem = {
  __typename?: 'DynamicpageItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<DynamicpageComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type DynamicpageItems = {
  __typename?: 'DynamicpageItems';
  items?: Maybe<Array<Maybe<DynamicpageItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ElearningformComponent = {
  __typename?: 'ElearningformComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ElearningformFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
  description?: Maybe<FilterQueryOperations>;
  pageId?: Maybe<FilterQueryOperations>;
};

export type ElearningformItem = {
  __typename?: 'ElearningformItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<ElearningformComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type ElearningformItems = {
  __typename?: 'ElearningformItems';
  items?: Maybe<Array<Maybe<ElearningformItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type EventformComponent = {
  __typename?: 'EventformComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  eventSlug?: Maybe<Scalars['String']>;
  hasLocations?: Maybe<Scalars['Boolean']>;
  termsAndConditions?: Maybe<Story>;
  title?: Maybe<Scalars['String']>;
};


export type EventformComponentTermsAndConditionsArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
};

export type EventformFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
  description?: Maybe<FilterQueryOperations>;
  event?: Maybe<FilterQueryOperations>;
  eventSlug?: Maybe<FilterQueryOperations>;
  termsAndConditions?: Maybe<FilterQueryOperations>;
  hasLocations?: Maybe<FilterQueryOperations>;
};

export type EventformItem = {
  __typename?: 'EventformItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<EventformComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type EventformItems = {
  __typename?: 'EventformItems';
  items?: Maybe<Array<Maybe<EventformItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type FilterQueryOperations = {
  /** Matches exactly one value */
  in?: Maybe<Scalars['String']>;
  /** Matches all without the given value */
  not_in?: Maybe<Scalars['String']>;
  /** Matches exactly one value with a wildcard search using * */
  like?: Maybe<Scalars['String']>;
  /** Matches all without the given value */
  not_like?: Maybe<Scalars['String']>;
  /** Matches any value of given array */
  in_array?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Must match all values of given array */
  all_in_array?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Greater than date (Exmples: 2019-03-03 or 2020-03-03T03:03:03) */
  gt_date?: Maybe<Scalars['ISO8601DateTime']>;
  /** Less than date (Format: 2019-03-03 or 2020-03-03T03:03:03) */
  lt_date?: Maybe<Scalars['ISO8601DateTime']>;
  /** Greater than integer value */
  gt_int?: Maybe<Scalars['Int']>;
  /** Less than integer value */
  lt_int?: Maybe<Scalars['Int']>;
  /** Matches exactly one integer value */
  in_int?: Maybe<Scalars['Int']>;
  /** Greater than float value */
  gt_float?: Maybe<Scalars['Float']>;
  /** Less than float value */
  lt_float?: Maybe<Scalars['Float']>;
};

export type FiltersComponent = {
  __typename?: 'FiltersComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  taste?: Maybe<Scalars['BlockScalar']>;
};

export type FiltersItem = {
  __typename?: 'FiltersItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<FiltersComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type FiltersItems = {
  __typename?: 'FiltersItems';
  items?: Maybe<Array<Maybe<FiltersItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type FooterComponent = {
  __typename?: 'FooterComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  bottom_links?: Maybe<Scalars['BlockScalar']>;
  component?: Maybe<Scalars['String']>;
  footer_columns?: Maybe<Scalars['BlockScalar']>;
};

export type FooterItem = {
  __typename?: 'FooterItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<FooterComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type FooterItems = {
  __typename?: 'FooterItems';
  items?: Maybe<Array<Maybe<FooterItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type FormComponent = {
  __typename?: 'FormComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  form?: Maybe<Story>;
  title?: Maybe<Scalars['String']>;
};


export type FormComponentFormArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
};

export type FormFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
  description?: Maybe<FilterQueryOperations>;
  form?: Maybe<FilterQueryOperations>;
};

export type FormItem = {
  __typename?: 'FormItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<FormComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type FormItems = {
  __typename?: 'FormItems';
  items?: Maybe<Array<Maybe<FormItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type HomeComponent = {
  __typename?: 'HomeComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  callToAction?: Maybe<Story>;
  cocktails_list?: Maybe<Array<Maybe<Story>>>;
  cocktails_title?: Maybe<Scalars['String']>;
  collection_title?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  gallery_images?: Maybe<Scalars['BlockScalar']>;
  gallery_link_label?: Maybe<Scalars['String']>;
  gallery_link_url?: Maybe<Link>;
  gallery_title?: Maybe<Scalars['String']>;
  header_button_link?: Maybe<Link>;
  header_button_text?: Maybe<Scalars['String']>;
  header_description?: Maybe<Scalars['String']>;
  header_title?: Maybe<Scalars['String']>;
  header_visual?: Maybe<Scalars['BlockScalar']>;
  instagram?: Maybe<Story>;
  seo?: Maybe<Scalars['JsonScalar']>;
};


export type HomeComponentCallToActionArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
};


export type HomeComponentCocktails_ListArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
};


export type HomeComponentInstagramArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
};

export type HomeFilterQuery = {
  gallery_title?: Maybe<FilterQueryOperations>;
  gallery_link_label?: Maybe<FilterQueryOperations>;
  collection_title?: Maybe<FilterQueryOperations>;
  cocktails_title?: Maybe<FilterQueryOperations>;
  callToAction?: Maybe<FilterQueryOperations>;
  header_title?: Maybe<FilterQueryOperations>;
  header_description?: Maybe<FilterQueryOperations>;
  header_button_text?: Maybe<FilterQueryOperations>;
  instagram?: Maybe<FilterQueryOperations>;
  cocktails_list?: Maybe<FilterQueryOperations>;
};

export type HomeItem = {
  __typename?: 'HomeItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<HomeComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type HomeItems = {
  __typename?: 'HomeItems';
  items?: Maybe<Array<Maybe<HomeItem>>>;
  total?: Maybe<Scalars['Int']>;
};


export type InstagramComponent = {
  __typename?: 'InstagramComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  ctaText?: Maybe<Scalars['String']>;
  ctaUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  photos?: Maybe<Scalars['BlockScalar']>;
  title?: Maybe<Scalars['String']>;
};

export type InstagramFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
  description?: Maybe<FilterQueryOperations>;
  ctaText?: Maybe<FilterQueryOperations>;
  ctaUrl?: Maybe<FilterQueryOperations>;
};

export type InstagramItem = {
  __typename?: 'InstagramItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<InstagramComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type InstagramItems = {
  __typename?: 'InstagramItems';
  items?: Maybe<Array<Maybe<InstagramItem>>>;
  total?: Maybe<Scalars['Int']>;
};


export type Link = {
  __typename?: 'Link';
  cachedUrl: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  fieldtype: Scalars['String'];
  id: Scalars['String'];
  linktype: Scalars['String'];
  story?: Maybe<Story>;
  url: Scalars['String'];
};

export type LinkEntries = {
  __typename?: 'LinkEntries';
  items: Array<LinkEntry>;
};

export type LinkEntry = {
  __typename?: 'LinkEntry';
  id?: Maybe<Scalars['Int']>;
  isFolder?: Maybe<Scalars['Boolean']>;
  isStartpage?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type ModalComponent = {
  __typename?: 'ModalComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  cancelButton?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  confirmButton?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ModalFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
  description?: Maybe<FilterQueryOperations>;
  confirmButton?: Maybe<FilterQueryOperations>;
  cancelButton?: Maybe<FilterQueryOperations>;
};

export type ModalItem = {
  __typename?: 'ModalItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<ModalComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type ModalItems = {
  __typename?: 'ModalItems';
  items?: Maybe<Array<Maybe<ModalItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type MomentComponent = {
  __typename?: 'MomentComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  callToAction?: Maybe<Story>;
  component?: Maybe<Scalars['String']>;
  instagramSection?: Maybe<Story>;
  sections?: Maybe<Scalars['BlockScalar']>;
  seo?: Maybe<Scalars['JsonScalar']>;
};


export type MomentComponentCallToActionArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
};


export type MomentComponentInstagramSectionArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
};

export type MomentFilterQuery = {
  callToAction?: Maybe<FilterQueryOperations>;
  instagramSection?: Maybe<FilterQueryOperations>;
};

export type MomentItem = {
  __typename?: 'MomentItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<MomentComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type MomentItems = {
  __typename?: 'MomentItems';
  items?: Maybe<Array<Maybe<MomentItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type MomentsoverviewComponent = {
  __typename?: 'MomentsoverviewComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  callToAction?: Maybe<Story>;
  component?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instagramSection?: Maybe<Story>;
  moments?: Maybe<Scalars['BlockScalar']>;
  seo?: Maybe<Scalars['JsonScalar']>;
  title?: Maybe<Scalars['String']>;
};


export type MomentsoverviewComponentCallToActionArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
};


export type MomentsoverviewComponentInstagramSectionArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
};

export type MomentsoverviewFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
  description?: Maybe<FilterQueryOperations>;
  callToAction?: Maybe<FilterQueryOperations>;
  instagramSection?: Maybe<FilterQueryOperations>;
};

export type MomentsoverviewItem = {
  __typename?: 'MomentsoverviewItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<MomentsoverviewComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type MomentsoverviewItems = {
  __typename?: 'MomentsoverviewItems';
  items?: Maybe<Array<Maybe<MomentsoverviewItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type NavigationComponent = {
  __typename?: 'NavigationComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  cart_hint?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  links?: Maybe<Scalars['BlockScalar']>;
  mobile_links?: Maybe<Scalars['BlockScalar']>;
  pre_header?: Maybe<Scalars['BlockScalar']>;
};

export type NavigationItem = {
  __typename?: 'NavigationItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<NavigationComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type NavigationItems = {
  __typename?: 'NavigationItems';
  items?: Maybe<Array<Maybe<NavigationItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaymentComponent = {
  __typename?: 'PaymentComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  callToAction?: Maybe<Story>;
  component?: Maybe<Scalars['String']>;
  failed_description?: Maybe<Scalars['String']>;
  failed_title?: Maybe<Scalars['String']>;
  instagramSection?: Maybe<Story>;
  pending_description?: Maybe<Scalars['String']>;
  pending_title?: Maybe<Scalars['String']>;
  succeeded_description?: Maybe<Scalars['String']>;
  succeeded_title?: Maybe<Scalars['String']>;
};


export type PaymentComponentCallToActionArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
};


export type PaymentComponentInstagramSectionArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
};

export type PaymentFilterQuery = {
  succeeded_title?: Maybe<FilterQueryOperations>;
  succeeded_description?: Maybe<FilterQueryOperations>;
  pending_title?: Maybe<FilterQueryOperations>;
  pending_description?: Maybe<FilterQueryOperations>;
  failed_title?: Maybe<FilterQueryOperations>;
  failed_description?: Maybe<FilterQueryOperations>;
  instagramSection?: Maybe<FilterQueryOperations>;
  callToAction?: Maybe<FilterQueryOperations>;
};

export type PaymentItem = {
  __typename?: 'PaymentItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<PaymentComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type PaymentItems = {
  __typename?: 'PaymentItems';
  items?: Maybe<Array<Maybe<PaymentItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PlpComponent = {
  __typename?: 'PlpComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  collections?: Maybe<Scalars['JsonScalar']>;
  component?: Maybe<Scalars['String']>;
  hidesFilters?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Scalars['JsonScalar']>;
  seo?: Maybe<Scalars['JsonScalar']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type PlpFilterQuery = {
  subtitle?: Maybe<FilterQueryOperations>;
  title?: Maybe<FilterQueryOperations>;
  hidesFilters?: Maybe<FilterQueryOperations>;
};

export type PlpItem = {
  __typename?: 'PlpItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<PlpComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type PlpItems = {
  __typename?: 'PlpItems';
  items?: Maybe<Array<Maybe<PlpItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ProductComponent = {
  __typename?: 'ProductComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  background_color?: Maybe<Scalars['JsonScalar']>;
  component?: Maybe<Scalars['String']>;
  image_section_image?: Maybe<Asset>;
  image_section_text?: Maybe<Scalars['JsonScalar']>;
  image_section_title?: Maybe<Scalars['String']>;
  instagramSection?: Maybe<Story>;
  more_info_text?: Maybe<Scalars['JsonScalar']>;
  more_info_title?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['JsonScalar']>;
  related_title?: Maybe<Scalars['String']>;
  shopify_product?: Maybe<Scalars['JsonScalar']>;
};


export type ProductComponentInstagramSectionArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
};

export type ProductFilterQuery = {
  more_info_title?: Maybe<FilterQueryOperations>;
  image_section_title?: Maybe<FilterQueryOperations>;
  related_title?: Maybe<FilterQueryOperations>;
  instagramSection?: Maybe<FilterQueryOperations>;
};

export type ProductItem = {
  __typename?: 'ProductItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<ProductComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type ProductItems = {
  __typename?: 'ProductItems';
  items?: Maybe<Array<Maybe<ProductItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type QueryType = {
  __typename?: 'QueryType';
  AgeconsentItem?: Maybe<AgeconsentItem>;
  AgeconsentItems?: Maybe<AgeconsentItems>;
  AuthpageItem?: Maybe<AuthpageItem>;
  AuthpageItems?: Maybe<AuthpageItems>;
  BlogpageItem?: Maybe<BlogpageItem>;
  BlogpageItems?: Maybe<BlogpageItems>;
  BlogpostcategoryItem?: Maybe<BlogpostcategoryItem>;
  BlogpostcategoryItems?: Maybe<BlogpostcategoryItems>;
  CalltoactionItem?: Maybe<CalltoactionItem>;
  CalltoactionItems?: Maybe<CalltoactionItems>;
  CheckoutItem?: Maybe<CheckoutItem>;
  CheckoutItems?: Maybe<CheckoutItems>;
  CocktailItem?: Maybe<CocktailItem>;
  CocktailItems?: Maybe<CocktailItems>;
  CocktailoverviewItem?: Maybe<CocktailoverviewItem>;
  CocktailoverviewItems?: Maybe<CocktailoverviewItems>;
  CollectionItem?: Maybe<CollectionItem>;
  CollectionItems?: Maybe<CollectionItems>;
  ContactformItem?: Maybe<ContactformItem>;
  ContactformItems?: Maybe<ContactformItems>;
  ContentNode?: Maybe<ContentItem>;
  ContentNodes?: Maybe<ContentItems>;
  CookieconsentItem?: Maybe<CookieconsentItem>;
  CookieconsentItems?: Maybe<CookieconsentItems>;
  CourseformItem?: Maybe<CourseformItem>;
  CourseformItems?: Maybe<CourseformItems>;
  DatasourceEntries?: Maybe<DatasourceEntries>;
  Datasources?: Maybe<Datasources>;
  DynamicblogpageItem?: Maybe<DynamicblogpageItem>;
  DynamicblogpageItems?: Maybe<DynamicblogpageItems>;
  DynamicpageItem?: Maybe<DynamicpageItem>;
  DynamicpageItems?: Maybe<DynamicpageItems>;
  ElearningformItem?: Maybe<ElearningformItem>;
  ElearningformItems?: Maybe<ElearningformItems>;
  EventformItem?: Maybe<EventformItem>;
  EventformItems?: Maybe<EventformItems>;
  FiltersItem?: Maybe<FiltersItem>;
  FiltersItems?: Maybe<FiltersItems>;
  FooterItem?: Maybe<FooterItem>;
  FooterItems?: Maybe<FooterItems>;
  FormItem?: Maybe<FormItem>;
  FormItems?: Maybe<FormItems>;
  HomeItem?: Maybe<HomeItem>;
  HomeItems?: Maybe<HomeItems>;
  InstagramItem?: Maybe<InstagramItem>;
  InstagramItems?: Maybe<InstagramItems>;
  Links?: Maybe<LinkEntries>;
  ModalItem?: Maybe<ModalItem>;
  ModalItems?: Maybe<ModalItems>;
  MomentItem?: Maybe<MomentItem>;
  MomentItems?: Maybe<MomentItems>;
  MomentsoverviewItem?: Maybe<MomentsoverviewItem>;
  MomentsoverviewItems?: Maybe<MomentsoverviewItems>;
  NavigationItem?: Maybe<NavigationItem>;
  NavigationItems?: Maybe<NavigationItems>;
  PaymentItem?: Maybe<PaymentItem>;
  PaymentItems?: Maybe<PaymentItems>;
  PlpItem?: Maybe<PlpItem>;
  PlpItems?: Maybe<PlpItems>;
  ProductItem?: Maybe<ProductItem>;
  ProductItems?: Maybe<ProductItems>;
  RateLimit?: Maybe<RateLimit>;
  RegisterpageItem?: Maybe<RegisterpageItem>;
  RegisterpageItems?: Maybe<RegisterpageItems>;
  SearchpageItem?: Maybe<SearchpageItem>;
  SearchpageItems?: Maybe<SearchpageItems>;
  Space?: Maybe<Space>;
  SubcollectionItem?: Maybe<SubcollectionItem>;
  SubcollectionItems?: Maybe<SubcollectionItems>;
  Tags?: Maybe<Tags>;
  TermsandconditionsItem?: Maybe<TermsandconditionsItem>;
  TermsandconditionsItems?: Maybe<TermsandconditionsItems>;
};


export type QueryTypeAgeconsentItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeAgeconsentItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<AgeconsentFilterQuery>;
};


export type QueryTypeAuthpageItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeAuthpageItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<AuthpageFilterQuery>;
};


export type QueryTypeBlogpageItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeBlogpageItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<BlogpageFilterQuery>;
};


export type QueryTypeBlogpostcategoryItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeBlogpostcategoryItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<BlogpostcategoryFilterQuery>;
};


export type QueryTypeCalltoactionItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeCalltoactionItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<CalltoactionFilterQuery>;
};


export type QueryTypeCheckoutItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeCheckoutItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<CheckoutFilterQuery>;
};


export type QueryTypeCocktailItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeCocktailItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<CocktailFilterQuery>;
};


export type QueryTypeCocktailoverviewItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeCocktailoverviewItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<CocktailoverviewFilterQuery>;
};


export type QueryTypeCollectionItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeCollectionItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<CollectionFilterQuery>;
};


export type QueryTypeContactformItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeContactformItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<ContactformFilterQuery>;
};


export type QueryTypeContentNodeArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeContentNodesArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
};


export type QueryTypeCookieconsentItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeCookieconsentItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<CookieconsentFilterQuery>;
};


export type QueryTypeCourseformItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeCourseformItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<CourseformFilterQuery>;
};


export type QueryTypeDatasourceEntriesArgs = {
  datasource?: Maybe<Scalars['String']>;
  dimension?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
};


export type QueryTypeDatasourcesArgs = {
  search?: Maybe<Scalars['String']>;
  by_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryTypeDynamicblogpageItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeDynamicblogpageItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<DynamicblogpageFilterQuery>;
};


export type QueryTypeDynamicpageItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeDynamicpageItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<DynamicpageFilterQuery>;
};


export type QueryTypeElearningformItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeElearningformItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<ElearningformFilterQuery>;
};


export type QueryTypeEventformItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeEventformItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<EventformFilterQuery>;
};


export type QueryTypeFiltersItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeFiltersItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
};


export type QueryTypeFooterItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeFooterItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
};


export type QueryTypeFormItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeFormItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<FormFilterQuery>;
};


export type QueryTypeHomeItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeHomeItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<HomeFilterQuery>;
};


export type QueryTypeInstagramItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeInstagramItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<InstagramFilterQuery>;
};


export type QueryTypeLinksArgs = {
  starts_with?: Maybe<Scalars['String']>;
  paginated?: Maybe<Scalars['Boolean']>;
};


export type QueryTypeModalItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeModalItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<ModalFilterQuery>;
};


export type QueryTypeMomentItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeMomentItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<MomentFilterQuery>;
};


export type QueryTypeMomentsoverviewItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeMomentsoverviewItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<MomentsoverviewFilterQuery>;
};


export type QueryTypeNavigationItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeNavigationItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
};


export type QueryTypePaymentItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypePaymentItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<PaymentFilterQuery>;
};


export type QueryTypePlpItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypePlpItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<PlpFilterQuery>;
};


export type QueryTypeProductItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeProductItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<ProductFilterQuery>;
};


export type QueryTypeRegisterpageItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeRegisterpageItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<RegisterpageFilterQuery>;
};


export type QueryTypeSearchpageItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeSearchpageItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
};


export type QueryTypeSubcollectionItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeSubcollectionItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<SubcollectionFilterQuery>;
};


export type QueryTypeTagsArgs = {
  starts_with?: Maybe<Scalars['String']>;
};


export type QueryTypeTermsandconditionsItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeTermsandconditionsItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
};

export type RateLimit = {
  __typename?: 'RateLimit';
  maxCost: Scalars['Int'];
};

export type RegisterpageComponent = {
  __typename?: 'RegisterpageComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  seo?: Maybe<Scalars['JsonScalar']>;
  termsAndConditions?: Maybe<Story>;
  title?: Maybe<Scalars['String']>;
};


export type RegisterpageComponentTermsAndConditionsArgs = {
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
};

export type RegisterpageFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
  description?: Maybe<FilterQueryOperations>;
  termsAndConditions?: Maybe<FilterQueryOperations>;
};

export type RegisterpageItem = {
  __typename?: 'RegisterpageItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<RegisterpageComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type RegisterpageItems = {
  __typename?: 'RegisterpageItems';
  items?: Maybe<Array<Maybe<RegisterpageItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchpageComponent = {
  __typename?: 'SearchpageComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  seo?: Maybe<Scalars['JsonScalar']>;
};

export type SearchpageItem = {
  __typename?: 'SearchpageItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<SearchpageComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type SearchpageItems = {
  __typename?: 'SearchpageItems';
  items?: Maybe<Array<Maybe<SearchpageItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Space = {
  __typename?: 'Space';
  domain: Scalars['String'];
  id: Scalars['Int'];
  languageCodes: Array<Maybe<Scalars['String']>>;
  name: Scalars['String'];
  version: Scalars['Int'];
};

export type Story = {
  __typename?: 'Story';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<Scalars['JsonScalar']>;
  createdAt?: Maybe<Scalars['String']>;
  firstPublishedAt?: Maybe<Scalars['String']>;
  fullSlug?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isStartpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  metaData?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  publishedAt?: Maybe<Scalars['String']>;
  releaseId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sortByDate?: Maybe<Scalars['String']>;
  tagList?: Maybe<Array<Maybe<Scalars['String']>>>;
  translatedSlugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type SubcollectionComponent = {
  __typename?: 'SubcollectionComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  collection?: Maybe<Scalars['JsonScalar']>;
  component?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['BlockScalar']>;
  hidesFilters?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Asset>;
  isHidden?: Maybe<Scalars['Boolean']>;
  seo?: Maybe<Scalars['JsonScalar']>;
  title?: Maybe<Scalars['String']>;
};

export type SubcollectionFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
  isHidden?: Maybe<FilterQueryOperations>;
  hidesFilters?: Maybe<FilterQueryOperations>;
};

export type SubcollectionItem = {
  __typename?: 'SubcollectionItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<SubcollectionComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type SubcollectionItems = {
  __typename?: 'SubcollectionItems';
  items?: Maybe<Array<Maybe<SubcollectionItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Tag = {
  __typename?: 'Tag';
  name: Scalars['String'];
  taggingsCount: Scalars['Int'];
};

export type Tags = {
  __typename?: 'Tags';
  items: Array<Tag>;
};

export type TermsandconditionsComponent = {
  __typename?: 'TermsandconditionsComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  termsAndConditions?: Maybe<Scalars['JsonScalar']>;
};

export type TermsandconditionsItem = {
  __typename?: 'TermsandconditionsItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<TermsandconditionsComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type TermsandconditionsItems = {
  __typename?: 'TermsandconditionsItems';
  items?: Maybe<Array<Maybe<TermsandconditionsItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type TranslatedSlug = {
  __typename?: 'TranslatedSlug';
  lang: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type ImageFragmentFragment = (
  { __typename?: 'Asset' }
  & Pick<Asset, 'alt' | 'filename' | 'focus'>
);

export type AllSubcollectionsQueryVariables = Exact<{
  filter?: Maybe<Scalars['JsonScalar']>;
}>;


export type AllSubcollectionsQuery = (
  { __typename?: 'QueryType' }
  & { SubcollectionItems?: Maybe<(
    { __typename?: 'SubcollectionItems' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'SubcollectionItem' }
      & Pick<SubcollectionItem, 'uuid' | 'full_slug'>
      & { translated_slugs?: Maybe<Array<Maybe<(
        { __typename?: 'TranslatedSlug' }
        & Pick<TranslatedSlug, 'lang' | 'path'>
      )>>> }
    )>>> }
  )> }
);

export type AuthPageQueryVariables = Exact<{
  slug: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
}>;


export type AuthPageQuery = (
  { __typename?: 'QueryType' }
  & { AuthpageItem?: Maybe<(
    { __typename?: 'AuthpageItem' }
    & Pick<AuthpageItem, 'uuid'>
    & { content?: Maybe<(
      { __typename?: 'AuthpageComponent' }
      & Pick<AuthpageComponent, '_editable' | 'title' | 'description' | 'seo'>
    )> }
  )> }
);

export type BlogQueryVariables = Exact<{
  slug: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
}>;


export type BlogQuery = (
  { __typename?: 'QueryType' }
  & { BlogpageItem?: Maybe<(
    { __typename?: 'BlogpageItem' }
    & Pick<BlogpageItem, 'id' | 'default_full_slug'>
    & { content?: Maybe<(
      { __typename?: 'BlogpageComponent' }
      & Pick<BlogpageComponent, '_uid' | '_editable' | 'title' | 'description' | 'seo'>
      & { instagramSection?: Maybe<(
        { __typename?: 'Story' }
        & Pick<Story, 'content'>
      )>, callToAction?: Maybe<(
        { __typename?: 'Story' }
        & Pick<Story, 'content' | 'id'>
      )> }
    )>, translated_slugs?: Maybe<Array<Maybe<(
      { __typename?: 'TranslatedSlug' }
      & Pick<TranslatedSlug, 'lang' | 'path'>
    )>>> }
  )> }
);

export type BlogPostQueryVariables = Exact<{
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
}>;


export type BlogPostQuery = (
  { __typename?: 'QueryType' }
  & { DynamicblogpageItem?: Maybe<(
    { __typename?: 'DynamicblogpageItem' }
    & Pick<DynamicblogpageItem, 'uuid' | 'full_slug' | 'default_full_slug'>
    & { content?: Maybe<(
      { __typename?: 'DynamicblogpageComponent' }
      & Pick<DynamicblogpageComponent, 'sections' | 'noIndex' | 'seo' | '_editable'>
      & { instagramSection?: Maybe<(
        { __typename?: 'Story' }
        & Pick<Story, 'content'>
      )>, callToAction?: Maybe<(
        { __typename?: 'Story' }
        & Pick<Story, 'content' | 'id'>
      )> }
    )>, translated_slugs?: Maybe<Array<Maybe<(
      { __typename?: 'TranslatedSlug' }
      & Pick<TranslatedSlug, 'lang' | 'path'>
    )>>> }
  )> }
);

export type BlogPostsQueryVariables = Exact<{
  filter?: Maybe<Scalars['JsonScalar']>;
  prefix?: Maybe<Scalars['String']>;
}>;


export type BlogPostsQuery = (
  { __typename?: 'QueryType' }
  & { DynamicblogpageItems?: Maybe<(
    { __typename?: 'DynamicblogpageItems' }
    & Pick<DynamicblogpageItems, 'total'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'DynamicblogpageItem' }
      & Pick<DynamicblogpageItem, 'id' | 'slug' | 'full_slug' | 'default_full_slug'>
      & { translated_slugs?: Maybe<Array<Maybe<(
        { __typename?: 'TranslatedSlug' }
        & Pick<TranslatedSlug, 'lang' | 'path'>
      )>>>, content?: Maybe<(
        { __typename?: 'DynamicblogpageComponent' }
        & Pick<DynamicblogpageComponent, 'title' | 'shortDescription'>
        & { category?: Maybe<(
          { __typename?: 'Story' }
          & Pick<Story, 'content' | 'uuid'>
        )>, previewImage?: Maybe<(
          { __typename?: 'Asset' }
          & ImageFragmentFragment
        )> }
      )> }
    )>>> }
  )> }
);

export type CallToActionQueryVariables = Exact<{
  slug: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
}>;


export type CallToActionQuery = (
  { __typename?: 'QueryType' }
  & { CalltoactionItem?: Maybe<(
    { __typename?: 'CalltoactionItem' }
    & Pick<CalltoactionItem, 'uuid'>
    & { content?: Maybe<(
      { __typename?: 'CalltoactionComponent' }
      & Pick<CalltoactionComponent, '_editable' | 'blocks' | 'component' | 'title'>
    )> }
  )> }
);

export type CocktailQueryVariables = Exact<{
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
}>;


export type CocktailQuery = (
  { __typename?: 'QueryType' }
  & { CocktailItem?: Maybe<(
    { __typename?: 'CocktailItem' }
    & Pick<CocktailItem, 'id' | 'slug' | 'uuid' | 'name' | 'published_at' | 'default_full_slug' | 'full_slug'>
    & { content?: Maybe<(
      { __typename?: 'CocktailComponent' }
      & Pick<CocktailComponent, '_editable' | '_uid' | 'backgroundColor' | 'component' | 'description' | 'title' | 'glass' | 'glass_icon' | 'flavour' | 'recipe' | 'visual' | 'products' | 'featured_products_title' | 'featured_products_description' | 'related_cocktails_title' | 'seo'>
      & { image?: Maybe<(
        { __typename?: 'Asset' }
        & ImageFragmentFragment
      )>, related?: Maybe<Array<Maybe<(
        { __typename?: 'Story' }
        & Pick<Story, 'content' | 'slug' | 'fullSlug'>
      )>>>, instagramSection?: Maybe<(
        { __typename?: 'Story' }
        & Pick<Story, 'content'>
      )>, callToAction?: Maybe<(
        { __typename?: 'Story' }
        & Pick<Story, 'content' | 'id'>
      )>, rowUrl?: Maybe<(
        { __typename?: 'Link' }
        & Pick<Link, 'cachedUrl' | 'linktype'>
        & { story?: Maybe<(
          { __typename?: 'Story' }
          & Pick<Story, 'fullSlug'>
        )> }
      )> }
    )>, translated_slugs?: Maybe<Array<Maybe<(
      { __typename?: 'TranslatedSlug' }
      & Pick<TranslatedSlug, 'lang' | 'path'>
    )>>> }
  )> }
);

export type CocktailItemsQueryVariables = Exact<{
  prefix?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type CocktailItemsQuery = (
  { __typename?: 'QueryType' }
  & { CocktailItems?: Maybe<(
    { __typename?: 'CocktailItems' }
    & Pick<CocktailItems, 'total'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'CocktailItem' }
      & Pick<CocktailItem, 'id' | 'slug' | 'full_slug' | 'uuid' | 'created_at'>
      & { content?: Maybe<(
        { __typename?: 'CocktailComponent' }
        & Pick<CocktailComponent, 'description' | 'title' | 'backgroundColor' | '_editable' | '_uid'>
        & { image?: Maybe<(
          { __typename?: 'Asset' }
          & Pick<Asset, 'alt' | 'title' | 'name' | 'copyright' | 'filename' | 'focus' | 'id'>
        )>, rowUrl?: Maybe<(
          { __typename?: 'Link' }
          & Pick<Link, 'cachedUrl' | 'linktype'>
          & { story?: Maybe<(
            { __typename?: 'Story' }
            & Pick<Story, 'fullSlug'>
          )> }
        )> }
      )>, translated_slugs?: Maybe<Array<Maybe<(
        { __typename?: 'TranslatedSlug' }
        & Pick<TranslatedSlug, 'lang' | 'path'>
      )>>> }
    )>>> }
  )> }
);

export type CocktailsOverviewQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type CocktailsOverviewQuery = (
  { __typename?: 'QueryType' }
  & { CocktailoverviewItem?: Maybe<(
    { __typename?: 'CocktailoverviewItem' }
    & Pick<CocktailoverviewItem, 'id' | 'uuid' | 'name' | 'default_full_slug'>
    & { content?: Maybe<(
      { __typename?: 'CocktailoverviewComponent' }
      & Pick<CocktailoverviewComponent, 'title' | 'subtitle' | 'seo' | '_uid' | '_editable'>
    )>, translated_slugs?: Maybe<Array<Maybe<(
      { __typename?: 'TranslatedSlug' }
      & Pick<TranslatedSlug, 'lang' | 'path'>
    )>>> }
  )> }
);

export type CollectionItemQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CollectionItemQuery = (
  { __typename?: 'QueryType' }
  & { CollectionItem?: Maybe<(
    { __typename?: 'CollectionItem' }
    & Pick<CollectionItem, 'uuid' | 'default_full_slug' | 'full_slug'>
    & { content?: Maybe<(
      { __typename?: 'CollectionComponent' }
      & Pick<CollectionComponent, 'header' | 'title' | '_uid' | 'collection' | 'isHidden' | 'hidesFilters' | 'seo' | '_editable'>
      & { image?: Maybe<(
        { __typename?: 'Asset' }
        & ImageFragmentFragment
      )> }
    )>, translated_slugs?: Maybe<Array<Maybe<(
      { __typename?: 'TranslatedSlug' }
      & Pick<TranslatedSlug, 'lang' | 'path'>
    )>>> }
  )> }
);

export type CollectionItemsQueryVariables = Exact<{
  prefix?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['JsonScalar']>;
}>;


export type CollectionItemsQuery = (
  { __typename?: 'QueryType' }
  & { CollectionItems?: Maybe<(
    { __typename?: 'CollectionItems' }
    & Pick<CollectionItems, 'total'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'CollectionItem' }
      & Pick<CollectionItem, 'uuid' | 'slug' | 'full_slug'>
      & { content?: Maybe<(
        { __typename?: 'CollectionComponent' }
        & Pick<CollectionComponent, 'header' | 'title' | '_uid' | 'collection' | 'isHidden' | 'hidesFilters'>
        & { image?: Maybe<(
          { __typename?: 'Asset' }
          & ImageFragmentFragment
        )> }
      )>, translated_slugs?: Maybe<Array<Maybe<(
        { __typename?: 'TranslatedSlug' }
        & Pick<TranslatedSlug, 'lang' | 'path'>
      )>>> }
    )>>> }
  )> }
);

export type DynamicPagesQueryVariables = Exact<{
  filter?: Maybe<Scalars['JsonScalar']>;
  prefix?: Maybe<Scalars['String']>;
}>;


export type DynamicPagesQuery = (
  { __typename?: 'QueryType' }
  & { DynamicpageItems?: Maybe<(
    { __typename?: 'DynamicpageItems' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'DynamicpageItem' }
      & Pick<DynamicpageItem, 'slug' | 'full_slug' | 'published_at' | 'uuid'>
      & { translated_slugs?: Maybe<Array<Maybe<(
        { __typename?: 'TranslatedSlug' }
        & Pick<TranslatedSlug, 'lang' | 'path'>
      )>>> }
    )>>> }
  )> }
);

export type FiltersMappingQueryVariables = Exact<{ [key: string]: never; }>;


export type FiltersMappingQuery = (
  { __typename?: 'QueryType' }
  & { FiltersItem?: Maybe<(
    { __typename?: 'FiltersItem' }
    & Pick<FiltersItem, 'uuid'>
    & { content?: Maybe<(
      { __typename?: 'FiltersComponent' }
      & Pick<FiltersComponent, 'taste' | '_editable'>
    )> }
  )> }
);

export type FooterQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type FooterQuery = (
  { __typename?: 'QueryType' }
  & { FooterItem?: Maybe<(
    { __typename?: 'FooterItem' }
    & Pick<FooterItem, 'published_at' | 'uuid'>
    & { content?: Maybe<(
      { __typename?: 'FooterComponent' }
      & Pick<FooterComponent, 'footer_columns' | 'bottom_links' | '_editable'>
    )> }
  )> }
);

export type HomeQueryVariables = Exact<{
  slug: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
}>;


export type HomeQuery = (
  { __typename?: 'QueryType' }
  & { HomeItem?: Maybe<(
    { __typename?: 'HomeItem' }
    & Pick<HomeItem, 'uuid'>
    & { content?: Maybe<(
      { __typename?: 'HomeComponent' }
      & Pick<HomeComponent, '_editable' | 'header_title' | 'header_description' | 'header_button_text' | 'header_visual' | 'gallery_images' | 'gallery_title' | 'collection_title' | 'gallery_link_label' | 'cocktails_title' | 'seo'>
      & { header_button_link?: Maybe<(
        { __typename?: 'Link' }
        & Pick<Link, 'url'>
        & { story?: Maybe<(
          { __typename?: 'Story' }
          & Pick<Story, 'fullSlug'>
        )> }
      )>, gallery_link_url?: Maybe<(
        { __typename?: 'Link' }
        & Pick<Link, 'url'>
        & { story?: Maybe<(
          { __typename?: 'Story' }
          & Pick<Story, 'fullSlug'>
        )> }
      )>, cocktails_list?: Maybe<Array<Maybe<(
        { __typename?: 'Story' }
        & Pick<Story, 'content' | 'slug' | 'fullSlug'>
      )>>>, instagram?: Maybe<(
        { __typename?: 'Story' }
        & Pick<Story, 'content'>
      )>, callToAction?: Maybe<(
        { __typename?: 'Story' }
        & Pick<Story, 'id' | 'content'>
      )> }
    )> }
  )> }
);

export type InstagramQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type InstagramQuery = (
  { __typename?: 'QueryType' }
  & { InstagramItem?: Maybe<(
    { __typename?: 'InstagramItem' }
    & Pick<InstagramItem, 'uuid'>
    & { content?: Maybe<(
      { __typename?: 'InstagramComponent' }
      & Pick<InstagramComponent, 'title' | 'description' | 'photos' | 'ctaText' | 'ctaUrl' | '_editable'>
    )> }
  )> }
);

export type ModalQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type ModalQuery = (
  { __typename?: 'QueryType' }
  & { ModalItem?: Maybe<(
    { __typename?: 'ModalItem' }
    & Pick<ModalItem, 'uuid'>
    & { content?: Maybe<(
      { __typename?: 'ModalComponent' }
      & Pick<ModalComponent, 'title' | 'description' | 'confirmButton' | 'cancelButton' | '_editable'>
    )> }
  )> }
);

export type MomentsQueryVariables = Exact<{
  slug: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
}>;


export type MomentsQuery = (
  { __typename?: 'QueryType' }
  & { MomentsoverviewItem?: Maybe<(
    { __typename?: 'MomentsoverviewItem' }
    & Pick<MomentsoverviewItem, 'uuid' | 'default_full_slug'>
    & { content?: Maybe<(
      { __typename?: 'MomentsoverviewComponent' }
      & Pick<MomentsoverviewComponent, '_editable' | 'title' | 'description' | 'moments' | 'seo'>
      & { instagramSection?: Maybe<(
        { __typename?: 'Story' }
        & Pick<Story, 'content'>
      )>, callToAction?: Maybe<(
        { __typename?: 'Story' }
        & Pick<Story, 'content' | 'id'>
      )> }
    )>, translated_slugs?: Maybe<Array<Maybe<(
      { __typename?: 'TranslatedSlug' }
      & Pick<TranslatedSlug, 'lang' | 'path'>
    )>>> }
  )> }
);

export type NavigationQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type NavigationQuery = (
  { __typename?: 'QueryType' }
  & { NavigationItem?: Maybe<(
    { __typename?: 'NavigationItem' }
    & Pick<NavigationItem, 'published_at' | 'uuid'>
    & { content?: Maybe<(
      { __typename?: 'NavigationComponent' }
      & Pick<NavigationComponent, 'links' | 'pre_header' | 'mobile_links' | 'cart_hint' | '_editable'>
    )> }
  )> }
);

export type PageByIdQueryVariables = Exact<{
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
}>;


export type PageByIdQuery = (
  { __typename?: 'QueryType' }
  & { DynamicpageItem?: Maybe<(
    { __typename?: 'DynamicpageItem' }
    & Pick<DynamicpageItem, 'uuid' | 'full_slug' | 'default_full_slug'>
    & { content?: Maybe<(
      { __typename?: 'DynamicpageComponent' }
      & Pick<DynamicpageComponent, 'sections' | 'noIndex' | 'seo' | '_editable'>
      & { instagramSection?: Maybe<(
        { __typename?: 'Story' }
        & Pick<Story, 'content'>
      )>, callToAction?: Maybe<(
        { __typename?: 'Story' }
        & Pick<Story, 'content' | 'id'>
      )> }
    )>, translated_slugs?: Maybe<Array<Maybe<(
      { __typename?: 'TranslatedSlug' }
      & Pick<TranslatedSlug, 'lang' | 'path'>
    )>>> }
  )> }
);

export type PaymentsPageQueryVariables = Exact<{
  slug: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
}>;


export type PaymentsPageQuery = (
  { __typename?: 'QueryType' }
  & { PaymentItem?: Maybe<(
    { __typename?: 'PaymentItem' }
    & Pick<PaymentItem, 'uuid'>
    & { content?: Maybe<(
      { __typename?: 'PaymentComponent' }
      & Pick<PaymentComponent, '_editable' | 'succeeded_title' | 'pending_title' | 'failed_title' | 'succeeded_description' | 'failed_description' | 'pending_description'>
      & { instagramSection?: Maybe<(
        { __typename?: 'Story' }
        & Pick<Story, 'content'>
      )>, callToAction?: Maybe<(
        { __typename?: 'Story' }
        & Pick<Story, 'content' | 'id'>
      )> }
    )> }
  )> }
);

export type ProductQueryVariables = Exact<{
  id: Scalars['String'];
  prefix: Scalars['String'];
  lang?: Maybe<Scalars['String']>;
}>;


export type ProductQuery = (
  { __typename?: 'QueryType' }
  & { ProductItems?: Maybe<(
    { __typename?: 'ProductItems' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'ProductItem' }
      & Pick<ProductItem, 'uuid' | 'slug'>
      & { content?: Maybe<(
        { __typename?: 'ProductComponent' }
        & Pick<ProductComponent, '_editable' | 'products' | 'related_title' | 'more_info_title' | 'more_info_text' | 'image_section_title' | 'image_section_text' | 'component' | 'background_color'>
        & { image_section_image?: Maybe<(
          { __typename?: 'Asset' }
          & ImageFragmentFragment
        )>, instagramSection?: Maybe<(
          { __typename?: 'Story' }
          & Pick<Story, 'content'>
        )> }
      )> }
    )>>> }
  )> }
);

export type ProductByIdQueryVariables = Exact<{
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
}>;


export type ProductByIdQuery = (
  { __typename?: 'QueryType' }
  & { ProductItem?: Maybe<(
    { __typename?: 'ProductItem' }
    & Pick<ProductItem, 'uuid' | 'slug'>
    & { content?: Maybe<(
      { __typename?: 'ProductComponent' }
      & Pick<ProductComponent, '_editable' | 'products' | 'related_title' | 'more_info_title' | 'more_info_text' | 'image_section_title' | 'image_section_text' | 'component' | 'background_color'>
      & { image_section_image?: Maybe<(
        { __typename?: 'Asset' }
        & ImageFragmentFragment
      )>, instagramSection?: Maybe<(
        { __typename?: 'Story' }
        & Pick<Story, 'content'>
      )> }
    )> }
  )> }
);

export type ShopAllQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type ShopAllQuery = (
  { __typename?: 'QueryType' }
  & { PlpItem?: Maybe<(
    { __typename?: 'PlpItem' }
    & Pick<PlpItem, 'id' | 'name' | 'slug' | 'uuid'>
    & { content?: Maybe<(
      { __typename?: 'PlpComponent' }
      & Pick<PlpComponent, 'items' | 'subtitle' | 'title' | 'hidesFilters' | 'collections' | 'seo' | '_editable' | '_uid'>
    )> }
  )> }
);

export type RegisterPageQueryVariables = Exact<{
  slug: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
}>;


export type RegisterPageQuery = (
  { __typename?: 'QueryType' }
  & { RegisterpageItem?: Maybe<(
    { __typename?: 'RegisterpageItem' }
    & Pick<RegisterpageItem, 'uuid'>
    & { content?: Maybe<(
      { __typename?: 'RegisterpageComponent' }
      & Pick<RegisterpageComponent, '_editable' | 'seo'>
      & { termsAndConditions?: Maybe<(
        { __typename?: 'Story' }
        & Pick<Story, 'content'>
      )> }
    )> }
  )> }
);

export type SearchQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type SearchQuery = (
  { __typename?: 'QueryType' }
  & { SearchpageItem?: Maybe<(
    { __typename?: 'SearchpageItem' }
    & Pick<SearchpageItem, 'id' | 'uuid' | 'name' | 'default_full_slug'>
    & { content?: Maybe<(
      { __typename?: 'SearchpageComponent' }
      & Pick<SearchpageComponent, 'seo' | '_uid' | '_editable'>
    )>, translated_slugs?: Maybe<Array<Maybe<(
      { __typename?: 'TranslatedSlug' }
      & Pick<TranslatedSlug, 'lang' | 'path'>
    )>>> }
  )> }
);

export type SubcollectionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SubcollectionQuery = (
  { __typename?: 'QueryType' }
  & { SubcollectionItem?: Maybe<(
    { __typename?: 'SubcollectionItem' }
    & Pick<SubcollectionItem, 'uuid' | 'slug' | 'default_full_slug'>
    & { content?: Maybe<(
      { __typename?: 'SubcollectionComponent' }
      & Pick<SubcollectionComponent, 'header' | 'title' | '_uid' | 'collection' | 'isHidden' | 'hidesFilters' | 'seo' | '_editable'>
      & { image?: Maybe<(
        { __typename?: 'Asset' }
        & ImageFragmentFragment
      )> }
    )>, translated_slugs?: Maybe<Array<Maybe<(
      { __typename?: 'TranslatedSlug' }
      & Pick<TranslatedSlug, 'lang' | 'path'>
    )>>> }
  )> }
);

export type SubcollectionsQueryVariables = Exact<{
  prefix: Scalars['String'];
  filter?: Maybe<Scalars['JsonScalar']>;
}>;


export type SubcollectionsQuery = (
  { __typename?: 'QueryType' }
  & { SubcollectionItems?: Maybe<(
    { __typename?: 'SubcollectionItems' }
    & Pick<SubcollectionItems, 'total'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'SubcollectionItem' }
      & Pick<SubcollectionItem, 'uuid' | 'slug' | 'full_slug'>
      & { content?: Maybe<(
        { __typename?: 'SubcollectionComponent' }
        & Pick<SubcollectionComponent, 'header' | 'title' | '_uid' | 'collection' | 'isHidden' | 'hidesFilters' | '_editable'>
        & { image?: Maybe<(
          { __typename?: 'Asset' }
          & ImageFragmentFragment
        )> }
      )>, translated_slugs?: Maybe<Array<Maybe<(
        { __typename?: 'TranslatedSlug' }
        & Pick<TranslatedSlug, 'lang' | 'path'>
      )>>> }
    )>>> }
  )> }
);

export type TermsAndConditionsItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type TermsAndConditionsItemsQuery = (
  { __typename?: 'QueryType' }
  & { TermsandconditionsItems?: Maybe<(
    { __typename?: 'TermsandconditionsItems' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'TermsandconditionsItem' }
      & { content?: Maybe<(
        { __typename?: 'TermsandconditionsComponent' }
        & Pick<TermsandconditionsComponent, 'termsAndConditions' | '_uid' | '_editable'>
      )> }
    )>>> }
  )> }
);

export const ImageFragmentFragmentDoc = gql`
    fragment ImageFragment on Asset {
  alt
  filename
  focus
}
    `;
export const AllSubcollectionsDocument = gql`
    query allSubcollections($filter: JsonScalar) {
  SubcollectionItems(filter_query: $filter) {
    items {
      uuid
      full_slug
      translated_slugs {
        lang
        path
      }
    }
  }
}
    `;
export const AuthPageDocument = gql`
    query authPage($slug: ID!, $lang: String = "default") {
  AuthpageItem(id: $slug, language: $lang) {
    content {
      _editable
      title
      description
      seo
    }
    uuid
  }
}
    `;
export const BlogDocument = gql`
    query blog($slug: ID!, $lang: String = "default") {
  BlogpageItem(id: $slug, resolve_links: "url") {
    id
    content {
      _uid
      _editable
      title
      description
      instagramSection(language: $lang) {
        content
      }
      callToAction(language: $lang) {
        content
        id
      }
      seo
    }
    translated_slugs {
      lang
      path
    }
    default_full_slug
  }
}
    `;
export const BlogPostDocument = gql`
    query blogPost($id: ID!, $lang: String = "default") {
  DynamicblogpageItem(
    id: $id
    resolve_relations: "contact_form_dynamic.form,event_form_dynamic.form,products_dynamic.shopify_products,cocktails_dynamic.cocktails,cta_dynamic.call_to_action,course_form_dynamic.form,elearning_form_dynamic.form"
    resolve_links: "url"
  ) {
    content {
      sections
      instagramSection(language: $lang) {
        content
      }
      callToAction(language: $lang) {
        content
        id
      }
      noIndex
      seo
      _editable
    }
    uuid
    full_slug
    translated_slugs {
      lang
      path
    }
    default_full_slug
  }
}
    `;
export const BlogPostsDocument = gql`
    query blogPosts($filter: JsonScalar, $prefix: String = "") {
  DynamicblogpageItems(filter_query: $filter, per_page: 100, starts_with: $prefix) {
    total
    items {
      id
      slug
      full_slug
      translated_slugs {
        lang
        path
      }
      default_full_slug
      content {
        title
        shortDescription
        category {
          content
          uuid
        }
        previewImage {
          ...ImageFragment
        }
      }
      translated_slugs {
        lang
        path
      }
    }
  }
}
    ${ImageFragmentFragmentDoc}`;
export const CallToActionDocument = gql`
    query callToAction($slug: ID!, $lang: String) {
  CalltoactionItem(id: $slug, language: $lang, resolve_links: "url") {
    content {
      _editable
      blocks
      component
      title
    }
    uuid
  }
}
    `;
export const CocktailDocument = gql`
    query cocktail($id: ID!, $lang: String) {
  CocktailItem(id: $id, resolve_links: "url") {
    id
    slug
    uuid
    name
    published_at
    content {
      _editable
      _uid
      backgroundColor
      component
      description
      image {
        ...ImageFragment
      }
      title
      glass
      glass_icon
      flavour
      recipe
      visual
      products
      featured_products_title
      featured_products_description
      related(language: $lang) {
        content
        slug
        fullSlug
      }
      related_cocktails_title
      instagramSection(language: $lang) {
        content
      }
      callToAction(language: $lang) {
        content
        id
      }
      seo
      rowUrl {
        cachedUrl
        story {
          fullSlug
        }
        linktype
      }
    }
    translated_slugs {
      lang
      path
    }
    default_full_slug
    full_slug
  }
}
    ${ImageFragmentFragmentDoc}`;
export const CocktailItemsDocument = gql`
    query cocktailItems($prefix: String = "", $sort_by: String, $search_term: String = "", $page: Int = 1) {
  CocktailItems(
    starts_with: $prefix
    sort_by: $sort_by
    search_term: $search_term
    page: $page
  ) {
    items {
      id
      slug
      full_slug
      content {
        description
        title
        backgroundColor
        image {
          alt
          title
          name
          copyright
          filename
          focus
          id
        }
        _editable
        _uid
        rowUrl {
          cachedUrl
          story {
            fullSlug
          }
          linktype
        }
      }
      uuid
      created_at
      translated_slugs {
        lang
        path
      }
    }
    total
  }
}
    `;
export const CocktailsOverviewDocument = gql`
    query cocktailsOverview($slug: ID!) {
  CocktailoverviewItem(id: $slug) {
    id
    uuid
    name
    content {
      title
      subtitle
      seo
      _uid
      _editable
    }
    default_full_slug
    translated_slugs {
      lang
      path
    }
  }
}
    `;
export const CollectionItemDocument = gql`
    query collectionItem($id: ID!) {
  CollectionItem(id: $id) {
    content {
      image {
        ...ImageFragment
      }
      header
      title
      _uid
      collection
      isHidden
      hidesFilters
      seo
      _editable
    }
    uuid
    translated_slugs {
      lang
      path
    }
    default_full_slug
    full_slug
  }
}
    ${ImageFragmentFragmentDoc}`;
export const CollectionItemsDocument = gql`
    query collectionItems($prefix: String = "", $filter: JsonScalar) {
  CollectionItems(
    starts_with: $prefix
    filter_query: $filter
    sort_by: "content.order:asc"
  ) {
    items {
      content {
        image {
          ...ImageFragment
        }
        header
        title
        _uid
        collection
        isHidden
        hidesFilters
      }
      uuid
      slug
      full_slug
      translated_slugs {
        lang
        path
      }
    }
    total
  }
}
    ${ImageFragmentFragmentDoc}`;
export const DynamicPagesDocument = gql`
    query dynamicPages($filter: JsonScalar, $prefix: String = "") {
  DynamicpageItems(filter_query: $filter, per_page: 100, starts_with: $prefix) {
    items {
      slug
      full_slug
      published_at
      uuid
      translated_slugs {
        lang
        path
      }
    }
  }
}
    `;
export const FiltersMappingDocument = gql`
    query filtersMapping {
  FiltersItem(id: "shop/filters") {
    content {
      taste
      _editable
    }
    uuid
  }
}
    `;
export const FooterDocument = gql`
    query footer($slug: ID!) {
  FooterItem(id: $slug, resolve_links: "url") {
    published_at
    content {
      footer_columns
      bottom_links
      _editable
    }
    uuid
  }
}
    `;
export const HomeDocument = gql`
    query home($slug: ID!, $lang: String) {
  HomeItem(id: $slug, resolve_links: "url") {
    content {
      _editable
      header_title
      header_description
      header_button_link {
        url
        story {
          fullSlug
        }
      }
      header_button_text
      header_visual
      gallery_images
      gallery_title
      gallery_link_url {
        story {
          fullSlug
        }
        url
      }
      collection_title
      gallery_link_label
      cocktails_title
      cocktails_list(language: $lang) {
        content
        slug
        fullSlug
      }
      instagram(language: $lang) {
        content
      }
      callToAction(language: $lang) {
        id
        content
      }
      seo
    }
    uuid
  }
}
    `;
export const InstagramDocument = gql`
    query instagram($id: ID!) {
  InstagramItem(id: $id) {
    content {
      title
      description
      photos
      ctaText
      ctaUrl
      _editable
    }
    uuid
  }
}
    `;
export const ModalDocument = gql`
    query modal($slug: ID!) {
  ModalItem(id: $slug) {
    content {
      title
      description
      confirmButton
      cancelButton
      _editable
    }
    uuid
  }
}
    `;
export const MomentsDocument = gql`
    query moments($slug: ID!, $lang: String = "default") {
  MomentsoverviewItem(id: $slug, resolve_links: "url") {
    content {
      _editable
      title
      description
      moments
      instagramSection(language: $lang) {
        content
      }
      callToAction(language: $lang) {
        content
        id
      }
      seo
    }
    uuid
    translated_slugs {
      lang
      path
    }
    default_full_slug
  }
}
    `;
export const NavigationDocument = gql`
    query navigation($slug: ID!) {
  NavigationItem(id: $slug, resolve_links: "url") {
    published_at
    content {
      links
      pre_header
      mobile_links
      cart_hint
      _editable
    }
    uuid
  }
}
    `;
export const PageByIdDocument = gql`
    query pageById($id: ID!, $lang: String = "default") {
  DynamicpageItem(
    id: $id
    resolve_relations: "contact_form_dynamic.form,event_form_dynamic.form,products_dynamic.shopify_products,cocktails_dynamic.cocktails,cta_dynamic.call_to_action,course_form_dynamic.form,elearning_form_dynamic.form"
    resolve_links: "url"
  ) {
    content {
      sections
      instagramSection(language: $lang) {
        content
      }
      callToAction(language: $lang) {
        content
        id
      }
      noIndex
      seo
      _editable
    }
    uuid
    translated_slugs {
      lang
      path
    }
    full_slug
    default_full_slug
  }
}
    `;
export const PaymentsPageDocument = gql`
    query paymentsPage($slug: ID!, $lang: String = "default") {
  PaymentItem(id: $slug, resolve_links: "url") {
    content {
      _editable
      succeeded_title
      pending_title
      failed_title
      succeeded_description
      failed_description
      pending_description
      instagramSection(language: $lang) {
        content
      }
      callToAction(language: $lang) {
        content
        id
      }
    }
    uuid
  }
}
    `;
export const ProductDocument = gql`
    query product($id: String!, $prefix: String!, $lang: String = "default") {
  ProductItems(search_term: $id, starts_with: $prefix) {
    items {
      content {
        _editable
        products
        related_title
        more_info_title
        more_info_text
        image_section_title
        image_section_text
        image_section_image {
          ...ImageFragment
        }
        component
        background_color
        instagramSection(language: $lang) {
          content
        }
      }
      uuid
      slug
    }
  }
}
    ${ImageFragmentFragmentDoc}`;
export const ProductByIdDocument = gql`
    query productById($id: ID!, $lang: String = "default") {
  ProductItem(id: $id) {
    content {
      _editable
      products
      related_title
      more_info_title
      more_info_text
      image_section_title
      image_section_text
      image_section_image {
        ...ImageFragment
      }
      component
      background_color
      instagramSection(language: $lang) {
        content
      }
    }
    uuid
    slug
  }
}
    ${ImageFragmentFragmentDoc}`;
export const ShopAllDocument = gql`
    query shopAll($slug: ID!) {
  PlpItem(id: $slug) {
    id
    name
    slug
    uuid
    content {
      items
      subtitle
      title
      hidesFilters
      collections
      seo
      _editable
      _uid
    }
  }
}
    `;
export const RegisterPageDocument = gql`
    query registerPage($slug: ID!, $lang: String = "default") {
  RegisterpageItem(id: $slug) {
    content {
      _editable
      termsAndConditions(language: $lang) {
        content
      }
      seo
    }
    uuid
  }
}
    `;
export const SearchDocument = gql`
    query search($slug: ID!) {
  SearchpageItem(id: $slug) {
    id
    uuid
    name
    content {
      seo
      _uid
      _editable
    }
    translated_slugs {
      lang
      path
    }
    default_full_slug
  }
}
    `;
export const SubcollectionDocument = gql`
    query subcollection($id: ID!) {
  SubcollectionItem(id: $id) {
    content {
      image {
        ...ImageFragment
      }
      header
      title
      _uid
      collection
      isHidden
      hidesFilters
      seo
      _editable
    }
    uuid
    slug
    translated_slugs {
      lang
      path
    }
    default_full_slug
  }
}
    ${ImageFragmentFragmentDoc}`;
export const SubcollectionsDocument = gql`
    query subcollections($prefix: String!, $filter: JsonScalar) {
  SubcollectionItems(starts_with: $prefix, filter_query: $filter) {
    items {
      content {
        image {
          ...ImageFragment
        }
        header
        title
        _uid
        collection
        isHidden
        hidesFilters
        _editable
      }
      uuid
      slug
      full_slug
      translated_slugs {
        lang
        path
      }
    }
    total
  }
}
    ${ImageFragmentFragmentDoc}`;
export const TermsAndConditionsItemsDocument = gql`
    query termsAndConditionsItems {
  TermsandconditionsItems {
    items {
      content {
        termsAndConditions
        _uid
        _editable
      }
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    allSubcollections(variables?: AllSubcollectionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AllSubcollectionsQuery> {
      return withWrapper(() => client.request<AllSubcollectionsQuery>(AllSubcollectionsDocument, variables, requestHeaders));
    },
    authPage(variables: AuthPageQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AuthPageQuery> {
      return withWrapper(() => client.request<AuthPageQuery>(AuthPageDocument, variables, requestHeaders));
    },
    blog(variables: BlogQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<BlogQuery> {
      return withWrapper(() => client.request<BlogQuery>(BlogDocument, variables, requestHeaders));
    },
    blogPost(variables: BlogPostQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<BlogPostQuery> {
      return withWrapper(() => client.request<BlogPostQuery>(BlogPostDocument, variables, requestHeaders));
    },
    blogPosts(variables?: BlogPostsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<BlogPostsQuery> {
      return withWrapper(() => client.request<BlogPostsQuery>(BlogPostsDocument, variables, requestHeaders));
    },
    callToAction(variables: CallToActionQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CallToActionQuery> {
      return withWrapper(() => client.request<CallToActionQuery>(CallToActionDocument, variables, requestHeaders));
    },
    cocktail(variables: CocktailQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CocktailQuery> {
      return withWrapper(() => client.request<CocktailQuery>(CocktailDocument, variables, requestHeaders));
    },
    cocktailItems(variables?: CocktailItemsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CocktailItemsQuery> {
      return withWrapper(() => client.request<CocktailItemsQuery>(CocktailItemsDocument, variables, requestHeaders));
    },
    cocktailsOverview(variables: CocktailsOverviewQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CocktailsOverviewQuery> {
      return withWrapper(() => client.request<CocktailsOverviewQuery>(CocktailsOverviewDocument, variables, requestHeaders));
    },
    collectionItem(variables: CollectionItemQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CollectionItemQuery> {
      return withWrapper(() => client.request<CollectionItemQuery>(CollectionItemDocument, variables, requestHeaders));
    },
    collectionItems(variables?: CollectionItemsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CollectionItemsQuery> {
      return withWrapper(() => client.request<CollectionItemsQuery>(CollectionItemsDocument, variables, requestHeaders));
    },
    dynamicPages(variables?: DynamicPagesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DynamicPagesQuery> {
      return withWrapper(() => client.request<DynamicPagesQuery>(DynamicPagesDocument, variables, requestHeaders));
    },
    filtersMapping(variables?: FiltersMappingQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FiltersMappingQuery> {
      return withWrapper(() => client.request<FiltersMappingQuery>(FiltersMappingDocument, variables, requestHeaders));
    },
    footer(variables: FooterQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FooterQuery> {
      return withWrapper(() => client.request<FooterQuery>(FooterDocument, variables, requestHeaders));
    },
    home(variables: HomeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<HomeQuery> {
      return withWrapper(() => client.request<HomeQuery>(HomeDocument, variables, requestHeaders));
    },
    instagram(variables: InstagramQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<InstagramQuery> {
      return withWrapper(() => client.request<InstagramQuery>(InstagramDocument, variables, requestHeaders));
    },
    modal(variables: ModalQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ModalQuery> {
      return withWrapper(() => client.request<ModalQuery>(ModalDocument, variables, requestHeaders));
    },
    moments(variables: MomentsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MomentsQuery> {
      return withWrapper(() => client.request<MomentsQuery>(MomentsDocument, variables, requestHeaders));
    },
    navigation(variables: NavigationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<NavigationQuery> {
      return withWrapper(() => client.request<NavigationQuery>(NavigationDocument, variables, requestHeaders));
    },
    pageById(variables: PageByIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PageByIdQuery> {
      return withWrapper(() => client.request<PageByIdQuery>(PageByIdDocument, variables, requestHeaders));
    },
    paymentsPage(variables: PaymentsPageQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PaymentsPageQuery> {
      return withWrapper(() => client.request<PaymentsPageQuery>(PaymentsPageDocument, variables, requestHeaders));
    },
    product(variables: ProductQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ProductQuery> {
      return withWrapper(() => client.request<ProductQuery>(ProductDocument, variables, requestHeaders));
    },
    productById(variables: ProductByIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ProductByIdQuery> {
      return withWrapper(() => client.request<ProductByIdQuery>(ProductByIdDocument, variables, requestHeaders));
    },
    shopAll(variables: ShopAllQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ShopAllQuery> {
      return withWrapper(() => client.request<ShopAllQuery>(ShopAllDocument, variables, requestHeaders));
    },
    registerPage(variables: RegisterPageQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RegisterPageQuery> {
      return withWrapper(() => client.request<RegisterPageQuery>(RegisterPageDocument, variables, requestHeaders));
    },
    search(variables: SearchQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SearchQuery> {
      return withWrapper(() => client.request<SearchQuery>(SearchDocument, variables, requestHeaders));
    },
    subcollection(variables: SubcollectionQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SubcollectionQuery> {
      return withWrapper(() => client.request<SubcollectionQuery>(SubcollectionDocument, variables, requestHeaders));
    },
    subcollections(variables: SubcollectionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SubcollectionsQuery> {
      return withWrapper(() => client.request<SubcollectionsQuery>(SubcollectionsDocument, variables, requestHeaders));
    },
    termsAndConditionsItems(variables?: TermsAndConditionsItemsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TermsAndConditionsItemsQuery> {
      return withWrapper(() => client.request<TermsAndConditionsItemsQuery>(TermsAndConditionsItemsDocument, variables, requestHeaders));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;