import { Box, Icon, css } from '@storyofams/react-ui';
import styled from 'styled-components';

import { HeavyCheckmark } from '~components/common/Icons';
import { Text } from '~components/common/Text';
import theme from '~styles/theme';

const ProgressItem = styled(Text)`
  position: relative;
  margin-bottom: 32px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 100px;
    border: 4px solid ${(p) => p.theme.colors['grey300']};
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
  }
`;

export const Progress = ({
  steps,
  tickSize = 32,
  lineSize = 4,
  space = 4,
  colorPrimary = 'cocktails',
  colorNeutral = 'grey300',
  breakpoint = 'md',
  ...props
}) => {
  return (
    <Box {...props} display={{ [breakpoint]: 'flex' }}>
      {steps.map((step, i) => (
        <ProgressItem
          fontSize={1.5}
          css={css({
            height: tickSize,
            lineHeight: `${tickSize}px`,
            paddingLeft: tickSize + 24,
            marginBottom: space,
            flex: 1,

            '&:before': {
              width: tickSize,
              height: tickSize,
              backgroundColor: step.completed ? colorPrimary : colorNeutral,
            },
            '&:after': {
              left: tickSize / 2 - lineSize / 2,
              width: lineSize,
              height: i < steps?.length - 1 ? tickSize + 32 : 0,
              backgroundColor: steps[i + 1]?.completed
                ? colorPrimary
                : colorNeutral,
            },
            [`@media (min-width: ${theme.breakpoints[breakpoint]})`]: {
              marginBottom: 0,
              paddingTop: tickSize + 16,
              paddingRight: `${theme.space[2]}px`,
              paddingLeft: `${theme.space[2]}px`,
              height: 'auto',
              textAlign: 'center',
              lineHeight: 1.5,
              '&:after': {
                width: '100%',
                height: lineSize,
                top: tickSize / 2 - lineSize / 2,
                background: `linear-gradient(to right, ${
                  i === 0
                    ? 'transparent'
                    : step?.completed
                    ? theme.colors[colorPrimary]
                    : theme.colors[colorNeutral]
                } 50%, ${
                  i >= steps?.length - 1
                    ? 'transparent'
                    : step.completed
                    ? theme.colors[colorPrimary]
                    : theme.colors[colorNeutral]
                } 50%)`,
              },
              '&:before': {
                left: `calc(50% - ${tickSize / 2}px)`,
              },
            },
          })}
        >
          {step.completed && (
            <Icon
              icon={HeavyCheckmark}
              color="white"
              position="absolute"
              top={`${tickSize / 2 - 5}px`}
              left={{ all: `${tickSize / 2 - 6}px`, md: 'calc(50% - 6px)' }}
              width="12px"
              zIndex={2}
            />
          )}
          {step.label}
        </ProgressItem>
      ))}
    </Box>
  );
};
