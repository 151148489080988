import {
  getClient,
  getStaticPropsWithSdk,
} from '@storyofams/storyblok-toolkit';

import { getSdk } from './sdk';

export const version = () =>
  process.env.PASSWORD_PROTECT || process.env.NODE_ENV === 'development'
    ? 'draft'
    : 'published';

const client = getClient({
  version: version(),
  token: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
});

export const storyblokSdk = getSdk(client);

export const staticPropsWithSdk = getStaticPropsWithSdk(
  getSdk,
  client,
  process.env.STORYBLOK_PREVIEW_TOKEN,
);
