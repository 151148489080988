import React, { useEffect } from 'react';
import { Spinner } from '@storyofams/react-ui';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { useRouter } from 'next/router';

import { Seo } from '~components';
import { useShopify } from '~context';
import { getDisplayName } from '~lib/getDisplayName';

export function withAuth(Component: any) {
  const AuthComponent = (props) => {
    const { customer, customerIsLoading } = useShopify();
    const router = useRouter();

    useEffect(() => {
      if (!customer && !customerIsLoading) {
        router.replace({
          pathname: `/login`,
          query: {
            to: router.pathname,
            as: router.asPath,
          },
        });
      }
    }, [customer, customerIsLoading]);

    if (customerIsLoading || !customer) {
      return (
        <>
          <Seo noIndex />
          <Spinner minHeight="400px" color="cocktails" />
        </>
      );
    }

    return <Component {...props} />;
  };

  hoistNonReactStatics(AuthComponent, Component);

  AuthComponent.displayName = `withAuth(${getDisplayName(Component)})`;

  return AuthComponent;
}
