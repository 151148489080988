export const PreconnectCheckout = () => (
  <>
    {!!process.env.NEXT_PUBLIC_PRIMARY_DOMAIN && (
      <>
        <link rel="preconnect" href={process.env.NEXT_PUBLIC_PRIMARY_DOMAIN} />
        <link
          rel="dns-prefetch"
          href={process.env.NEXT_PUBLIC_PRIMARY_DOMAIN}
        />
        <link rel="preconnect" href="https://cdn.shopify.com" />
        <link rel="dns-prefetch" href="https://cdn.shopify.com" />
      </>
    )}
  </>
);
