export const getError = (err) => {
  const res = err.response;

  let error = String(err) ? String(err) : 'An unknown error has occurred.';
  if (res?.data?.error?.message) {
    error = res?.data?.error?.message;
  }

  if (error === 'Error: Network Error') {
    error = 'Could not connect to the server!';
  }

  return error;
};
