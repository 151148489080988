import { NextSeo, NextSeoProps } from 'next-seo';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { defineMessages, useIntl } from 'react-intl';

import { getDescription, getLocalizedUrl, getCanonicalUrl } from '~lib';

interface SeoProps extends NextSeoProps {
  story?: any;
  image?: string;
  ogType?: 'website' | 'article';
  noIndex?: boolean;
}

const messages = defineMessages({
  title: 'Bols - The world’s first cocktail brand',
});

export const Seo = ({
  story: storyProp,
  title: titleProp,
  description: descriptionProp,
  image: imageProp,
  ogType,
  noIndex,
  ...props
}: SeoProps) => {
  const router = useRouter();
  const intl = useIntl();
  const { defaultLocale, pathname, locale, locales } = router;

  let title = titleProp;
  let description = descriptionProp;
  let image = { url: imageProp, width: 1200, height: 627 };

  const story = storyProp?.content || storyProp;

  if (story) {
    if (!title) {
      title = story.seo?.title || story.title;
    }
    if (!description) {
      description = getDescription(story);
    }
    if (!imageProp) {
      image = {
        url: story?.seo?.og_image || story?.image?.filename,
        width: 1200,
        height: 627,
      };
    }
  }

  const isHome = locales
    .map((l) => `/${defaultLocale !== l ? `${l}/` : ''}`)
    .includes(pathname);

  if (title && isHome) {
    title = `Bols - ${title}`;
  } else {
    title = title ?? intl.formatMessage(messages.title);
  }

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        canonical={getCanonicalUrl(router, locale, storyProp)}
        noindex={story?.noIndex === true || noIndex}
        openGraph={{
          images: [image],
          type: ogType || 'website',
          locale,
          site_name: 'Bols',
        }}
        languageAlternates={
          process.env.NEXT_PUBLIC_STORE_COUNTRY === 'nl'
            ? [
                {
                  hrefLang: 'x-default',
                  href: getLocalizedUrl(router, defaultLocale, storyProp),
                },
                ...locales?.map((hrefLang) => ({
                  hrefLang,
                  href: getLocalizedUrl(router, hrefLang, storyProp),
                })),
              ]
            : undefined
        }
        {...props}
      />
      <Head>
        {process.env.NEXT_PUBLIC_STORE_COUNTRY === 'nl' &&
          locales
            ?.filter((l) => l !== defaultLocale)
            ?.map((content) => (
              <meta
                key={content}
                property="og:locale:alternate"
                content={content}
              />
            ))}
      </Head>
    </>
  );
};
