import { FC } from 'react';
import { Box, css, SystemProps } from '@storyofams/react-ui';
import {
  render,
  MARK_BOLD,
  MARK_LINK,
  NODE_PARAGRAPH,
  NODE_HEADING,
  NODE_OL,
  NODE_UL,
} from 'storyblok-rich-text-react-renderer';

import { Heading } from '../Heading';

interface RichTextProps extends SystemProps {
  text: any;
  markResolvers?: any;
  nodeResolvers?: any;
}

export const RichText: FC<RichTextProps> = ({
  markResolvers = {},
  nodeResolvers = {},
  text,
  ...props
}) => (
  <Box
    fontSize={2}
    lineHeight="140%"
    color="grey900"
    css={css({ '> *:last-child': { pb: 0 } })}
    {...props}
  >
    {render(text, {
      markResolvers: {
        [MARK_BOLD]: (children) => <b>{children}</b>,
        [MARK_LINK]: (children, props) => {
          const { href, linktype } = props;
          return (
            <Box textDecoration="underline" as="span">
              <a
                href={linktype === 'email' ? `mailto:${href}` : href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {children}
              </a>
            </Box>
          );
        },
        ...markResolvers,
      },
      nodeResolvers: {
        [NODE_HEADING]: (children, { level }) => (
          <Heading as={`h${level}` as any} pb={[2, 3]} color="grey900">
            {children}
          </Heading>
        ),
        [NODE_PARAGRAPH]: (children) => (
          <Box pb={[2, 3]} as="p">
            {children}
          </Box>
        ),
        [NODE_OL]: (children) => (
          <Box as="ol" pl={2} pb={[2, 3]}>
            {children}
          </Box>
        ),
        [NODE_UL]: (children) => (
          <Box as="ul" pl={2} pb={[2, 3]}>
            {children}
          </Box>
        ),
        ...nodeResolvers,
      },
    })}
  </Box>
);
