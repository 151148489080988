export const getLinkProps = (link: any) => {
  if (link?.linktype === 'url') {
    return { href: link?.url, target: '_blank', rel: 'noopener noreferrer' };
  }

  if (link?.linktype === 'story') {
    return { to: `/${link.story?.url}` };
  }

  return link?.cached_url;
};
