import { ReactNode, useEffect, useRef, useState } from 'react';
import { Flex, Icon } from '@storyofams/react-ui';
import { m, AnimatePresence } from 'framer-motion';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { defineMessages, FormattedMessage } from 'react-intl';
import { RemoveScroll } from 'react-remove-scroll';

import { Button } from '~components/common/Button';
import { CampaignLoader } from '~components/common/CampaignLoader';
import { Heading } from '~components/common/Heading';
import { ConsentLogo } from '~components/common/Icons';
import { PageLoader } from '~components/common/PageLoader';
import { Text } from '~components/common/Text';

interface AgeConsentProviderProps {
  children: ReactNode;
}
const messages = defineMessages({
  ageConsentTitle:
    'This is your first step into the magical world of cocktails.',
  ageConsentDescription:
    'To enter, please confirm that you are of legal drinking age in your country.',
  confirmAge: 'I hereby confirm',
});

const LOCAL_STORAGE_AGE_CONSENT = 'age_consent';

const Wrapper = m(Flex);

export const AgeConsentProvider = ({ children }: AgeConsentProviderProps) => {
  const router = useRouter();
  const [consentState, setConsentState] = useState(null);
  const shouldPlay = useRef(
    router?.asPath === '/' || router?.asPath?.startsWith('/?country-switch='),
  );
  const isCampaignPage = useRef(router?.asPath === '/ready-to-enjoy');

  const setConsent = () => {
    const consentDateStr = new Date().toISOString();
    Cookies.set(LOCAL_STORAGE_AGE_CONSENT, consentDateStr);
    setConsentState(consentDateStr);
  };

  useEffect(() => {
    const consent = Cookies.get(LOCAL_STORAGE_AGE_CONSENT);
    setConsentState(consent ?? false);
  }, []);

  return (
    <>
      <AnimatePresence>
        {consentState === null && (
          <Wrapper
            position="fixed"
            top={0}
            bottom={0}
            left={0}
            right={0}
            zIndex={99999999}
            bg="white"
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              ease: [0.87, 0, 0.13, 1],
              duration: 0.5,
            }}
          />
        )}
        {consentState !== null && !consentState && (
          <RemoveScroll>
            <Wrapper
              position="fixed"
              top={0}
              bottom={0}
              left={0}
              right={0}
              zIndex={99999999}
              bg="grey100"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              textAlign="center"
              p={4}
              overflowY="auto"
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: [0.87, 0, 0.13, 1], duration: 0.5 }}
            >
              <Icon
                icon={<ConsentLogo width="100%" height="100%" />}
                width={['114px', '122px']}
                height={['104px', '112px']}
              />
              <Heading variant="h1" mt={4} mb={0} maxWidth="700px">
                <FormattedMessage {...messages.ageConsentTitle} />
              </Heading>
              <Text mt={2} mb={0} maxWidth="700px">
                <FormattedMessage {...messages.ageConsentDescription} />
              </Text>
              <Button mt={5} onClick={setConsent}>
                <FormattedMessage {...messages.confirmAge} />
              </Button>
            </Wrapper>
          </RemoveScroll>
        )}
      </AnimatePresence>

      {children}

      {consentState !== null && consentState && shouldPlay.current && (
        <PageLoader />
      )}
      {consentState !== null && consentState && isCampaignPage.current && (
        <CampaignLoader hasConsent={consentState} />
      )}
    </>
  );
};

export default AgeConsentProvider;
