import {
  FC,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from 'react';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useIntl, defineMessages } from 'react-intl';
import { useQuery, useQueryClient } from 'react-query';
import { useToggle } from 'react-use';

import { showToast, showPersistentToast } from '~lib';
import { track } from '~lib/gtag';
import { getShopifySdk } from '~lib/shopify/client';
import {
  CheckoutFragmentFragment,
  CheckoutLineItemInput,
  CustomerAccessTokenCreateInput,
  CustomerCreateInput,
  CustomerQuery,
  CustomerResetInput,
  CustomerUpdateInput,
  MailingAddressInput,
  CustomerActivateInput,
  PaymentProvidersQuery,
  CheckoutAttributesUpdateV2Input,
} from '~lib/shopify/sdk';
import { decodeShopifyId } from '~lib/shopify/utils';

const LOCAL_STORAGE_CHECKOUT_ID_KEY = 'checkoutId';
const LOCAL_STORAGE_ACCESS_TOKEN_KEY = 'accessToken';

export const alertMessage = 'Something went wrong';

const messages = defineMessages({
  alertMessage: 'Something went wrong',
  loginFail: 'Invalid login credentials',
  passwordForgotSuccess: 'An email has been sent',
  passwordResetSuccess: 'Password was reset',
  activateSuccess: 'Account activated!',
});

export interface ShopifyContextType {
  accessToken?: string;
  // Checkout
  checkout: CheckoutFragmentFragment;
  updatingCart: boolean;
  addItemToCart: (
    lineItems: CheckoutLineItemInput[],
    gtagData?: Record<string, any>,
  ) => Promise<void>;
  adjustLineItemQuantity: (lineItem: CheckoutLineItemInput) => void;
  removeLineItem: (id: string) => Promise<void>;
  removeCartContents: () => Promise<void>;
  showCartOverlay: boolean;
  toggleCartOverlay: (nextValue?: boolean) => void;
  checkoutIsDisabled: boolean;
  checkoutIsLoading: boolean;
  paymentProviders: PaymentProvidersQuery['shop']['paymentSettings'];
  isShippingAlcohol: boolean;
  country?: string;
  updateCartAttributes: (
    input: CheckoutAttributesUpdateV2Input,
  ) => Promise<void>;
  // Authentication
  login: (credentials: CustomerAccessTokenCreateInput) => Promise<boolean>;
  signUp: (
    customer: CustomerCreateInput,
  ) => Promise<{ disableForm: boolean } | boolean>;
  passwordRecover: (arg: { email: string }) => Promise<void>;
  passwordReset: (args: {
    id: string;
    input: CustomerResetInput;
  }) => Promise<void>;
  logout: () => Promise<void>;
  // Customer
  customer?: CustomerQuery['customer'];
  customerIsLoading: boolean;
  customerUpdate: (customer: CustomerUpdateInput) => Promise<boolean>;
  customerAddressCreate: (address: MailingAddressInput) => Promise<boolean>;
  customerAddressUpdate: (
    id: string,
    address: MailingAddressInput & { isDefault?: boolean },
  ) => Promise<boolean>;
  customerDefaultAddressUpdate: (addressId: string) => Promise<boolean>;
  customerAddressDelete: (id: string) => Promise<boolean>;
  customerActivate: (args: {
    id: string;
    input: CustomerActivateInput;
  }) => Promise<boolean>;
  customerNote: any;
  setCustomerNote: (note: string) => void;
}

export const ShopifyContext = createContext<ShopifyContextType>(undefined);

export const ShopifyProvider: FC = ({ children }) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const intl = useIntl();

  const [checkoutId, setCheckoutIdState] = useState('');
  const [isShippingAlcohol, setIsShippingAlcohol] = useState(false);
  const [country, setCountry] = useState('');
  const [checkoutIdInitialized, setCheckoutIdInitialized] = useState(false);
  const [updatingCart, setUpdatingCart] = useState(false);
  const [accessToken, setAccessTokenState] = useState('');
  const [hasSetAccessToken, setHasSetAccessToken] = useState(false);
  const [paymentProviders, setProviders] = useState(null);

  const [showCartOverlay, toggleCartOverlay] = useToggle(false);

  const [customerNote, setCustomerNote] = useState(null);

  const shopifySdk = useMemo(
    () => getShopifySdk(router?.locale),
    [router?.locale],
  );

  const {
    data: customer,
    refetch: refetchCustomer,
    status,
  } = useQuery(
    ['customer', accessToken],
    async () =>
      shopifySdk.customer({ token: accessToken }).then((res) => res.customer),
    {
      enabled: Boolean(accessToken),
    },
  );
  const customerIsLoading =
    (!accessToken && !hasSetAccessToken && status === 'idle') ||
    status === 'loading';

  const checkoutIsDisabled = !checkoutId;
  const { data: checkout, isLoading: checkoutIsLoading } = useQuery(
    ['checkout', checkoutId],
    async () =>
      shopifySdk
        .checkout({ id: checkoutId })
        .then(({ node }) => node as CheckoutFragmentFragment),
    {
      enabled: !checkoutIsDisabled,
    },
  );

  const setAccessToken = (token: string) => {
    localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, token);
    setAccessTokenState(token);
  };

  const setCheckoutId = (id: string) => {
    localStorage.setItem(LOCAL_STORAGE_CHECKOUT_ID_KEY, id);
    setCheckoutIdState(id);
  };

  /**
   * If there is a checkoutId in localStorage, set it
   * If checkout is completed or invalid, unset the checkoutId
   * If there if an access token in localStorage, set it
   */
  useEffect(() => {
    setAccessToken(localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY));
    setHasSetAccessToken(true);

    const checkoutId = localStorage.getItem(LOCAL_STORAGE_CHECKOUT_ID_KEY);
    if (checkoutId) setCheckoutIdState(checkoutId);

    shopifySdk
      .paymentProviders()
      .then((res) => setProviders(res.shop.paymentSettings))
      .catch((err) => console.log(err));

    setCheckoutIdInitialized(true);

    axios
      .get(`/api/country`)
      .then((result) => {
        setCountry(result?.data?.country);
      })
      .catch(() => {});
  }, []);

  /**
   * If checkout is completed or invalid, unset the checkoutId
   */
  useEffect(() => {
    if (
      checkout === null ||
      checkout?.lineItems?.edges?.some(({ node }) => !node.variant) ||
      checkout?.completedAt
    ) {
      setCheckoutId('');
    }
  }, [checkout]);

  /**
   * If there is a logged in customer and a checkout, associate the checkout with the customer
   */
  useEffect(() => {
    if (
      customer &&
      checkout &&
      !checkout.completedAt &&
      customer?.lastIncompleteCheckout?.id !== checkout.id
    ) {
      shopifySdk.associateCustomerWithCheckout({
        checkoutId: checkout.id,
        customerAccessToken: accessToken,
      });
    }
  }, [customer, checkout]);

  /**
   * If there is a customer and no checkout, check if the customer has an incomplete checkout. If so, set it
   */
  useEffect(() => {
    if (
      customer &&
      !checkoutId &&
      customer.lastIncompleteCheckout &&
      /* This check is needed to prevent a loop between
        this effect and the effect that unsets the checkoutId */
      !customer.lastIncompleteCheckout?.lineItems?.edges?.some(
        ({ node }) => !node.variant,
      )
    ) {
      setCheckoutId(customer.lastIncompleteCheckout.id);
    }
  }, [customer, checkoutId]);

  // *************************
  // Checkout ****************
  // *************************

  const trackAddToCart = (
    lineItems: CheckoutLineItemInput[],
    checkoutData,
    extraData = {},
  ) => {
    lineItems.forEach(({ variantId, quantity }) => {
      const item = checkoutData?.lineItems?.edges?.find(
        ({ node }) => variantId === node.variant.id,
      );

      if (item?.node?.variant) {
        const dimension1 = item.node.variant.selectedOptions?.find(
          ({ name }) => name === 'Size',
        )?.value;

        track({
          event: 'addToCart',
          ecommerce: {
            value: `${item?.node?.variant?.priceV2?.amount}`,
            currencyCode: item?.node?.variant?.priceV2?.currencyCode,
            add: {
              ...extraData,
              products: [
                {
                  id: decodeShopifyId(item.node.variant.product?.id),
                  variant: item.node.variant.sku,
                  quantity: `${quantity}`,
                  name: item?.node?.title,
                  price: `${item?.node?.variant?.priceV2?.amount}`,
                  brand: 'BOLS',
                  ...(dimension1 ? { dimension1 } : {}),
                  category: item.node.variant.product?.tags
                    ?.find((tag) => tag?.startsWith('collection_'))
                    ?.replace('collection_', ''),
                },
              ],
            },
          },
        });
      }
    });
  };

  const addItemToCart = async (
    lineItems: CheckoutLineItemInput[],
    gtagData,
  ) => {
    try {
      setUpdatingCart(true);

      if (!checkoutId) {
        const { checkoutCreate } = await shopifySdk.checkoutCreate({
          input: { lineItems },
        });

        setCheckoutId(checkoutCreate.checkout.id);

        queryClient.setQueryData(
          ['checkout', checkoutId],
          checkoutCreate.checkout,
        );

        trackAddToCart(lineItems, checkoutCreate.checkout, gtagData);
      } else {
        const updatedLineItems: CheckoutLineItemInput[] =
          checkout?.lineItems?.edges?.reduce((acc, { node }) => {
            const changedItem = lineItems.find(
              (input) => input.variantId === node.variant.id,
            );
            if (!!changedItem) {
              return [
                ...acc.filter((e) => e.variantId !== changedItem.variantId),
                {
                  variantId: changedItem.variantId,
                  quantity: node.quantity + changedItem.quantity,
                },
              ];
            }
            return [
              ...acc,
              { variantId: node.variant.id, quantity: node.quantity },
            ];
          }, lineItems);

        const { checkoutLineItemsReplace } =
          await shopifySdk.checkoutLineItemsReplace({
            checkoutId,
            lineItems: updatedLineItems,
          });

        queryClient.setQueryData(
          ['checkout', checkoutId],
          checkoutLineItemsReplace.checkout,
        );

        if (
          checkoutLineItemsReplace?.checkout?.lineItems.edges?.some(
            ({ node }) =>
              node?.variant?.product?.containsAlcohol?.value === 'true',
          ) &&
          !isShippingAlcohol
        ) {
          setIsShippingAlcohol(true);
        }

        trackAddToCart(lineItems, checkoutLineItemsReplace.checkout, gtagData);
      }

      toggleCartOverlay(true);
    } catch (e) {
      console.warn(e);
      setCheckoutId('');
    } finally {
      setUpdatingCart(false);
    }
  };

  const adjustLineItemQuantity = async (lineItem: CheckoutLineItemInput) => {
    try {
      setUpdatingCart(true);

      if (!lineItem.quantity) {
        removeLineItem(lineItem.variantId);
        return;
      }

      const { checkoutLineItemsReplace } =
        await shopifySdk.checkoutLineItemsReplace({
          checkoutId,
          lineItems: checkout.lineItems.edges.map(({ node }) => {
            return node.variant?.id === lineItem.variantId
              ? lineItem
              : { variantId: node.variant.id, quantity: node.quantity };
          }),
        });

      queryClient.setQueryData(
        ['checkout', checkoutId],
        checkoutLineItemsReplace.checkout,
      );

      trackAddToCart([lineItem], checkoutLineItemsReplace.checkout);
    } catch (e) {
    } finally {
      setUpdatingCart(false);
    }
  };

  const removeLineItem = async (id: string) => {
    const lineItems: CheckoutLineItemInput[] = checkout.lineItems.edges
      ?.filter(({ node }) => node.variant.id !== id)
      .map(({ node }) => ({
        variantId: node.variant.id,
        quantity: node.quantity,
      }));

    const { checkoutLineItemsReplace } =
      await shopifySdk.checkoutLineItemsReplace({
        checkoutId,
        lineItems,
      });

    queryClient.setQueryData(
      ['checkout', checkoutId],
      checkoutLineItemsReplace.checkout,
    );
  };

  const removeCartContents = async () => {
    try {
      setUpdatingCart(true);
      const { checkoutCreate } = await shopifySdk.checkoutCreate({
        input: { lineItems: [] },
      });

      setCheckoutId(checkoutCreate.checkout.id);

      queryClient.setQueryData(
        ['checkout', checkoutId],
        checkoutCreate.checkout,
      );
    } catch (e) {
      console.warn(e);
      setCheckoutId('');
      showToast(
        intl.formatMessage(messages.alertMessage) || alertMessage,
        'error',
      );
    } finally {
      setUpdatingCart(false);
    }
  };

  const updateCartAttributes = async (input) => {
    const { checkoutAttributesUpdateV2 } =
      await shopifySdk.checkoutAttributesUpdate({
        id: checkoutId,
        input,
      });

    queryClient.setQueryData(
      ['checkout', checkoutId],
      checkoutAttributesUpdateV2.checkout,
    );
  };

  // *************************
  // Authentication **********
  // *************************

  const login = async (credentials: CustomerAccessTokenCreateInput) => {
    return shopifySdk
      .customerAccessTokenCreate(credentials)
      .then((res) => {
        const accessToken =
          res.customerAccessTokenCreate?.customerAccessToken?.accessToken;
        const error =
          res.customerAccessTokenCreate?.customerUserErrors?.[0]?.code;

        if (error) throw error;

        const fallbackUrl =
          process.env.NEXT_PUBLIC_STORE_COUNTRY === 'b2b'
            ? '/shop'
            : '/account';

        setAccessToken(accessToken);
        router.push(
          (router.query.to as string) || fallbackUrl,
          router.query.as as string,
        );
        return true;
      })
      .catch((err) => {
        showPersistentToast(intl.formatMessage(messages.loginFail), 'error');
        console.error(err);
        return false;
      });
  };

  const logout = async () => {
    const url =
      process.env.NEXT_PUBLIC_STORE_COUNTRY === 'b2b' ? '/login' : '/';

    if (!accessToken) {
      router.push(url);
      return;
    }

    return shopifySdk
      .customerAccessTokenDelete({ customerAccessToken: accessToken })
      .then((res) => {
        const error = res.customerAccessTokenDelete?.userErrors?.[0];

        if (error) throw error;

        setAccessToken('');
        router.push(url);
      })
      .catch((err) => {
        showToast(
          intl.formatMessage(messages.alertMessage) || alertMessage,
          'error',
        );
        setAccessToken('');
        router.push(url);
      });
  };

  const signUp = async (customer: CustomerCreateInput) => {
    return shopifySdk
      .customerCreate({ input: customer })
      .then(async (res) => {
        if (
          res.customerCreate?.customerUserErrors?.[0]?.code ===
          'CUSTOMER_DISABLED'
        ) {
          showToast(
            res.customerCreate.customerUserErrors[0].message,
            'success',
          );
          return { disableForm: true };
        }
        if (res.customerCreate?.customerUserErrors?.[0]?.message) {
          throw res.customerCreate.customerUserErrors[0].message;
        }

        return await login(customer);
      })
      .catch((err) => {
        showToast(err, 'error');
        return { disableForm: false };
      });
  };

  const passwordRecover = async ({ email }: { email: string }) => {
    return shopifySdk
      .customerRecover({ email })
      .then(async (res) => {
        if (res.customerRecover?.customerUserErrors?.[0]?.message) {
          throw res.customerRecover.customerUserErrors[0].message;
        }

        showPersistentToast(
          intl.formatMessage(messages.passwordForgotSuccess),
          'success',
        );
      })
      .catch((err) => {
        showToast(err, 'error');
      });
  };

  const passwordReset = async ({
    id,
    input,
  }: {
    id: string;
    input: CustomerResetInput;
  }) => {
    return shopifySdk
      .customerReset({ id, input })
      .then(async (res) => {
        if (res.customerReset?.customerUserErrors?.[0]?.message) {
          throw res.customerReset.customerUserErrors[0].message;
        }

        setAccessToken(res.customerReset?.customerAccessToken?.accessToken);

        showToast(intl.formatMessage(messages.passwordResetSuccess), 'success');
        router.push('/account');
      })
      .catch((err) => {
        showToast(err.message, 'error');
        console.error(err);
      });
  };

  // *************************
  // Customer ****************
  // *************************

  const customerUpdate = (customer: CustomerUpdateInput) => {
    return shopifySdk
      .customerUpdate({ customerAccessToken: accessToken, customer })
      .then((res) => {
        if (res.customerUpdate.customerUserErrors?.length)
          throw res.customerUpdate.customerUserErrors[0].code;

        queryClient.setQueryData(
          ['customer', accessToken],
          res.customerUpdate.customer,
        );

        const resAccessToken =
          res.customerUpdate?.customerAccessToken?.accessToken;

        if (resAccessToken && resAccessToken !== accessToken) {
          setAccessToken(resAccessToken);
        }

        return true;
      })
      .catch((err) => {
        showToast(
          intl.formatMessage(messages.alertMessage) || alertMessage,
          'error',
        );
        return false;
      });
  };

  const customerAddressCreate = (
    address: MailingAddressInput & { isDefault?: boolean },
  ) => {
    const { isDefault, ...input } = address;

    return shopifySdk
      .customerAddressCreate({
        customerAccessToken: accessToken,
        address: input,
      })
      .then((res) => {
        if (res.customerAddressCreate.customerUserErrors?.length) {
          throw res.customerAddressCreate.customerUserErrors;
        }

        return res;
      })
      .then((res) => {
        if (isDefault) {
          customerDefaultAddressUpdate(
            res.customerAddressCreate.customerAddress.id,
          );
        }

        refetchCustomer();

        return true;
      })
      .catch((err) => {
        showToast(
          intl.formatMessage(messages.alertMessage) || alertMessage,
          'error',
        );
        console.error(err);
        return false;
      });
  };

  const customerAddressUpdate = (
    id: string,
    address: MailingAddressInput & { isDefault?: boolean },
  ) => {
    const { isDefault, ...input } = address;

    return shopifySdk
      .customerAddressUpdate({
        customerAccessToken: accessToken,
        id,
        address: input,
      })
      .then((res) => {
        if (res.customerAddressUpdate.customerUserErrors?.length) {
          throw res.customerAddressUpdate.customerUserErrors;
        }

        if (isDefault && id !== customer?.defaultAddress?.id) {
          customerDefaultAddressUpdate(id);

          return true;
        }

        refetchCustomer();

        return true;
      })
      .catch((err) => {
        showToast(
          intl.formatMessage(messages.alertMessage) || alertMessage,
          'error',
        );
        return false;
      });
  };

  const customerDefaultAddressUpdate = (addressId: string) => {
    return shopifySdk
      .customerDefaultAddressUpdate({
        customerAccessToken: accessToken,
        addressId,
      })
      .then((res) => {
        if (res.customerDefaultAddressUpdate.customerUserErrors?.length) {
          throw res.customerDefaultAddressUpdate.customerUserErrors;
        }

        refetchCustomer();

        return true;
      })
      .catch((err) => {
        showToast(
          intl.formatMessage(messages.alertMessage) || alertMessage,
          'error',
        );
        return false;
      });
  };

  const customerAddressDelete = (id: string) => {
    return shopifySdk
      .customerAddressDelete({
        customerAccessToken: accessToken,
        id,
      })
      .then((res) => {
        if (res.customerAddressDelete.customerUserErrors?.length) {
          throw res.customerAddressDelete.customerUserErrors;
        }

        refetchCustomer();

        return true;
      })
      .catch((err) => {
        showToast(
          intl.formatMessage(messages.alertMessage) || alertMessage,
          'error',
        );
        console.error(err);
        return false;
      });
  };

  const customerActivate = async ({
    id,
    input,
  }: {
    id: string;
    input: CustomerActivateInput;
  }) => {
    return shopifySdk
      .customerActivate({ id, input })
      .then((res) => {
        if (res.customerActivate.customerUserErrors?.length) {
          throw res.customerActivate.customerUserErrors;
        }

        refetchCustomer();

        showToast(intl.formatMessage(messages.activateSuccess), 'success');
        router.push('/account');

        return true;
      })
      .catch((err) => {
        console.error(err);

        showToast(
          intl.formatMessage(messages.alertMessage) || alertMessage,
          'error',
        );
        return false;
      });
  };

  return (
    <ShopifyContext.Provider
      value={{
        accessToken,
        // Checkout
        addItemToCart,
        removeLineItem,
        removeCartContents,
        adjustLineItemQuantity,
        updatingCart,
        checkout,
        showCartOverlay,
        toggleCartOverlay,
        checkoutIsLoading,
        checkoutIsDisabled: checkoutIdInitialized && checkoutIsDisabled,
        paymentProviders,
        isShippingAlcohol,
        country,
        // Authentication
        login,
        signUp,
        passwordRecover,
        passwordReset,
        logout,
        // Customer
        customer,
        customerIsLoading,
        customerUpdate,
        customerAddressCreate,
        customerAddressUpdate,
        customerDefaultAddressUpdate,
        customerAddressDelete,
        customerActivate,
        customerNote,
        setCustomerNote,
        updateCartAttributes,
      }}
    >
      {children}
    </ShopifyContext.Provider>
  );
};

export const useShopify = () => {
  const ctx = useContext(ShopifyContext);

  if (!ctx) {
    throw new Error(
      'Shopify context must be used within a shopify context provider',
    );
  }

  return ctx;
};
