import { ReactNode } from 'react';
import { Icon, Flex, SystemProps } from '@storyofams/react-ui';
import { User } from '~components/common/Icons';

// import { StyledDropdown } from './Dropdown';
import { NavigationButton } from './NavigationButton';
// import { NavigationLink } from './NavigationLink';

interface Props extends SystemProps {
  isLoggedIn?: boolean;
  children?: ReactNode;
}

export const ProfileButton = ({ isLoggedIn, children, ...props }: Props) => {
  return (
    <Flex
      mr={[0.5, 0.5, 1]}
      position="relative"
      css={{
        '&:hover': { '.show-on-hover': { opacity: 1 } },
      }}
      as="li"
    >
      <NavigationButton to={isLoggedIn ? '/account' : '/login'}>
        <Icon icon={User} fontSize={3} />
      </NavigationButton>

      {/* {isLoggedIn && (
        <StyledDropdown
          className="show-on-hover"
          as="ul"
          flexShrink={0}
          minWidth="200px"
          css={{ listStyle: 'none' }}
        >
          <Flex as="li" px={3} py={2} flexShrink={0}>
            <NavigationLink link="/account?tab=profile">
              My profile
            </NavigationLink>
          </Flex>

          <Flex as="li" px={3} py={2} flexShrink={0}>
            {children}
          </Flex>
        </StyledDropdown>
      )} */}
    </Flex>
  );
};
