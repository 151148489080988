import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export const usePagination = (totalItems, itemsPerPage = 10) => {
  const router = useRouter();
  const [page, setPage] = useState(1);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    const route = { query: { ...router.query, page: page } };
    router.push(route);
  }, [page]);

  const goToPreviousPage = () => setPage(page - 1);
  const goToNextPage = () => setPage(page + 1);

  const allPages = new Array(totalPages)
    .fill(1)
    .map((item, index) => index + 1);

  const getPages = () => {
    if (page < 5) {
      return [...allPages.slice(0, 5), '...', totalPages];
    }

    if (page >= totalPages - 3) {
      return [
        1,
        '...',
        ...allPages.slice(allPages?.length - 5, allPages?.length),
      ];
    }

    return [
      1,
      '...',
      page - 2,
      page - 1,
      page,
      page + 1,
      page + 2,
      '...',
      totalPages,
    ];
  };

  const paginationItems = totalPages <= 10 ? allPages : getPages();

  return {
    setPage,
    page,
    goToPreviousPage,
    goToNextPage,
    totalPages,
    paginationItems,
  };
};
