import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, css } from '@storyofams/react-ui';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { useIntl, defineMessages } from 'react-intl';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import * as Yup from 'yup';

import { showToast } from '~lib';
import { Button } from '~components/common/Button';
import { Heading } from '~components/common/Heading';
import { Input } from '~components/common/Input';
import { Modal } from '~components/common/Modal';
import { Text } from '~components/common/Text';
import { track } from '~lib/gtag';

import { storyblokSdk } from '~lib/storyblok/client';

const schema = Yup.object().shape({
  email: Yup.string().email().required(),
});

const messages = defineMessages({
  emailLabel: 'Email',
  emailPlaceholder: 'Your email address',
  title: 'Stay updated',
  description:
    'Get the newest cocktail recipes and the best deals directly serviced in your email inbox.',
  buttonText: 'Yes, please',
  success: 'Subscribed!',
});

const Container = styled(Box)`
  background: ${(p) => p.theme.colors.grey900} url('/images/background.svg');
  background-size: cover !important;
  flex: 2;
  padding-top: ${(p) => p.theme.space[10]}px;
  color: white;

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    background: ${(p) => p.theme.colors.grey900}
      url('/images/background-desktop.svg');
  }
`;

const GradientBox = styled(Box)`
  background: linear-gradient(
    180deg,
    rgba(1, 4, 6, 0) 0%,
    ${(p) => p.theme.colors.grey900} 100%
  );
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
`;

export const SubscriptionForm = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const intl = useIntl();
  const { locale: routerLocale, asPath } = useRouter();

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<{ email: string }>({
    resolver: yupResolver(schema),
  });

  const { data: modal } = useQuery(
    ['newsletter-modal', routerLocale],
    async () =>
      storyblokSdk
        .modal({
          slug: `${
            process.env.DEFAULT_LOCALE !== routerLocale
              ? `${routerLocale}/`
              : ''
          }settings/newsletter-modal`,
        })
        .then((res) => res.ModalItem),
    { refetchOnWindowFocus: false },
  );

  const onSubmit = (input) => {
    let contactType = 'Consumer';
    if (asPath.includes('/bartendingacademy')) {
      contactType = 'Bartender';
    } else if (asPath.includes('/cocktail-experience')) {
      contactType = 'HOB';
    }

    return axios
      .post(`/api/newsletter`, {
        email: input.email,
        sourceUrl: window.location.toString(),
        'Contact type': contactType,
      })
      .then((response) => {
        if (response.status !== 200) {
          showToast(response.statusText, 'error');
        } else {
          showToast(intl.formatMessage(messages.success), 'success');
          reset({});
          track({
            event: 'conversion',
            conversionType: 'newsletter',
          });
          setModalVisible(false);
        }
      })
      .catch((err) => showToast(err?.message, 'error'));
  };

  return (
    <Container>
      <GradientBox>
        <Box maxWidth="344px" textAlign="center">
          <Heading variant="h1" as="h1" mb={2} color="white">
            {intl.formatMessage(messages.title)}
          </Heading>
          <Text color="white" fontSize={[2, 2.25]} mb={3}>
            {intl.formatMessage(messages.description)}
          </Text>
          <Button
            variant="inverted"
            width={['calc(100% - 48px)', 'auto']}
            mb={10}
            mx="auto"
            css={css({
              '& div': { justifyContent: 'center' },
            })}
            onClick={() => setModalVisible(true)}
          >
            {intl.formatMessage(messages.buttonText)}
          </Button>
        </Box>
      </GradientBox>
      {!isEmpty(modal) && (
        <Modal
          isOpen={modalVisible}
          close={() => setModalVisible(false)}
          {...modal?.content}
        >
          <Box as="form" onSubmit={handleSubmit(onSubmit)} width="100%">
            <Input
              required
              type="email"
              label={intl.formatMessage(messages.emailLabel)}
              placeholder={intl.formatMessage(messages.emailPlaceholder)}
              {...register('email')}
              error={errors?.email?.message}
              mb={4}
            />
            <Button width="100%" mb={2} isLoading={isSubmitting} type="submit">
              {modal?.content?.confirmButton}
            </Button>
          </Box>
        </Modal>
      )}
    </Container>
  );
};
