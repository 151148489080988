import { useState } from 'react';
import { Box, Flex, Icon } from '@storyofams/react-ui';
import axios from 'axios';
import { useRouter } from 'next/router';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { Checkbox, Input } from '~components';
import { useShopify } from '~context';
import { formatCheckoutUrl } from '~lib';
import { Button } from '~components/common/Button';
import {
  Bag,
  Logo,
  Mastercard,
  Visa,
  AmericanExpress,
  ApplePay,
  GooglePay,
  Ideal,
  ShopPay,
  DinersClub,
  Discover,
  Jcb,
  GoogleWallet,
  Maestro,
  Bancontact,
} from '~components/common/Icons';
import { RichText } from '~components/common/RichText';
import { Text } from '~components/common/Text';
import { CardBrand, DigitalWallet } from '~lib/shopify/sdk';
import { shopifyToIntlPrice } from '~lib/shopify/utils';

import { Divider } from '../common/Divider';
import { CheckoutLineItem } from './CheckoutLineItem';
import { PreconnectCheckout } from './PreconnectCheckout';

const icons = {
  [CardBrand.AmericanExpress]: AmericanExpress,
  [CardBrand.DinersClub]: DinersClub,
  [CardBrand.Discover]: Discover,
  [CardBrand.Jcb]: Jcb,
  [CardBrand.Mastercard]: Mastercard,
  [CardBrand.Visa]: Visa,
  [DigitalWallet.AndroidPay]: GoogleWallet,
  [DigitalWallet.ApplePay]: ApplePay,
  [DigitalWallet.GooglePay]: GooglePay,
  [DigitalWallet.ShopifyPay]: ShopPay,
};

const Card = ({ icon }: { icon: string }) => (
  <Flex
    width="48px"
    height="28px"
    borderRadius="4px"
    bg="grey400"
    alignItems="center"
    justifyContent="center"
    mb={1.5}
    mx={0.75}
  >
    <Icon icon={icon} color="white" fontSize={4} height="28px" />
  </Flex>
);

interface CartProps {
  hint?: string;
}

const messages = defineMessages({
  cartHint: 'Cart hint',
  websiteEncrypted:
    'Our website is 100% encrypted and your personal details are safe',
  emptyBagTitle: 'Your bag is empty',
  emptyBagDescription:
    'A miracle: almost unlimited ideas fit into this shopping bag. Try it out right away.',
  goShopping: 'Go Shopping',
  emptyCart: 'Empty cart',
  giftNotePlaceholder: 'Please enter your gift note here',
  giftNoteLabel: 'Gift note',
  isGift: 'This is a gift',
});

export const Cart = ({ hint }: CartProps) => {
  const { formatMessage } = useIntl();
  const { locale } = useRouter();
  const {
    accessToken,
    checkout,
    customer,
    paymentProviders,
    toggleCartOverlay,
    removeCartContents,
    customerNote,
    setCustomerNote,
    updateCartAttributes,
  } = useShopify();

  const [isOpeningCheckout, setOpeningCheckout] = useState(false);
  const [noteVisible, setNoteVisible] = useState(!!customerNote);

  const openCheckout = async (e) => {
    if (isOpeningCheckout) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    setOpeningCheckout(true);

    const checkoutUrl = formatCheckoutUrl(checkout.webUrl, locale);

    try {
      const {
        data: { redirectUrl },
      } = await axios.get('/api/multipassCheckout', {
        params: {
          accessToken,
          checkoutUrl,
        },
      });

      redirectToCheckout(!!redirectUrl ? redirectUrl : checkoutUrl);
    } catch {
      redirectToCheckout(checkoutUrl);
    }
  };

  const redirectToCheckout = async (redirectUrl) => {
    if (!!customerNote) {
      await updateCartAttributes({
        note: customerNote,
        customAttributes: [{ key: 'isGift', value: 'true' }],
      });
    }
    window.location.href = redirectUrl;
  };

  return checkout?.lineItems?.edges?.length ? (
    <Flex
      height="100%"
      flexDirection="column"
      py={[2, 3]}
      mx={['-16px', '-24px']}
    >
      <Flex flexDirection="column" px={[2, 3]} flex="1">
        {!!hint && (
          <Box borderRadius="sm" bg="grey100" p={2} mb={[4, 3]}>
            <Text mb={1.5} fontWeight="semiBold" lineHeight="100%">
              <FormattedMessage {...messages.cartHint} />
            </Text>

            <RichText color="grey500" text={hint} />
          </Box>
        )}

        <Flex flexDirection="column" flex={1}>
          {checkout?.lineItems?.edges?.map(({ node }) => (
            <CheckoutLineItem key={node.id} {...node} />
          ))}
        </Flex>

        <Flex justifyContent="center" flexWrap="wrap">
          {[
            ...paymentProviders?.acceptedCardBrands,
            ...paymentProviders?.supportedDigitalWallets,
          ]?.map((card) => (icons[card] ? <Card icon={icons[card]} /> : null))}
          <Card icon={Ideal} />
          <Card icon={Bancontact} />
          <Card icon={Maestro} />
        </Flex>
      </Flex>

      <Divider mt={1.5} mb={[2, 3]} />

      <Flex flexDirection="column" px={[2, 3]} pb={[2, 3]}>
        {process.env.NEXT_PUBLIC_STORE_COUNTRY === 'b2b' && (
          <Button
            width="100%"
            isLoading={isOpeningCheckout}
            onClick={removeCartContents}
            variant="outline"
            height="58px !important"
            mb={2}
          >
            <FormattedMessage {...messages.emptyCart} />
          </Button>
        )}

        {noteVisible ? (
          <Input
            placeholder={formatMessage(messages.giftNotePlaceholder)}
            label={formatMessage(messages.giftNoteLabel)}
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            mb={2}
            as="textarea"
            value={customerNote}
            onChange={(e) => setCustomerNote(e.target.value)}
          />
        ) : (
          <Checkbox
            mb={2}
            label={formatMessage(messages.isGift)}
            onChange={() => setNoteVisible(true)}
          />
        )}

        <Button
          width="100%"
          isLoading={isOpeningCheckout}
          onClick={openCheckout}
          variant="primary"
          height="58px !important"
          mb={2}
        >
          <FormattedMessage
            defaultMessage="Checkout - {totalPrice}"
            values={{ totalPrice: shopifyToIntlPrice(checkout?.totalPriceV2) }}
          />
        </Button>

        <Text
          variant="s"
          color="grey400"
          textAlign="center"
          maxWidth="300px"
          alignSelf="center"
        >
          <FormattedMessage {...messages.websiteEncrypted} />
        </Text>
      </Flex>

      <PreconnectCheckout />
    </Flex>
  ) : (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      px={[2, 3]}
    >
      <Flex
        borderRadius="full"
        width="200px"
        height="200px"
        position="relative"
        bg="grey100"
        alignItems="center"
        justifyContent="center"
        mb={6}
      >
        <Icon
          icon={Bag}
          fontSize={12}
          position="absolute"
          bottom="calc(50% - 48px)"
        />
        <Icon
          icon={Logo}
          bottom="calc(50% - 36px)"
          fontSize={6}
          position="absolute"
          zIndex={1}
          color="white"
        />
      </Flex>

      <Text fontWeight="bold" fontSize={2.25} mb={2}>
        <FormattedMessage {...messages.emptyBagTitle} />
      </Text>

      <Text color="grey500" mb={6} textAlign="center" maxWidth="340px">
        <FormattedMessage {...messages.emptyBagDescription} />
      </Text>

      <Button
        to="/shop"
        variant="primary"
        width="340px"
        onClick={() => {
          toggleCartOverlay(false);
        }}
      >
        <FormattedMessage {...messages.goShopping} />
      </Button>
    </Flex>
  );
};
