import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { Layout } from '~components';
import { getDisplayName } from '~lib/getDisplayName';

export function withLayout(Component: any) {
  const LayoutComponent = ({ footer, navigation, actionTicker, ...props }) => {
    let navigationBg = undefined;
    if (props?.product?.readyToEnjoy?.value === 'true') {
      navigationBg = props?.story?.content?.background_color?.color as any;
    }
    if (props?.story?.content?.component === 'Cocktail') {
      navigationBg = props?.story?.content?.backgroundColor?.color as any;
    }

    return (
      <Layout
        footer={footer}
        navigation={navigation}
        navigationBg={navigationBg}
        storySlugs={{
          default_full_slug: props?.story?.default_full_slug,
          translated_slugs: props?.story?.translated_slugs,
        }}
      >
        <Component {...props} />
      </Layout>
    );
  };

  hoistNonReactStatics(LayoutComponent, Component);

  LayoutComponent.displayName = `withLayout(${getDisplayName(Component)})`;

  return LayoutComponent;
}
