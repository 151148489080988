import { MoneyV2 } from '../sdk';

export const shopifyToIntlPrice = (moneyV2: MoneyV2) => {
  if (!moneyV2 || !moneyV2.currencyCode || !moneyV2.amount) {
    return '';
  }
  return Intl.NumberFormat('en-GB', {
    currency: moneyV2.currencyCode,
    currencyDisplay: 'symbol',
    style: 'currency',
  })
    .format(moneyV2.amount)
    ?.replace('US$', '$');
};
