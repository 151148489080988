import React from 'react';

import { Button, ButtonProps } from '~components/common/Button';

export type SlideProps = {
  title: String;
  height: any;
} & Omit<ButtonProps, 'children'>;

export const Slide = ({ title, height, ...props }: SlideProps) => (
  <Button {...props} height={height} variant="unstyled">
    {title}
  </Button>
);
