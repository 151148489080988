import { SystemProps, Box, Icon, css, Flex } from '@storyofams/react-ui';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import { Button } from '~components/common/Button';
import { LanguageSwitch } from '~components/common/LanguageSwitch';
import { Container } from '~components/common/Layout/Container';
import { Text } from '~components/common/Text';
import { FooterItem } from '~lib/storyblok/sdk';
import { Logo, ArrowUp } from '../Icons';

import { SubscriptionForm } from './SubscriptionForm';

export type LinkType = {
  key: string;
  label?: string;
  path: string;
};

type MenuProps = {
  dark?: boolean;
  links: any;
} & SystemProps;

type FooterProps = {
  links: FooterItem;
  storySlugs?: any;
};

const StyledMenu = styled(Flex)`
  flex: 1;
  list-style-type: none;
`;

const getUrl = (linkObject) => {
  const link =
    linkObject?.linktype === 'story'
      ? `/${linkObject?.story?.full_slug}`
      : linkObject?.cached_url;

  if (
    process.env.NEXT_PUBLIC_STORE_COUNTRY === 'b2b' &&
    !['shop', 'account', 'https://'].some((url) => link.includes(url)) &&
    link !== '/'
  ) {
    return `${
      !!process.env.NEXT_PUBLIC_NL_URL
        ? process.env.NEXT_PUBLIC_NL_URL
        : 'https://bols.com'
    }${link}`;
  }

  return link;
};

const Menu = ({ dark, links, ...props }: MenuProps) => {
  return (
    <StyledMenu flexDirection="column" as="ul" {...props}>
      {links?.map(({ link, label, open_in_new_tab }, i) => {
        const url = getUrl(link);

        return (
          <Box
            as="li"
            key={`${links?._uid}-${i}`}
            mb={i !== links?.length - 1 && 2}
          >
            <Button
              fontSize={2}
              lineHeight="medium"
              color={dark ? 'white' : 'grey400'}
              cursor="pointer"
              {...(url?.startsWith('#') ? { href: url } : { to :url })}
              textAlign="left"
              target={open_in_new_tab ? '_blank' : undefined}
              rel={open_in_new_tab ? 'noopener noreferrer' : undefined}
              opacity={dark ? 0.5 : 1}
              css={css({
                '&:hover, &:active, &:focus': dark
                  ? { opacity: 0.8, color: 'white' }
                  : { color: 'grey200' },
              })}
            >
              {label}
            </Button>
          </Box>
        );
      })}
    </StyledMenu>
  );
};

export const Footer = ({ links, storySlugs }: FooterProps) => {
  const { locale } = useRouter();

  return (
    <Box display="flex" flexDirection="column" color="white" fontFamily="Sohne">
      <SubscriptionForm />

      <Box as="footer" backgroundColor="grey900">
        <Container pt={[5, 10]} px={{ all: 3, md: 8, lg: 10 }}>
          <Flex
            flexDirection={['column', 'row']}
            flexWrap="wrap"
            alignItems="flex-start"
            justifyContent="space-between"
            width="100%"
          >
            <Box
              position="relative"
              minWidth={['50px', undefined]}
              css={css({
                '.credits-logo-1': {
                  position: 'absolute',
                  top: ['-48px', 'calc(100% + 64px)'],
                },
                '.credits-logo-2': {
                  position: 'absolute',
                  width: '90px',
                  top: ['-90px', 'calc(100% + 104px)'],
                },
              })}
            >
              <Icon
                display={['none!important', 'flex!important']}
                icon={Logo}
                fontSize="88px"
              />
              <a
                className="credits-logo-1"
                href={`https://storyofams.com/${
                  locale === 'nl' ? 'nl/' : ''
                }shopify-plus`}
                target="_blank"
                rel="noreferrer"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: '<!-- Credits: Development by Story of AMS -->',
                  }}
                />
                <img
                  width="50"
                  height="33"
                  src="/images/story-of-ams-logo-dark.png"
                  alt="Headless Shopify"
                />
              </a>
              <a
                className="credits-logo-2"
                href={"https://instantcommerce.io/"}
                target="_blank"
                rel="noreferrer"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: '<!-- Credits: Framework by Instant Commerce -->',
                  }}
                />
                <img
                  width="90"
                  height="20"
                  src="/images/instant-commerce-logo-dark.png"
                  alt="Instant Commerce"
                />
              </a>
            </Box>

            <Flex
              flex={1}
              width="100%"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="space-between"
              pl={[0, 15]}
            >
              {links?.content?.footer_columns.map(({ title, links }, index) => (
                <Box width={['50%', '50%', '25%']} key={`column-${index}`}>
                  <Text
                    fontSize={2.25}
                    lineHeight="medium"
                    fontWeight="medium"
                    letterSpacing="0.02em"
                    color="white"
                    pb={3}
                    mt={index <= 1 ? null : [8, 8, 0]}
                    css={css({ textTransform: 'uppercase' })}
                  >
                    {title}
                  </Text>
                  <Menu links={links} />
                </Box>
              ))}
            </Flex>
            <ScrollToTop
              display={['none !important', 'flex !important']}
              fontSize="48px"
            />
          </Flex>
          <Flex
            flex={1}
            flexDirection={['column', 'row']}
            mt={[8, 15]}
            alignItems={['flex-start', 'center']}
          >
            <Menu
              flexDirection={['column', 'row']}
              links={links?.content?.bottom_links}
              dark
              css={css({ '> li': { mb: [2, 0], mr: [0, 5] } })}
              order={[1, 3]}
            />
            <ScrollToTop
              mt={10}
              display={['flex !important', 'none !important']}
            />
            <Text
              color="white"
              opacity={0.5}
              lineHeight="normal"
              mb={[10, 'auto']}
              mt={[6, 'auto']}
              textAlign="center"
              order={[3, 1]}
              mr={['auto', 5]}
              ml={['auto', 'unset']}
            >
              {`©  ${new Date().getFullYear()} Lucas Bols`}
            </Text>
            <Flex
              flexBasis={['40px', '160px']}
              order={[2, 4]}
              mt={[8, 0]}
              width="100%"
            >
              <LanguageSwitch storySlugs={storySlugs} />
            </Flex>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

const ScrollToTop = (props) => (
  <Box
    width={['100%', 'unset']}
    order={[3, 2]}
    css={css({
      transition: 'transform 0.2s ease-in-out',
      '&:hover, &:focus': {
        transform: 'translateY(-4px)',
      },
    })}
  >
    <Icon
      fontSize="40px"
      icon={<ArrowUp />}
      color="grey300"
      as="button"
      mx="auto"
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      {...props}
    />
  </Box>
);
