import { getLocalizedUrl } from './getLocalizedUrl';

export const getCanonicalUrl = (router, locale: string, story?: any) => {
  let path = router.asPath?.replace(/(\?|#).*/, '');
  const localizedUrl = getLocalizedUrl(router, locale, story);
  const usesCanonical =
    path.includes('/bartendingacademy') ||
    path.includes('/cocktail-experience');

  if (process.env.NEXT_PUBLIC_STORE_COUNTRY !== 'nl' && usesCanonical) {
    return localizedUrl.replace(
      `${process.env.NEXT_PUBLIC_STORE_COUNTRY}.`,
      '',
    );
  }
  return localizedUrl;
};
