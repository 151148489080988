import { ReactNode } from 'react';
import { css, SystemProps, Icon } from '@storyofams/react-ui';
import { Button } from '~components/common/Button';
import { LetterB } from '~components/common/Icons';

interface NavigationLinkProps extends SystemProps {
  active?: boolean;
  link: string;
  children: ReactNode;
}

export const NavigationLink = ({
  active,
  link,
  children,
  ...props
}: NavigationLinkProps) => {
  return (
    <Button
      to={link}
      variant="unstyled"
      display="flex"
      alignItems="center"
      justifyContent={['space-between', 'space-between', 'center']}
      height={['auto', 'auto', '100%']}
      fontSize={[4, 4, 2]}
      fontWeight={500}
      fontFamily={['heading', 'heading', 'body']}
      lineHeight="140%"
      textAlign={['left', 'left', 'center']}
      letterSpacing="0.02em"
      color={['white', 'white', active ? 'cocktails' : 'grey900'] as any}
      transition="0.18s color ease-in-out"
      css={css({
        '&:hover, &:active, &:focus': {
          '.letter': {
            opacity: '0.5',
          },
          '@media (min-width: 1024px)': {
            opacity: '1',
            '.letter': {
              opacity: '1',
            },
          },
        },
      })}
      cursor="pointer"
      {...props}
    >
      {children}
      <Icon
        icon={LetterB}
        fontSize="60px"
        color="grey200"
        position="absolute"
        zIndex={-1}
        className="letter"
        opacity={0}
        transition="opacity 0.2s ease-in-out"
      />
    </Button>
  );
};
