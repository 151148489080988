const neutrals = {
  white: '#ffffff',
  grey100: '#f4f4f5',
  grey200: '#e4e4e7',
  grey300: '#d4d4d8',
  grey400: '#a1a1aa',
  grey500: '#71717a',
  grey900: '#010406',
  warning50: '#FEFCE8',
  warning100: '#FEF08A',
  warning300: '#FACC15',
  warning600: '#CA8A04',
  warning800: '#713F12',
  success50: '#F0FDF4',
  success100: '#BBF7D0',
  success300: '#4ADE80',
  success600: '#16A34A',
  success800: '#14532D',
  error50: '#FEF2F2',
  error100: '#FEE2E2',
  error300: '#F87171',
  error600: '#DC2626',
  error800: '#7F1D1D',
  transparent: 'rgb(255, 255, 255, 0)',
};

const primary = {
  cocktails: '#091f26',
  margaritaAzul: '#009ade',
  espressoMartini: '#331b11',
  negroni: '#7c0900',
  oldFashioned: '#a76c06',
  martini: '#d06200',
  elderflowerSpritz: '#cfdcb1',
  gingerMule: '#eec98d',
};

const supporting = {
  margaritaAzul: '#003354',
  espressoMartini: '#af6d3b',
  negroni: '#e89d33',
  oldFashioned: '#ffd588',
  martini: '#331b11',
  elderflowerSpritz: '#023c13',
  gingerMule: '#7c4821',
};

const variants = {
  supporting,
};

const colors = {
  ...primary,
  ...variants,
  ...neutrals,
};

export default colors;
