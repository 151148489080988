import { ReactNode } from 'react';
import { Box, SystemProps } from '@storyofams/react-ui';

interface NavigationProps extends SystemProps {
  children: ReactNode;
}

export const Navigation = ({ children, ...props }: NavigationProps) => {
  return (
    <Box
      position="sticky"
      top={0}
      left={0}
      right={0}
      zIndex="sticky"
      transition="0.2s background-color ease-out"
      {...props}
    >
      <Box width="100%" position="relative">
        {children}
      </Box>
    </Box>
  );
};
