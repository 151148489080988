import React from 'react';
import { Menu, MenuList, MenuButton, MenuItem } from '@reach/menu-button';
import { Box, Icon, Flex } from '@storyofams/react-ui';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import '@reach/menu-button/styles.css';

import { Text } from '~components';
import { getLocales } from '~lib';
import { ArrowDown } from '~components/common/Icons';

const LANGUAGES = {
  en: { label: 'English' },
  us: { label: 'English - United States' },
  nl: { label: 'Nederlands' },
};
const messages = defineMessages({
  language: 'Language:',
});

const StyledMenuButton = styled.button`
  && {
    appearance: none;
    border: none;
    background-color: ${(p) => p.theme.colors.grey500};
    border-radius: 4px;
    color: white;
    font-size: ${(p) => p.theme.fontSizes[1.75]};
    line-height: 100%;
    user-select: none;
    cursor: pointer;
    transition: color 0.2s ease-out, background-color 0.2s ease-in-out;
    min-width: 160px;

    &:hover {
      background-color: ${(p) => p.theme.colors.grey400};
    }
  }

  &[aria-expanded='true'] .chevron-down {
    transform: rotate(180deg);
  }
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    width: 100%;
  }
`;

const StyledMenuList = styled.div`
  margin-bottom: 8px;
  && {
    padding: 7px 4px;
    background: ${(p) => p.theme.colors.grey500};
    border: none;
    box-sizing: border-box;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
      0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px 8px 8px 0px;
    margin: 8px 0;
  }
`;

const StyledItem = styled.a`
  && {
    padding: 9px;
    font-size: 14px;
    line-height: 100%;
    color: ${(p) => p.theme.colors.white};
    text-decoration: none;
    border-radius: 4px;
    min-width: 180px;
    transition: background-color 0.2s ease-in-out;

    &[data-selected],
    &:hover {
      background-color: ${(p) => p.theme.colors.grey400};
    }
  }
`;

interface LanguageSwitchProps {
  storySlugs?: any;
}

export const LanguageSwitch = ({
  storySlugs,
  ...props
}: LanguageSwitchProps) => {
  const { pathname, defaultLocale, locale: routerLocale, query } = useRouter();

  const locale = (routerLocale || process.env.DEFAULT_LOCALE)?.toUpperCase();

  const getLocalizedPathname = (value) => {
    if (value === defaultLocale) {
      if (storySlugs?.default_full_slug) {
        return `/${storySlugs.default_full_slug}`;
      }
    } else if (storySlugs?.translated_slugs?.length) {
      const path = storySlugs.translated_slugs.find(
        ({ lang }) => lang === value,
      )?.path;

      if (path) {
        return `/${path}`;
      }
    }

    return { pathname, query };
  };

  if (getLocales()?.length <= 1) {
    return null;
  }

  return (
    <Flex
      flexDirection={['column', 'row']}
      justifyContent="center"
      alignItems={['flex-start', 'center']}
      width="100%"
      {...props}
    >
      <Text
        color="white"
        lineHeight="140%"
        fontSize={1.75}
        mr={2}
        mb={[1.5, 0]}
      >
        <FormattedMessage {...messages.language} />
      </Text>
      <Menu>
        <MenuButton as={StyledMenuButton}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            p={1.5}
            justifyContent="space-between"
          >
            <Text color="white" fontSize={1.75} lineHeight="100%">
              {LANGUAGES?.[locale.toLowerCase()]?.label}
            </Text>

            <Icon
              ml={2}
              icon={ArrowDown}
              fontSize={1.5}
              className="chevron-down"
            />
          </Box>
        </MenuButton>
        <MenuList as={StyledMenuList}>
          {getLocales().map((value) => (
            <Link
              key={value}
              href={getLocalizedPathname(value)}
              passHref
              locale={value}
            >
              <MenuItem key={value} as={StyledItem} onSelect={() => {}}>
                {LANGUAGES?.[value]?.label}
              </MenuItem>
            </Link>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  );
};
