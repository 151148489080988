import { useCallback, useState } from 'react';
import { Box, Flex, css } from '@storyofams/react-ui';
import { debounce } from 'lodash';

import { defineMessages, FormattedMessage } from 'react-intl';
import { useShopify } from '~context';
import { Button } from '~components/common/Button';
import { Input } from '~components/common/Input';
import { CheckoutFragmentFragment } from '~lib/shopify/sdk';

import { Divider } from '../common/Divider';

import { LineItem } from './LineItem';

const messages = defineMessages({
  remove: 'Remove',
});

const StyledButton = ({ type = 'minus', ...props }) => (
  <Button
    variant="unstyled"
    width={['32px', '24px']}
    height={['32px !important', '24px !important']}
    border="1px"
    borderColor="grey200"
    borderRadius="full"
    display="flex"
    alignItems="center"
    justifyContent="center"
    position="relative"
    transition="0.2s border-color ease-out"
    css={css({
      '&:hover, &:focus': {
        borderColor: 'grey900',
      },
    })}
    {...props}
  >
    <Box
      width={['13px', '10px']}
      height="1px"
      left={['8.5px', '6px']}
      top={['14.5px', '11px']}
      bg="grey900"
      position="absolute"
    />
    {type === 'plus' && (
      <Box
        width="1px"
        height={['13px', '10px']}
        left={['14.5px', '10.5px']}
        top={['8.5px', '6px']}
        bg="grey900"
        position="absolute"
      />
    )}
  </Button>
);

export const CheckoutLineItem = (
  item: CheckoutFragmentFragment['lineItems']['edges'][number]['node'],
) => {
  const [quantity, setQuantity] = useState(item?.quantity);
  const { adjustLineItemQuantity, removeLineItem, checkout, updatingCart } =
    useShopify();

  const debouncedAdjustQuantity = useCallback(
    debounce(adjustLineItemQuantity, 100),
    [checkout],
  );

  const updateQuantity = (amount) => {
    setQuantity(amount);
    debouncedAdjustQuantity({
      variantId: item.variant.id,
      quantity: amount,
    });
  };

  return (
    <>
      <LineItem {...item} mb={0}>
        <Flex justifyContent="space-between" alignItems="center">
          <Button
            variant="unstyled"
            fontSize={1.75}
            color="grey500"
            onClick={() => removeLineItem(item.variant.id)}
            type="button"
            transition="0.2s color ease-out"
            pr={[1, 0]}
            css={css({
              textTransform: 'uppercase',
              '&:hover, &:focus': {
                color: 'grey900',
              },
            })}
          >
            <FormattedMessage {...messages.remove} />
          </Button>

          <Flex
            alignItems="center"
            cursor={updatingCart ? 'wait !important' : undefined}
          >
            <StyledButton
              disabled={quantity <= 0}
              onClick={() => updateQuantity(quantity - 1)}
              cursor={updatingCart ? 'wait !important' : undefined}
            />

            <Input
              type="number"
              min={1}
              value={quantity}
              px={[2, 1.5]}
              css={css({
                width: `calc(${String(quantity).length}ch + 32px)`,
              })}
              disabled={updatingCart}
              cursor={updatingCart ? 'wait !important' : undefined}
              onChange={(e) => updateQuantity(parseInt(e.target.value))}
            />

            <StyledButton
              type="plus"
              onClick={() => updateQuantity(quantity + 1)}
              cursor={updatingCart ? 'wait !important' : undefined}
            />
          </Flex>
        </Flex>
      </LineItem>
      <Divider my={3} color="grey200" />
    </>
  );
};
