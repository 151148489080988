import { ReactNode } from 'react';

import { Box, SystemProps } from '@storyofams/react-ui';

type ContainerProps = {
  children: ReactNode;
  as?: 'section';
} & SystemProps;

export const Container = ({ children, as, ...props }: ContainerProps) => {
  return (
    <Box
      maxWidth="maxWidth"
      px={{ all: 2, md: 8, lg: 10 }}
      pt={[8, 10]}
      pb={[8, 10]}
      mx="auto"
      width="100%"
      as={as}
      {...props}
    >
      {children}
    </Box>
  );
};
