import { FC } from 'react';
import { Box, SystemProps } from '@storyofams/react-ui';
import styled from 'styled-components';
import { Button } from '../../Button';

export const StyledHamburger = styled(Box)`
  && button {
    height: 60px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span {
    position: relative;
    height: 2px;
    background-color: ${(p) => p.theme.colors.grey900};
    width: 18px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 2px;
      background-color: ${(p) => p.theme.colors.grey900};
      width: 18px;
    }

    &::before {
      transform: translateY(-5px);
    }

    &::after {
      transform: translateY(5px);
    }
  }
`;

interface HamburgerProps extends SystemProps {
  onClick?(boolean): void;
}

export const Hamburger: FC<HamburgerProps> = ({ onClick, ...props }) => {
  return (
    <StyledHamburger
      display={['flex !important', 'flex !important', 'none !important']}
      mr={1}
      onClick={onClick}
      {...props}
    >
      <Button variant="unstyled" py={1} pr={1}>
        <span />
      </Button>
    </StyledHamburger>
  );
};
